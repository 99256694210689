export class UserUpdateServiceResponseModel {

    id: string;
    error: {
        code: string,
        message: string,
    };

    constructor(Id: string, Error: {
        code: string,
        message: string,
    }) {
        this.id = Id;
        this.error = Error;
    }
}

export const EmptyUserUpdateServiceResponseModel = new UserUpdateServiceResponseModel("", {
    code: "",
    message: ""
});

export default UserUpdateServiceResponseModel;
