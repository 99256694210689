import * as React from "react";

import Dots from "./Dots";
import Slide from "./Slide";
import SlideModel from "Models/Slide";

// CREATE STYLES OBJECT
const s = {
  container: "fullW fullH rel overflowH",
  onScreen: "onScreen",
  offScreenRight: "left100vw",
  offScreenLeft: "leftM100vw",
  transition: "transition1l"
};

//===========================================
// SLIDESHOW COMPONENT
//===========================================

export interface slideshowProp {
  slides: string[];
}

interface SlideshowState {
  slide1: SlideModel;
  slide2: SlideModel;
  currentId: number;
}

class Slideshow extends React.Component<slideshowProp, SlideshowState> {
  carouselInterval: number;
  constructor(props: slideshowProp) {
    super(props);
    this.state = {
      slide1: {
        id: 0,
        position: s.onScreen,
        transition: true
      },
      slide2: {
        id: 1,
        position: s.offScreenRight,
        transition: true
      },
      currentId: 0
    };
    this.carouselInterval = 4000;
  }

  componentDidMount() {
    this.startCarousel();
  }

  componentWillUnmount() {
    clearInterval(this.carouselInterval);
  }

  startCarousel = () => {
    this.carouselInterval = window.setInterval(() => {
      this.transitionSlides();
    }, 4000);
  };

  setSlideState = (
    slide1: SlideModel,
    slide2: SlideModel,
    currentId: number
  ) => {
    this.setState({
      slide1: slide1,
      slide2: slide2,
      currentId: currentId
    });
  };

  transitionSlides = () => {
    const { slide1, slide2 } = this.state;
    let currentId;
    if (slide1["position"] === s.onScreen) {
      slide1["position"] = s.offScreenLeft;
      slide2["position"] = s.onScreen;
      currentId = slide2.id;
    } else {
      slide1["position"] = s.onScreen;
      slide2["position"] = s.offScreenLeft;
      currentId = slide1.id;
    }

    this.setSlideState(slide1, slide2, currentId);

    setTimeout(() => {
      this.resetSlideOffScreen();
    }, 1000);
  };

  resetSlideOffScreen = () => {
    const { slide1, slide2, currentId } = this.state;
    const { slides } = this.props;
    if (slide1["position"] === s.offScreenLeft) {
      slide1["transition"] = false;
      slide1["position"] = s.offScreenRight;
      slide1["id"] = slide2.id + 1 === slides.length ? 0 : slide2.id + 1;
    } else {
      slide2["transition"] = false;
      slide2["position"] = s.offScreenRight;
      slide2["id"] = slide1.id + 1 === slides.length ? 0 : slide1.id + 1;
    }
    this.setSlideState(slide1, slide2, currentId);
    this.resetSlideTransitions(slide1, slide2, currentId);
  };

  resetSlideTransitions = (
    slide1: SlideModel,
    slide2: SlideModel,
    currentId: number
  ) => {
    setTimeout(() => {
      slide1["transition"] = true;
      slide2["transition"] = true;
      this.setSlideState(slide1, slide2, currentId);
    }, 500);
  };

  render() {
    const { slide1, slide2, currentId } = this.state;
    const { slides } = this.props;
    return (
      <div className={s.container}>
        <Slide
          image={slides[slide1.id]}
          position={slide1.position}
          transition={slide1.transition ? s.transition : ""}
        />
        <Slide
          image={slides[slide2.id]}
          position={slide2.position}
          transition={slide2.transition ? s.transition : ""}
        />
        <Dots slideId={currentId} slides={slides} />
      </div>
    );
  }
}

export default Slideshow;
