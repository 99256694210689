export class UserListApiModel {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    userPrincipalName: string;
    roles: string[];
    scenarioIds: string[];
    metro: [{ key: number, value: string }];
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    locations: {
        metro: string;
    };
    etag: string;
    accountEnabled: boolean;
    constructor(id: string,
        displayName: string,
        firstName: string,
        lastName: string,
        email: string,
        userPrincipalName: string,
        roles: string[],
        scenarioIds: string[],
        metro: [{ key: number, value: string }],
        createdBy: string,
        createdDate: string,
        modifiedBy: string,
        modifiedDate: string,
        etag: string,
        accountEnabled: boolean,
        locations: {
            metro: string;
        }) {
        this.id = id;
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.userPrincipalName = userPrincipalName;
        this.roles = roles;
        this.scenarioIds = scenarioIds;
        this.metro = metro;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.modifiedBy = modifiedBy;
        this.modifiedDate = modifiedDate;
        this.locations = locations;
        this.metro = metro;
        this.etag = etag;
        this.accountEnabled = accountEnabled;
    }
}
export const EmptyUserListApiModel = new UserListApiModel("", "", "", "", "", "",
    [], [], [{ key: -1, value: "" }], "", "", "", "", "",false, { metro: "" });
export class UserListUIModel {
    id: string;
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    userPrincipalName: string;
    roles: string[];
    scenarioIds: string[];
    metro: [{ key: number, value: string }];
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    etag: string;
    accountEnabled: boolean;
    constructor(id: string,
        displayName: string,
        firstName: string,
        lastName: string,
        email: string,
        userPrincipalName: string,
        roles: string[],
        scenarioIds: string[],
        metro: [{ key: number, value: string }],
        createdBy: string,
        createdDate: string,
        modifiedBy: string,
        modifiedDate: string,
        etag: string,
        accountEnabled: boolean
    ) {
        this.id = id;
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.userPrincipalName = userPrincipalName;
        this.roles = roles;
        this.scenarioIds = scenarioIds;
        this.metro = metro;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.modifiedBy = modifiedBy;
        this.modifiedDate = modifiedDate;
        this.metro = metro;
        this.etag = etag;
        this.accountEnabled = accountEnabled;
    }
}
export const EmptyUserListUIModel = new UserListUIModel("", "", "", "", "", "",
    [], [], [{ key: -1, value: "" }], "", "", "", "", "",false);
export class UserMapper {
    public static ApiToUI(apiUser: UserListApiModel, scenarioDisplayName: string[]): UserListUIModel {
        return new UserListUIModel(
            apiUser.id,
            apiUser.displayName,
            apiUser.firstName,
            apiUser.lastName,
            apiUser.email,
            apiUser.userPrincipalName,
            apiUser.roles,
            scenarioDisplayName,
            apiUser.metro,
            apiUser.createdBy,
            apiUser.createdDate,
            apiUser.modifiedBy,
            apiUser.modifiedDate,
            apiUser.etag,
            apiUser.accountEnabled
        );
    }
}

