import { IDropdownOption } from '@fluentui/react';

// Types
export enum ScenarioVariantType {
    Device = "Device",
    User = "User"
};

export type IScenarioVariantOption = {
    readonly key: string;
    readonly text: string;
}
export type IScenarioVariant = {
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly options: any[];
};

export type IScenario = {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly variants: IScenarioVariant[];
};

// Classes
export class ScenarioVariant implements IScenarioVariant{
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly options: any[];
    private optionsArray: Array<IScenarioVariantOption>;

    constructor(model: IScenarioVariant) {
        this.id = model.id;
        this.name = model.name;
        this.type = model.type;
        this.options = model.options;
        this.optionsArray = new Array<IScenarioVariantOption>();
        if (model.options !== null && model.options !== undefined) {
            const jsonOptions = JSON.stringify(this.options);
            var obj = JSON.parse(jsonOptions);
            for (const key in obj) {
                if (this.options.hasOwnProperty(key)) {
                    var value = obj[key];
                    this.optionsArray.push({key: value, text: key});
                }
            }
        }
    }

    public GetOptions(): Array<IScenarioVariantOption> {

        return this.optionsArray;
    }

    public static NullVariant() : ScenarioVariant {
        var empty: IScenarioVariant = {
            id: "",
            name: "",
            type: "",
            options: []
        };
        return new ScenarioVariant(empty);
    }
};

export class Scenario implements IScenario {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly variants: IScenarioVariant[];
    private variantsArray: Array<ScenarioVariant>;

    constructor(id: string, name: string, description: string, variants: IScenarioVariant[]) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.variants = variants === null ? [] : variants;
        this.variantsArray = new Array<ScenarioVariant>();
        if (variants !== null && variants !== undefined) {
            for (var i= 0; i < variants.length; i++) {
                this.variantsArray.push(new ScenarioVariant(variants[i]));
            }
        }
    }

    public GetVariants(type?: ScenarioVariantType): Array<ScenarioVariant> {
        if (type !== undefined) {
            return this.variantsArray.filter(v => v.type === type);
        }
        return this.variantsArray;
    }
}


export class ScenarioHelper {
    public static ScenariosToDropdownOptions(responses: IScenario[]): IDropdownOption[] {
        let options: IDropdownOption[]  = [];
        responses.sort((a, b) => a.name < b.name ? -1 : 1).forEach((option) => {
            options.push({ key: option.id, text: option.name });
        });

        return options;
    }
}

export class ScenarioResponseModel {
    id: string;
    name: string;
    supportedCompanyMetaData?: {
        companyId: string;
        companyName: string;
        allowedUserEmailDomains: string[];
    }[];

    constructor(id: string, name: string, supportedCompanyMetaData: [string, string, string[]] [] = []) {
        this.id = id;
        this.name = name;
        this.supportedCompanyMetaData = supportedCompanyMetaData.map(([companyId, companyName, allowedUserEmailDomains]) => ({
            companyId,
            companyName,
            allowedUserEmailDomains
        }));
    }
}

export const EmptyScenarioResponseModel = new ScenarioResponseModel("", "", []);

export class ScenarioMapper {
    public static MapScenarioName(scenarioIds: string[], scenarioData: ScenarioResponseModel[]): string {
        var ScenarioNameList: string = "";

        scenarioIds.forEach(function (scenarioId) {
            if (ScenarioNameList !== "") {
                ScenarioNameList = ScenarioNameList + ", ";
            }
            ScenarioNameList = ScenarioNameList + scenarioData.filter(p => p.id === scenarioId).map((x: ScenarioResponseModel) => x.name);
        });

        return ScenarioNameList;
    }
}
