import * as React from 'react';

import { SiteHeaderBar } from './SiteHeaderBar';
import TransparentFooter from './TransparentFooter';
import { SidebarNav } from 'Components/Framework/Navigation/SideNavigation/SidebarNav';
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import "scss/site.css";

initializeIcons();

export interface LayoutProp {
    isAuthenticated: boolean;
    authButtonMethod: any;
    user: any;
}

export class Layout extends React.Component<LayoutProp, {}> {

    renderSidebar(display: boolean) {

        if (display && this.props.user !== undefined) {
            display = this.props.user.roles !== undefined ? true : false;
        }

        return display ? (<div><SidebarNav isAuthenticated={this.props.isAuthenticated}
            authButtonMethod={this.props.authButtonMethod}
            user={this.props.user}/></div>) : "";
    }

    render() {
        return (
            <div>
                <div id="site-header">
                    <SiteHeaderBar
                        isAuthenticated={this.props.isAuthenticated}
                        authButtonMethod={this.props.authButtonMethod}
                        user={this.props.user}
                    />
                </div>
                <main id="site-main">
                    { this.renderSidebar(this.props.isAuthenticated) }   
                    <div id="valyrian-main-content" style={{ width: "100%"}}>{this.props.children}</div>
                </main>
                <TransparentFooter isAuthenticated={this.props.isAuthenticated} />
            </div>
        );
    }
}