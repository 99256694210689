import * as React from "react";

//===========================================
// DOT FUNCTIONAL COMPONENT
//===========================================

interface DotProps {
  slideId: number;
  dotId: number;
}

const Dot = ({ slideId, dotId }: DotProps) => (
  <div className="row">
    <div className={"dot " + (slideId === dotId ? "white" : "white50")} />
  </div>
);
export default Dot;
