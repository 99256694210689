import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { TracingService } from './TracingService';
import User from 'Models/User';
import UserServiceResponseModel, { EmptyUserServiceResponseModel } from 'Models/UserServiceResponseModel';
import { ScenarioService } from './ScenarioService';

const constants = Constants.getInstance();
const Component_Name = 'Create Single User Service';
let tracingService = TracingService.getInstance();

export class CreateSingleUserService {
    private static instance: CreateSingleUserService;

    public static getInstance = () => {
        if (!CreateSingleUserService.instance) {
            CreateSingleUserService.instance = new CreateSingleUserService();
        }
        return CreateSingleUserService.instance;
    };

    public CreateNewUser = async (token: string, userPrincipalName: string, firstName: string, lastName: string, email: string, scenarioIds: string[], roles: string[], company: string, skipDcat: boolean, skipCoi: boolean): Promise<UserServiceResponseModel> => {
        tracingService.trace(Component_Name, 'CreateSingleUserAPICall');
        let createUserOutput: UserServiceResponseModel = EmptyUserServiceResponseModel;
        const CreateUserEndPoint = constants.CreateUserApiEndpoint;
        const createUserRequest = User.getUserPostModel(
            this.getCreateUserRequest(userPrincipalName, firstName, lastName, email, scenarioIds, roles, company, skipDcat,skipCoi));
        console.log(createUserRequest);

        try {
            const response = await this.createUserApiCall(CreateUserEndPoint, token, createUserRequest);
            const data = await response.json();
            let createUserResponse: UserServiceResponseModel;
            createUserResponse = data;
            createUserOutput = CreateSingleUserService.CreateUserResponseToCreateUserPortalUI(createUserResponse);

        }
        catch (error) { 
                console.log("Error occurred with correlationID: " + constants.CorrelationID);
                tracingService.traceHttpResponse(error);
                ApplicationInsightsLogger.getInstance().logException(error);
        }
        return createUserOutput;
    };

    public ReactivateUser = async (token: string, displayName: string, userPrincipalName: string, firstName: string, lastName: string, email: string, scenarioIds: string[], roles: string[], company: string, skipDcat: boolean, skipCoi: boolean): Promise<UserServiceResponseModel> => {
        tracingService.trace(Component_Name, 'ReactivateSingleUserAPICall');
        let createUserOutput: UserServiceResponseModel = EmptyUserServiceResponseModel;
        const CreateUserEndPoint = constants.CreateUserApiEndpoint;
        const createUserRequest = User.getUserPostModel(
            this.getReactivateUserRequest(userPrincipalName, displayName, firstName, lastName, email, scenarioIds, roles, company, skipDcat, skipCoi));
        console.log(createUserRequest);

        try {
            const response = await this.createUserApiCall(CreateUserEndPoint, token, createUserRequest);
            const data = await response.json();
            let createUserResponse: UserServiceResponseModel;
            createUserResponse = data;
            createUserOutput = CreateSingleUserService.CreateUserResponseToCreateUserPortalUI(createUserResponse);

        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return createUserOutput;
    };

    private createUserApiCall = async (CreateUserEndPoint: string, token: string, createUserRequest: User) => {
        return await fetch(CreateUserEndPoint, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            },
            body: JSON.stringify(createUserRequest)
        });
    };

    private getTenatfromUPN(upn: string) {
        const names = upn.split(constants.atSymbol);
        return names[1];
    }

    private getCreateUserRequest = (upn: string, firstName: string, lastName: string, email: string, scenarioIds: string[], roles: string[], company: string, skipDcat: boolean, skipCoi: boolean) => {
        let userPrincipalName = email;
        let names: string[] = [];
        const upnDomain = this.getTenatfromUPN(upn);
        names = email.split(constants.atSymbol);
        let mailNickName = names[0];
        userPrincipalName = names[0] + constants.atSymbol + upnDomain;

        return new User(firstName, firstName,
            lastName, mailNickName, userPrincipalName,
            {
                email: email,
                city: null,
                country: null,
                department: company,
                jobTitle: null,
                officeLocation: null,
                postalCode: null,
                state: null,
                streetAddress: null,
                mobilePhone: null
            }, scenarioIds, roles, skipDcat,skipCoi, true);
    };

    private getReactivateUserRequest = (upn: string, displayName: string, firstName: string, lastName: string, email: string, scenarioIds: string[], roles: string[], company: string, skipDcat: boolean, skipCoi: boolean) => {
        let userPrincipalName = email;
        let names: string[] = [];
        const upnDomain = this.getTenatfromUPN(upn);
        names = email.split(constants.atSymbol);
        let mailNickName = names[0];
        userPrincipalName = names[0] + constants.atSymbol + upnDomain;

        return new User(displayName, firstName,
            lastName, mailNickName, userPrincipalName,
            {
                email: email,
                city: null,
                country: null,
                department: company,
                jobTitle: null,
                officeLocation: null,
                postalCode: null,
                state: null,
                streetAddress: null,
                mobilePhone: null
            }, scenarioIds, roles, skipDcat, skipCoi,true);
    };

    private static CreateUserResponseToCreateUserPortalUI = (createUserResponse: UserServiceResponseModel) => {
        return new UserServiceResponseModel(
            createUserResponse.notificationStatus,
            createUserResponse.user,
            createUserResponse.error);
    };
}
