import * as React from "react";
import {
    Input,
    Label,
    Dropdown,
    makeStyles,
    Option,
    shorthands
} from "@fluentui/react-components";
// Types
import type { DynamicFilterDefinition, DynamicFilterProps, DynamicFilterState, FilterValue } from "./DynamicFilterTypes";
// CSS
import "Components/Framework/Forms/Form.css";
import "./Filters.css";

const useDropdownStyles = makeStyles({
    root: {
      // Stack the label above the field with a gap
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      maxWidth: "200px"
    },
    listbox: {
      maxHeight: "200px"
    },
    // these styles wrap the value text within the dropdown button and cause it to truncate
    truncatedText: {
      overflowX: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  });

export const getInitialFilterState = (dependency: string | undefined, managed: boolean = false) => {
    return {
        disabled: dependency ? true : false,
        currentValue: { value: [], text: "" },
        managed: managed
    };
};

export const renderFilterControlsByType = (props: DynamicFilterProps) => {
    const type = props.definition.children ? "composite" : (props.definition.options ? "options" : "basic");
    switch (type) {
        case "composite":
            return CompositeFilter(props);
        case "options":
            return OptionsFilter(props);
        default:
            return TextFilter(props);
    }
};

const TextFilter = (props: DynamicFilterProps) => {
    return (
        <div className="standard-form-item vertical">
            <Label size="small">{props.definition.label}</Label>
            <Input
                id={props.definition.key}
                className="standard-form-textfield textfield"
                onChange={(event) => props.onValueChange(props.definition.key, { value: event.target.value, text: event.target.value })} />
        </div>
    );
};

const OptionsFilter: React.FunctionComponent<DynamicFilterProps> = (props) => {
    // OR combobox underlined with virtualizer
    // Multiselect is not supported
    const [myValue, setMyValue] = React.useState({value: "", text: ""});
    const styles = useDropdownStyles();

    const createOptions = () => {
        let opts: any = [];
        if (props.definition.options !== undefined) {
            props.definition.options.forEach((option) => {
                let value = (typeof option === "string") ? option : option.value.toString();

                opts.push(<Option key={value} value={value}>
                    {
                        (typeof option === "string") ? option : option.text.toString()
                    }
                </Option>);
            });
        }
        return opts;
    };

    return (
        <div key={props.definition.key} className="standard-form-item vertical">
            <Label size="small">{props.definition.label}</Label>
            <Dropdown
                id={props.definition.key}
                disabled={props.state?.disabled ?? false}
                placeholder={"Select " + props.definition.label}
                value={ (props.state.managed === false) ? myValue.text : props.state.currentValue.text}
                selectedOptions={
                    (props.state.managed === false)
                        ? [myValue.value]
                        : (props.state.currentValue.value === "string")
                            ? [props.state.currentValue.value]
                            : [props.state.currentValue.value.toString()]
                }
                onOptionSelect={(event, data) => {
                    if (!props.state.managed) {
                        setMyValue({value: data.optionValue ?? "",
                        text: data.optionText ?? ""});
                    }
                    props.onValueChange(props.definition.key, {
                        value: data.optionValue ?? "",
                        text: data.optionText ?? ""
                    });
                }}
                listbox={{ className: styles.listbox }}
            >
                {createOptions()}
            </Dropdown>
        </div>
    );
};

const ControlledOptionsFilter: React.FunctionComponent<DynamicFilterProps> = (props) => {
    const styles = useDropdownStyles();

    const createOptions = () => {
        let opts: any = [];
        if (props.definition.options !== undefined) {
            props.definition.options.forEach((option) => {
                let value = (typeof option === "string") ? option : option.value.toString();

                opts.push(<Option key={value} value={value}>
                    {
                        (typeof option === "string") ? option : option.text.toString()
                    }
                </Option>);
            });
        }
        return opts;
    };  

    return (
        <div key={props.definition.key} className="standard-form-item vertical">
            <Label size="small">{props.definition.label}</Label>
            <Dropdown
                id={props.definition.key}
                disabled={props.state?.disabled ?? false}
                placeholder={"Select " + props.definition.label}
                value={ props.state.currentValue.text}
                selectedOptions={
                    (props.state.currentValue.value === "string")
                            ? [props.state.currentValue.value]
                            : [props.state.currentValue.value.toString()]
                }
                onOptionSelect={(event, data) => {
                    props.onValueChange(props.definition.key, {
                        value: data.optionValue ?? "",
                        text: data.optionText ?? ""
                    });
                }}
                listbox={{ className: styles.listbox }}
            >
                {createOptions()}
            </Dropdown>
        </div>
    );
};


function convertMapToArray(map: Map<string, FilterValue>): { key: string, value: string }[] {
    const arr = new Array<{ key: string, value: string }>();
    map.forEach((value: FilterValue, key: string) => arr.push({ key: key, value: value.value.toString() }));
    return arr;
}

const CompositeFilter: React.FunctionComponent<DynamicFilterProps> = (props) => {
    const [childrensProps, setChildrensProps] = React.useState<DynamicFilterProps[]>([]);
    const [currentCompositeValue, setCurrentCompositeValue] = React.useState<Map<string, FilterValue>>(new Map<string, FilterValue>());//React.useState <Array<{ key: string, value: string }>>([]);
    const [didMount, setDidMount] = React.useState<boolean>(false);

    const updateDependents = (triggerKey: string, compositeValue: Map<string, FilterValue>): DynamicFilterProps[] => {
        let rebuiltFilterProps: DynamicFilterProps[] = [];

        childrensProps.map(child => {
            let updatedChild = child;
            updatedChild.state = updatedChild.state ?? getInitialFilterState(child.definition.dependencyKey, true);

            if (child.definition.dependencyKey === triggerKey && child.definition.onDependencyValueChange) {
                // Handle callback to update options
                updatedChild.definition.options = child.definition.onDependencyValueChange(convertMapToArray(compositeValue));

                // disable and reset selected value
                updatedChild.state.currentValue = { value: [], text: "" };
                updatedChild.state.disabled = (child.state.disabled === undefined) ? false : updatedChild.definition.options?.length < 1;

                // recurse update dependents
                compositeValue.set(updatedChild.definition.key, {value: "", text: ""});
                rebuiltFilterProps.push(updatedChild);
                rebuiltFilterProps = rebuiltFilterProps.concat(updateDependents(child.definition.key, compositeValue));
            }
        });

        return rebuiltFilterProps;
    };

    const onChildValueChanged = (key: string, childValue: FilterValue) => {
        console.log("child '" + key + "' changed value to '" + childValue.value + "'");

        // Evaluate each child to update
        // - the child's props
        // - the composite filter's overall value and display text
        let updatedCompositeValue = currentCompositeValue;

        let updatedChildrensProps: DynamicFilterProps[] = [];

        childrensProps.map(childEvaluating => {
            let child = childEvaluating;
            child.state = childEvaluating.state ?? getInitialFilterState(childEvaluating.definition.dependencyKey, true);

            if (childEvaluating.definition.key === key) {
                // This child is the trigger, update its state and any other children that take a dependency on it
                child.state.currentValue = childValue;
                updatedChildrensProps.push(child);
                
                updatedCompositeValue.set(key, childValue);
                updatedChildrensProps = updatedChildrensProps.concat(updateDependents(key, updatedCompositeValue));
            }
        });

        let aggregatedProps = childrensProps.map(child => {
            const updatedKid = updatedChildrensProps.find(updated => updated.definition.key === child.definition.key);
            return updatedKid ? updatedKid : child;
        });

        let compositeDisplayText: string = "";
        updatedCompositeValue.forEach((value: FilterValue, key: string) => compositeDisplayText = compositeDisplayText.concat(value.text + ","));
        if (compositeDisplayText.endsWith(',')) {
            compositeDisplayText = compositeDisplayText.substring(0, compositeDisplayText.length - 1);
        }

        setChildrensProps(aggregatedProps);
        setCurrentCompositeValue(updatedCompositeValue);

        // notify filter editor that this composite filter's overall value has changed
        props.onValueChange(props.definition.key, { value: convertMapToArray(updatedCompositeValue), text: compositeDisplayText});
    };

    const getChildState = (child: DynamicFilterDefinition): DynamicFilterState => {
        return childrensProps.map(filter => filter.definition.key === child.key ? filter.state : getInitialFilterState(child.dependencyKey, true))[0];
    };

    const renderChildFilters = () => {
        if (!didMount) {
            initializeChildFilters();
            setDidMount(true);
        }
        return childrensProps.map(child => ControlledOptionsFilter(
            { definition: child.definition, state: child.state ?? getInitialFilterState(child.definition.dependencyKey, true), onValueChange: onChildValueChanged }
        ));
    };

    const initializeChildFilters = () => {
        setChildrensProps(props.definition.children?.map<DynamicFilterProps>(child => {
            return {
                definition: child,
                state: getChildState(child),
                onValueChange: onChildValueChanged
            };
        }) ?? []
        );
        setDidMount(true);
    };

    return (
        <div>
            {renderChildFilters()}
        </div>
    );
};