import * as React from 'react';
import {
    Label,
    IDropdownOption,
    PrimaryButton,
    DefaultButton,
    IStackTokens,
    Stack
} from '@fluentui/react';
import {
    MessageBar,
    MessageBarType
} from "office-ui-fabric-react";
import { DeviceApiModel, DeviceMapper, DeviceUIModel } from 'Models/DeviceModel';
import { DeviceInventoryService } from 'Services/DeviceInventoryService';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { ScenarioResponseModel } from '../../../Models/ScenarioResponseModel';
import { ScenarioService } from 'Services/ScenarioService';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import Loading from "Images/Loading.gif";
import ResourceManager from 'Resources/ResourceManager';
import "scss/site.css";
import "../../../scss/common.css";


const Component_Name = 'Device Details';
const DetailName_SerialNumber = ResourceManager.GetString(ResourceStrings.Device.SerialNumber);
const Confirmation_Message = ResourceManager.GetString(ResourceManager.GetString(ResourceStrings.Device.Decommission));
const DetailName_Status = ResourceManager.GetString(ResourceStrings.Device.DeviceState);

const DecommissionDevice_SuccessMessage = ResourceManager.GetString(ResourceStrings.DeviceAction.DecommissionDevice.Message.Success);
const DecommissionFormTitle = ResourceManager.GetString(ResourceStrings.DeviceAction.DecommissionDevice.Title);

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();

interface IDeviceDecommissionFormProps {
    device: DeviceApiModel;
    onClose: (success: boolean) => any;
}

interface IDeviceDecommissionFormState {
    selectedScenario: string;
    isLoading: boolean;
    error: boolean;
    showMessageBar: boolean;
    apiResponse: string;
    apiSuccess: boolean;
    skipWipe: boolean;
}

export class DecommissionDeviceForm extends React.Component<IDeviceDecommissionFormProps, IDeviceDecommissionFormState> {
    private device: DeviceUIModel;
    private allScenarios: ScenarioResponseModel[];
    private scenarioOptions: IDropdownOption[];

    constructor(props: IDeviceDecommissionFormProps) {
        super(props);
        this.allScenarios = ScenarioService.getInstance().GetScenarioData();
        this.device = DeviceMapper.ApiToUI(props.device, ScenarioService.MapScenarioName(props.device.scenarioIds, this.allScenarios));
        this.scenarioOptions = [];
        this.allScenarios.forEach((scenario) => {
            this.scenarioOptions.push({ key: scenario.id, text: scenario.name });
        });

        this.state = {
            selectedScenario: "",
            isLoading: false,
            error: false,
            showMessageBar: false,
            apiResponse: "",
            apiSuccess: false,
            skipWipe: false
        };
        console.log("Decommission form");
    }

    static contextType = UserContext;

    handleCheckboxChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        this.setState({ skipWipe: !!checked });
    };

    private DecommissionDeviceAsync = async () => {
        const user = this.context;
        await deviceInventoryService.DecommissionDevice(user.IdToken, this.props.device.id).then(result => {
            this.setState({
                error: result.error,
                apiResponse: result.message,
                showMessageBar: true,
                isLoading: false
            });
        });
    };

    private HandleSubmit = () => {
        this.setState({ isLoading: true });
        this.DecommissionDeviceAsync();
    };

    private CloseForm = () => {
        if (!this.state.error && this.state.apiResponse !== "") {
            this.props.onClose(true);
        }
        else {
            this.props.onClose(false);
        }
    };

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying device Decommission form');
    }

    public render(): JSX.Element {
        const stackTokens: IStackTokens = { childrenGap: 15 };

        return (
            <div className="wrapper">
                <div className="securedevicesform">
                    <Label className="securedevicesform-title">{DecommissionFormTitle}</Label>
                    <div className="securedevicesform-body">

                        <div className="form__text">
                            <Label className="securedevices-label">
                                {DetailName_SerialNumber}
                            </Label>
                            <div className="securedevices-input">
                                {this.device.deviceSerialNumber}
                            </div>
                        </div>

                        <div className="form__text">
                            <Label className="securedevices-label">
                                {DetailName_Status}
                            </Label>
                            <div className="securedevices-input">
                                {this.device.deviceStatus}
                            </div>
                        </div>
                        <div className="form__text">
                            <Label className="securedevices-alert-msg">
                                {Confirmation_Message}
                            </Label>
                        </div>
                    </div>
                    <div className="securedevicesform-footer">
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                disabled={this.state.isLoading || this.state.apiResponse !== ""}
                                onClick={this.HandleSubmit}
                                text="Confirm"
                            />
                            <DefaultButton
                                disabled={this.state.isLoading || this.state.apiResponse !== ""}
                                onClick={this.CloseForm}
                                text="Cancel"
                            />
                            <img
                                src={Loading}
                                hidden={!this.state.isLoading}
                                style={{
                                    width: "35px",
                                    height: "35px",
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                alt="Loading"
                            ></img>
                        </Stack>
                    </div>
                    {this.state.showMessageBar && this.state.apiResponse ? (
                        <div className="securedevicesform-header">
                            {!this.state.error ? (
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseForm()}
                                >
                                    {DecommissionDevice_SuccessMessage + this.state.apiResponse}
                                </MessageBar>
                            ) : (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseForm()}
                                >
                                    {this.state.apiResponse}
                                </MessageBar>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        );
    }
}