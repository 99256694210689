"use client";
import * as React from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import {
    Label,
    PrimaryButton,
    DefaultButton,
    TextField,
    Dropdown,
    IDropdownOption,
    Stack,
    IStackTokens,
    Icon    
} from "@fluentui/react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { initializeIcons   } from '@fluentui/font-icons-mdl2';
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { Component } from "react";
import { AuthorizedRole } from "Auth/Authorization";
import * as Common from "Components/Common/ValyrianCommonComponents";
import Loading from "Images/Loading.gif";
import { GraphUserElevationService } from "Services/GraphUserElevationService";
import UserElevationRequest from "../../../Models/UserElevationRequest";


const Component_Name = "User Elevation";
// The values that we want to allow elevationg on via portal.
const allowedprivilegeroles = ["Azure AD Joined Device Local Administrator"];
// The default(max) duration for the elevation.
const expirationDurationinHours = 5;

const title = Common.ResourceManager.GetString(
    Common.ResourceStrings.UserElevation.UserElevationTitle
);

const reason = Common.ResourceManager.GetString(
    Common.ResourceStrings.UserElevation.reasonLabel
);
const dropdownPlaceHolder = Common.ResourceManager.GetString(
    Common.ResourceStrings.UserElevation.rolePlaceHolder
);
const reasonPlaceHolder = Common.ResourceManager.GetString(
    Common.ResourceStrings.UserElevation.reasonPlaceHolder
);
const privilege = Common.ResourceManager.GetString(
    Common.ResourceStrings.UserElevation.privilege
);
const invalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.Common.invalidMessage);

const stackTokens: IStackTokens = { childrenGap: 15 };

const privliegeOptions: IDropdownOption[] = [];
let tracingService = Common.TracingService.getInstance();
initializeIcons();

export class UserElevation extends Component<any, any> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Elevate User",
        authorizedRoles: [
            AuthorizedRole.EligiblePrivilegedUser
        ]
    };

    constructor(props: any) {
        super(props);
        this.state = {
            role: "",
            privilege: "",
            reason: "",
            showMessageBar: false,
            isUserElevated: false,
            errorMessage: "",
            errorCode: "",
            formErrors: { privilege: "", reason: "" },
            isLoading: false,
            isUserValid: true
        };

        this.fillPriviligeDropdownOptions =
            this.fillPriviligeDropdownOptions.bind(this);
        this.handleSubmitPIMElevation = this.handleSubmitPIMElevation.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.closeMessageBar = this.closeMessageBar.bind(this);
        this.validateUserInput = this.validateUserInput.bind(this);
    }
    static contextType = Common.UserContext;
    private Constant = Common.Constants.getInstance();

    async componentDidMount() {
        await this.fillPriviligeDropdownOptions();
    }
    // Validation methods
    validateUserInput =  (fieldName: string) => {
        let fieldValidationErrors = this.state.formErrors;
        switch (fieldName) {
            case privilege:
                fieldValidationErrors.privilege = (this.state.privilege === "")
                    ? invalidMessage
                    : "";
                break;
            case reason:
                fieldValidationErrors.reason = (this.state.reason === "" || !this.state.reason.trim())
                    ? invalidMessage
                    : "";
                break;        
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors
        });
    };

    // Pageload Methods //
    fillPriviligeDropdownOptions = async () => {
        const user = this.context;
        let aadUserRoles = await GraphUserElevationService.getInstance().GetUserRoles(
            user.userObjectID,
            user.accessToken
        );
        aadUserRoles
            .filter((x) =>
                allowedprivilegeroles.includes(x.roleDefinition.displayName)
            )
            .map((role: { roleDefinition: { id: any; displayName: any } }) => {
                if (
                    privliegeOptions.filter((x) => x.key === role.roleDefinition.id)
                        .length === 0
                )
                    privliegeOptions.push({
                        key: role.roleDefinition.id,
                        text: role.roleDefinition.displayName
                    });
            });
    };
    
    //Form Methods //
    closeMessageBar = () => {
        this.setState({
            reason: "",
            showMessageBar: false,
            errorMessage: "",
            errorCode: "",
            formErrors: { privilege: "", reason: "" },
            isUserElevated: false
        });
    };

    handleInputChange = async (
        event: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >,
        newValue: string | undefined
    ): Promise<void> => {
        const target = event.target as HTMLInputElement;
        console.log(target, target.id, target.name, target.value);
        const newAsset = { [target.id]: target.value };
        await this.setState(newAsset);
        this.validateUserInput(reason);
    };

    handleprivilegeDropdownChange = async (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
        >,
        value: string | undefined
    ): Promise<void> => {
        const target = event.target as HTMLInputElement;
        const newResource = { [target.id]: value };
        await this.setState(newResource);
        this.validateUserInput(privilege);
    };

    handleSubmitPIMElevation = async () => {
        const activationType = "selfActivate";
        const expirataionType = "AfterDuration";
        //The pramatere here is the hours that can be configure using the const at the top.
        let expirationDuration = "PT{0}H";
        expirationDuration = Common.Helper.stringFormat(
            expirationDuration,
            expirationDurationinHours
        );

        this.validateUserInput(reason);
        this.validateUserInput(privilege);

        if (this.state.formErrors.reason === ""
            && this.state.formErrors.privilege === "") {
            this.setState({ isLoading: true });
            const user = this.context;
            var starttime = new Date(Date.now());

            var userElevationRequest = new UserElevationRequest(
                activationType,
                user.userObjectID,
                this.state.privilege,
                "/",
                this.state.reason,
                {
                    startDateTime: starttime,
                    expiration: {
                        type: expirataionType,
                        duration: expirationDuration
                    }
                }
            );

            await GraphUserElevationService.getInstance()
                .ElevateUser(userElevationRequest, user.accessToken)
                .then((results) => {
                    if (results.error) {
                        this.setState({
                            errorCode: results.error.code,
                            errorMessage: results.error.message,
                            showMessageBar: true,
                            isUserElevated: false,
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            showMessageBar: true,
                            isUserElevated: true,
                            isLoading: false
                        });
                    }
                });
        }
    };

    handleCancel = () => {
        this.setState({
            reason: "",
            isUserElevated: false,
            errorMessage: "",
            errorCode: "",
            showMessageBar: false,
            formErrors: { privilege: "", reason: "" }
        });
        console.log(this.state);
    };

    render() {
        return (
            <Portal.CommonPage {...this.pageProps}>
            <Fabric>
                <div className="wrapper">
                    <div
                        className="securedevicesform"
                        style={{ width: "660px" }}
                    >
                        <Label className="securedevicesform-title">{title}</Label>
                        <div className="securedevicesform-body">
                            <div className="form__dropdown">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor="roleName"
                                >
                                    {privilege}
                                </Label>
                                <Dropdown
                                    className="securedevices-dropdown"
                                    id="privilege"
                                    selectedKey={this.state.privilege}
                                    onChange={(event, value) =>
                                        this.handleprivilegeDropdownChange(
                                            event,
                                            value?.key as string
                                        )
                                    }
                                    placeholder={dropdownPlaceHolder}
                                    options={privliegeOptions}
                                    calloutProps={{ doNotLayer: true }}
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.privilege}
                                </Label>
                            </div>

                            <div className="form__text">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor="reason"
                                >
                                    {reason}
                                </Label>
                                <TextField
                                    className="securedevices-input"
                                    id="reason"
                                    multiline
                                    rows={3}
                                    value={this.state.reason}
                                    placeholder={reasonPlaceHolder}
                                    onChange={(event, value) =>
                                        this.handleInputChange(event, value)
                                    }
                                />

                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.reason}
                                </Label>
                            </div>
                        </div>
                        <div className="securedevicesform-footer">
                            <Stack horizontal tokens={stackTokens}>
                                <PrimaryButton
                                    disabled={this.state.isLoading}
                                    onClick={this.handleSubmitPIMElevation}
                                    text={Common.ResourceManager.GetString(
                                        Common.ResourceStrings.Common.Submit
                                    )}
                                />
                                <DefaultButton
                                    disabled={this.state.isLoading}
                                    onClick={this.handleCancel}
                                    text={Common.ResourceManager.GetString(
                                        Common.ResourceStrings.Common.Clear
                                    )}
                                />
                                <img
                                    src={Loading}
                                    hidden={!this.state.isLoading}
                                    style={{
                                        width: "35px",
                                        height: "35px",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    alt="Loading"
                                ></img>
                            </Stack>
                        </div>
                        {this.state.showMessageBar ? (
                            <div className="securedevicesform-header">
                                {this.state.isUserElevated ? (
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={true}
                                        onDismiss={() => this.closeMessageBar()}
                                    >
                                        {Common.Helper.stringFormat(
                                            Common.ResourceManager.GetString(
                                                Common.ResourceStrings.UserElevation
                                                    .UserElevationSuccessMessage
                                            ),
                                            expirationDurationinHours
                                        )}
                                        <div style={{ paddingTop: "5px" }}>
                                            <Icon iconName="Info" style={{ paddingRight: "2px", paddingTop: "2px" }} />                                           
                                            {Common.Helper.stringFormat(
                                                Common.ResourceManager.GetString(
                                                    Common.ResourceStrings.UserElevation
                                                        .UserRefreshLoginMessage
                                                ),
                                                expirationDurationinHours
                                            )}
                                            <i className="iconrefreshuserlogin"></i>                                           
                                       </div>
                                    </MessageBar>
                                ) : (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        onDismiss={() => this.closeMessageBar()}
                                    >
                                        {this.state.errorMessage}
                                    </MessageBar>
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </Fabric>
            </Portal.CommonPage>
        );
    }
}