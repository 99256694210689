import Device from './Device';

export class DeviceOnboardResponseModel {
    responseMessage: string;
    device: Device;
    error: {
        code: string,
        message: string,
    };

    constructor(ResponseMessage: string, Device: Device, Error: {
        code: string,
        message: string,
    }) {
        this.responseMessage = ResponseMessage;
        this.device = Device;
        this.error = Error;
    }
}

export const EmptyDeviceOnboardResponseModel = new DeviceOnboardResponseModel("", new Device("", "", {
    facilityShortCode: "",
    room: "",
    locker: "",
    slot: ""
}, [], []), {
    code: "",
    message: ""
});

export default DeviceOnboardResponseModel;
