import { ApplicationInsightsLogger } from 'Services/Logger';
import type { DynamicFilterDefinition } from "./DynamicFilterTypes";
import { LocationModel } from 'Models/LocationModel';
import { LocationService } from 'Services/LocationService';
import { Constants } from 'Utilities/Constants';

const device = "device";

/**
 * Predefined logic for populating and defining location filters to be used in a CommonList.
 *
 * @remarks The location filter is defined as a composite filter, a filter with child filters.
 * Selected values are represented as an array of key-value pairs. Array length equivalent to hierachy depth. Max depth is 4.
 * 
 * @example Selected location values for a 4-depth definition could look like the array below if the "room" were unselected.
 * [
 *    {"metro": "27"},
 *    {"campus": "93"},
 *    {"facility": "291"},
 *    {"room": ""}
 * ];
 */
export class LocationFilterDefinition {
    private allLocations: LocationModel[];
    private metros: { value: string, text: string }[];
    private campuses: { value: string, text: string }[];
    private facilities: { value: string, text: string }[];
    private filterKeys: {
        metro: string;
        campus: string;
        facility: string;
    };

    /**
     * Initalize a new LocationFilterDefition object.
     * @param type; - The type of resource to filter by location. Ex: "device" or "user".
     */
    constructor(type: any) {
        this.allLocations = [];
        this.metros = [];
        this.campuses = [];
        this.facilities = [];

        this.filterKeys = {
            metro: type === device ? Constants.getInstance().DeviceLocationMetro : "metro",
            campus: type === device ? Constants.getInstance().DeviceLocationCampus : "campus",
            facility: type === device ? Constants.getInstance().DeviceLocationFacility : "facility"
        };
    };

    private setMetroOptions(): void {
        this.allLocations.map((metro) => {
            if (this.metros.findIndex(x => x.value === metro.id) === -1) {
                this.metros.push({ value: metro.id, text: metro.name });
            }
        });
    };

   /**
    * Fetch all location from the service and set metro options key-value pairs.
    * @remarks This is an async funtion.
    * @returns void.
    */
    public fetchLocationData = async (userIdToken: any) => {
        await LocationService.getInstance().GetLocationData(userIdToken).then(results => {
            if (results && !results?.hasOwnProperty("error")) {
                this.allLocations = results;
                this.setMetroOptions();
            } else {
                // TODO: [errors]: graceful error handling pattern required
            }
        });
    };

    /**
     * Filter available campus options by selected metro.
     * @param parentValue - Selected location values
     * @returns Campus options to display.
     */
    private filterCampusOptionsByMetro = (parentValue: { key: string, value: string }[]): { value: string, text: string }[] => {
        console.log("filtering campuses");
        let campusOptions: { value: string, text: string }[] = [];
        const selectedMetroOption = parentValue.find(location => location.key === this.filterKeys.metro);

        try {
            if (selectedMetroOption) {
                this.allLocations.filter(location => location.id === selectedMetroOption.value)
                    .map((metro) => {
                        metro.children.map((campus) => {
                            campusOptions.push({ value: campus.id, text: campus.name });
                        });

                    });
            }
        }
        catch (error) {
            ApplicationInsightsLogger.getInstance().logException(error);
            // TODO: [errors]: graceful error handling pattern required
        }

        this.campuses = campusOptions;

        return this.campuses;
    };

    /**
     * Filter available facility options by selected campus.
     * @param parentValue - Selected location values
     * @returns Facility options to display.
     */
    private filterFacilityOptionsByCampus = (parentValue: { key: string, value: string }[]): { value: string, text: string }[] => {
        var facilityOptions: { value: string, text: string }[] = [];
        const selectedMetroOption = parentValue.find(location => location.key === this.filterKeys.metro);
        const selectedCampusOption = parentValue.find(location => location.key === this.filterKeys.campus);

        try {
            if (selectedMetroOption && selectedCampusOption) {
                this.allLocations.filter(location => location.id === selectedMetroOption.value)
                    .map((metro) => {
                        metro.children.filter(location => location.id === selectedCampusOption.value)
                            .map((campus) => {
                                campus.children.map(facility => {
                                    facilityOptions.push({ value: facility.id, text: facility.name });
                                });
                            });
                    });
            }
        }
        catch (error) {
            ApplicationInsightsLogger.getInstance().logException(error);
            // TODO: [errors] graceful error handling pattern required
        }

        this.facilities = facilityOptions;

        return this.facilities;
    };

    /**
     * Create a DynamicFilterDefinition for the location filter.
     * @remarks A depth of 2 will define metro and campus filters only.
     * @param depth - Depth of the location hierarchy to define filters for. Valid depth values are 1-3
     * @returns Filter definition.
     */
    public createLocationFilterDefinition = (depth: number): DynamicFilterDefinition => {
        let levelDefinitions: DynamicFilterDefinition[] = [
            {
                label: "Metro",
                key: this.filterKeys.metro,
                options: this.metros
            }
        ];

        if (depth > 1) {
            levelDefinitions.push({
                label: "Campus",
                key: this.filterKeys.campus,
                options: this.campuses,
                dependencyKey: this.filterKeys.metro,
                onDependencyValueChange: this.filterCampusOptionsByMetro
            });
        }

        if (depth > 2) {
            levelDefinitions.push({
                label: "Facility",
                key: this.filterKeys.facility,
                options: this.facilities,
                dependencyKey: this.filterKeys.campus,
                onDependencyValueChange: this.filterFacilityOptionsByCampus
            });
        }

        if (depth > 1) {
            return {
                label: "Location",
                key: "location",
                options:  undefined,
                children: levelDefinitions
            };
        }

        return levelDefinitions[0];
    };

    public static parseLocationFilterValue(filter: { key: string, value: string | any[] }): { key: string, value: string }[] {
        if (typeof(filter.value) === "string") {
            return [{key: filter.key, value: filter.value.toString()}];
        }

        return (filter.value as Array<any>);
    };
}