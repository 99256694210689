import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { LocationModel } from '../Models/LocationModel';
import { TracingService } from './TracingService';

var locationData: LocationModel[];
const constants = Constants.getInstance();
let tracingService = TracingService.getInstance();
const Component_Name = 'Location Service';

export class LocationService {
    private static instance: LocationService;

    public static getInstance = () => {
        if (!LocationService.instance) {
            LocationService.instance = new LocationService();
        }
        return LocationService.instance;
    };

    public GetLocationData = async (token: string): Promise<LocationModel[]> => {
        tracingService.trace(Component_Name, 'GetLocationAPICall');

        //currently only me route is supported
        var LocationApiEndpoint = constants.LocationApiEndpoint;
        await fetch(LocationApiEndpoint, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            }
        })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.error.message) || response.statusText;
                    return Promise.reject(error);
                }

                tracingService.traceHttpResponse(response);
                locationData = data.nodes;

            })
            .catch((error) => {
                console.log(error);
                tracingService.traceHttpResponse(error.response);
                ApplicationInsightsLogger.getInstance().logException(error);
            });

        return locationData;
    };
}