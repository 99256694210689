"use client";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { KnownErrors, StandardErrorFallbackPage, StandardErrorProps } from "./ErrorHandling";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { UserContext } from 'Auth/UserContext';
import { frameworkLightTheme } from "Components/Framework/Theme/Theme";
import { DismissRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import "./CommonPage.css";

/**
 * Properties of announcement box on a CommonPage.
 */
export interface IPageAnnouncement {
    text: any;
    closable: boolean;
};

type CommonPageState = {
    displayAnnouncement: boolean;
    currentError?: StandardErrorProps;
}

/**
 * Properties passed to the CommonPage component.
 */
export type CommonPageProps = {
    componentName?: string;
    authRequired: boolean;
    pageTitle: string;
    authorizedRoles: AuthorizedRole[];
    announcement?: IPageAnnouncement;
}

/**
 * Interface for Page classes to standardize page prop definitions.
 * ALL pages must implement this interface.
 */
export interface ICommonPage {
    pageProps: CommonPageProps;
}

/**
 * Wrapper component for all routable web pages. Encapsulates common page behavior.
 * - renders
 *      - the page header
 * - handles
 *      - authorization logic
 *      - some page errors?
 */
export class CommonPage extends React.Component<CommonPageProps, CommonPageState> {

    static contextType = UserContext;

    constructor(props: CommonPageProps) {
        super(props);
        this.state = {
            displayAnnouncement: props.announcement !== undefined
        };
    }

    protected Authorize(): boolean {
        // use context to get userToken and extract userRoles
        const user = this.context;
        console.log("Authorizing user role for page.");

        return Authorization.AuthorizeUser(user.roles, this.props.authorizedRoles);
    }

    private CloseAnnouncement = () => {
        this.setState({
            displayAnnouncement: false
        });
    };

    componentDidMount() {
        if (!this.Authorize()) {
            this.setState({ currentError: KnownErrors.forbidden });
        }
    }

    render() {
        const primaryColor = frameworkLightTheme.colorBrandForeground2;

        return (
            <div style={{ width: "100%", margin: "0 auto" }}>
                <div className='standard-page-title' style={{ color: primaryColor }}>
                    <h1 className="standard-page-content">{this.props.pageTitle}</h1>
                </div>
                <ErrorBoundary FallbackComponent={StandardErrorFallbackPage}>
                    <div className="standard-page-content standard-page-announcement"
                        style={{ display: this.state.displayAnnouncement && !this.state.currentError ? "block" : "none", backgroundColor: frameworkLightTheme.colorBrandBackground2 }}>
                        <div className="closeAnnouncement">
                            <Button
                                style={{ float: 'right', display: this.props.announcement?.closable ? 'block' : 'none' }}
                                onClick={this.CloseAnnouncement}
                                appearance="transparent"
                                icon={<DismissRegular />}
                                size="medium"
                            />
                            </div>
                        <div>{this.props.announcement?.text}</div>
                    </div>
                    <div className="standard-page-content">
                        {!this.state.currentError  ? this.props.children : StandardErrorFallbackPage({error: this.state.currentError})}
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}