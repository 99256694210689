export class DeviceHealthUIModel {
    deviceName: string;
    available:string;
    deviceState: string;
    recommendations: string[];
    batteryStatus: string;
    campus: string;
    metro: string;
    facility: string;
    room: string;
    locker: string;
    slot: string;
    deviceAsset: string;
    deviceSerialNumber: string;
    deviceType: string;
    windowsUpdateDate: Date;
    lastActivityDate: Date;
    deviceMake: string;
    deviceModel: string;
    deviceOS: string;
    storageCapacity: number;
    remainingStorageCapacity: number;
   

    constructor(deviceName: string,
        available: string,
        deviceState: string,
        recommendations: string[],
        batteryStatus: string,
        campus: string,
        metro: string,
        facility: string,
        room: string,
        locker: string,
        slot: string,
        deviceAsset: string,
        deviceSerialNumber: string,
        deviceType: string,
        windowsUpdateDate: Date,
        lastActivityDate: Date,
        deviceMake: string,
        deviceModel: string,
        deviceOS: string,
        storageCapacity: number,
        remainingStorageCapacity: number) {
        this.deviceName = deviceName;
        this.available = available;
        this.deviceState = deviceState;
        this.recommendations = recommendations;
        this.batteryStatus = batteryStatus;
        this.campus = campus;
        this.metro = metro;
        this.facility = facility;
        this.room = room;
        this.locker = locker;
        this.slot = slot;
        this.deviceAsset = deviceAsset;
        this.deviceSerialNumber = deviceSerialNumber;
        this.deviceType = deviceType;
        this.windowsUpdateDate = windowsUpdateDate;
        this.lastActivityDate = lastActivityDate;
        this.deviceMake = deviceMake;
        this.deviceModel = deviceModel;
        this.deviceOS = deviceOS;
        this.storageCapacity = storageCapacity;
        this.remainingStorageCapacity = remainingStorageCapacity;
    }
}
export const EmptyDeviceHealthUIModel = new DeviceHealthUIModel("", "", "",
    [], "", "", "", "", "", "", "", "", "", "",
    new Date(), new Date(), "", "", "", 0, 0
);

export default DeviceHealthUIModel;