import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { ScenarioResponseModel } from 'Models/ScenarioResponseModel';
import { ScenarioService } from './ScenarioService';
import { TracingService } from './TracingService';
import UserReponseModelPagination from '../Models/UserReponseModelPagination';
import * as Common from "Components/Common/ValyrianCommonComponents";
import UpdateUser from '../Models/UpdateUser';
import UserUpdateServiceResponseModel, { EmptyUserUpdateServiceResponseModel } from '../Models/UserUpdateServiceResponseModel';
import { CommonUtils } from '../Utilities/CommonUtils';
import RoleResponseModel from '../Models/RoleResponseModel';
import { UserListApiModel, UserListUIModel, UserMapper } from '../Models/UserListModel';
import DisableUserServiceResponseModel, { EmptyDisableUserServiceResponseModel } from '../Models/DisableUserServiceResponseModel';

const constants = Constants.getInstance();
const Component_Name = 'User Management Service';
let tracingService = TracingService.getInstance();
var scenarioData: ScenarioResponseModel[];
var rolesData: RoleResponseModel[];
export class UserManagementService {
    private static instance: UserManagementService;
    private constructor() {
        scenarioData = ScenarioService.getInstance().GetScenarioData();
        rolesData = CommonUtils.GetRoleData();
    }

    public static getInstance = () => {
        if (!UserManagementService.instance) {
            UserManagementService.instance = new UserManagementService();
        }
        return UserManagementService.instance;
    };
    public GetUserListData = async (token: string, filters: { key: string, value: string | any[] }[], countiToken: string, batchSize?: number): Promise<UserReponseModelPagination> => {
        // filters: { key: string, value: string } []
        tracingService.trace(Component_Name, 'GetUserListAPICall');
        var UserListPaginationApiEndpoint = constants.UserListPaginationApiEndpoint;
        // add paging
        if (batchSize) {
            UserListPaginationApiEndpoint = UserListPaginationApiEndpoint + "&pagesize=" + batchSize.toString();
        }
        var filter = "";
        // Add filters if any
        if (filters !== undefined && filters.length > 0) {
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].value !== "") {
                    if (i !== 0) {
                        filter = filter + " and ";
                    }
                    if (filters[i].key === "scenario") {
                        var scenarioIdToNameMap = CommonUtils.convertScenarioNameToIdMapping(scenarioData);
                        var key = scenarioIdToNameMap.get(filters[i].value);
                        filter = filter + "Filter/" + filters[i].key + " eq '" + key + "'";
                    } else
                        if (filters[i].key === "roles") {
                            var roleIdToNameMap = CommonUtils.convertRoleNameToIdMapping(rolesData);
                            var key = roleIdToNameMap.get(filters[i].value);
                            filter = filter + "Filter/" + filters[i].key + " eq '" + key + "'";
                        } else {
                            filter = filter + "Filter/" + filters[i].key + " eq '" + filters[i].value + "'";
                        }

                }
            }
            if (filter !== "") {
                filter = "&$filter=(" + filter + ')';
            }
        }
        if (filter !== "") {
            UserListPaginationApiEndpoint = UserListPaginationApiEndpoint + filter;
        };
        try {
            var response =
                await fetch(UserListPaginationApiEndpoint, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                        'correlationId': constants.CorrelationID,
                        Authorization: constants.Bearer + token,
                        'x-ms-continuation': countiToken
                    }
                });
            const data = await response.json();
            if (response.ok) {
                return new UserReponseModelPagination(data["continuationToken"], data["users"], data["totalRecords"]);
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;
                return Promise.reject(errorMsg);
            }
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
            return Promise.reject(error);
        };
    };

    public GetUserByAadObjectId = async (token: string, aadObjectId: string) => {
        tracingService.trace(Component_Name, 'GetUserByAadObjectId');
        const UpdateUserEndPoint = Common.Helper.stringFormat(constants.GetUserApiEndpoint, aadObjectId);

        try {
            const response = await this.GetUserApiCall(UpdateUserEndPoint, token);
            const data = await response.json();

            if (response.ok) {
                return {
                    error: false, message: data
                };
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;

                return {
                    error: true,
                    message: errorMsg
                };
            }
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);

            return Promise.reject(error);
        };
    };

    public UpdateUserAsync = async (token: string, displayName: string, firstName: string, lastName: string, metros: string[], scenarios: string[], roles: string[], etag: string, id: string): Promise<UserUpdateServiceResponseModel> => {
        tracingService.trace(Component_Name, 'UpdateUserAPICall');
        let updateUserOutput: UserUpdateServiceResponseModel = EmptyUserUpdateServiceResponseModel;
        const UpdateUserEndPoint = Common.Helper.stringFormat(constants.UpdateUserApiEndpoint, id);
        const updateUserRequest = UpdateUser.getUpdateUserPatchModel(
            this.getUpdateUserRequest(displayName, firstName, lastName, scenarios, roles, metros));
        console.log(updateUserRequest);

        try {
            const response = await this.UpdateUserApiCall(UpdateUserEndPoint, token, updateUserRequest, etag);
            const data = await response.json();
            let updateUserResponse: UserUpdateServiceResponseModel;
            updateUserResponse = data;
            updateUserOutput = UserManagementService.UpdateUserResponseToUpdateUserPortalUI(updateUserResponse);

        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return updateUserOutput;
    };

    public DisableUserAsync = async (token: string, upn: string, etag: string): Promise<DisableUserServiceResponseModel> => {
        tracingService.trace(Component_Name, 'DisableUserAPICall');
        let disableUserOutput: DisableUserServiceResponseModel = EmptyDisableUserServiceResponseModel;
        const UpdateUserEndPoint = Common.Helper.stringFormat(constants.DisableUserApiEndpoint, upn);
        try {
            const response = await this.DisableUserApiCall(UpdateUserEndPoint, token, etag);
            const data = await response.json();
            let disableUserResponse: DisableUserServiceResponseModel;
            disableUserResponse = data;
            disableUserOutput = UserManagementService.DisableUserResponseToUpdateUserPortalUI(disableUserResponse);
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return disableUserOutput;
    };

    public static ApiUsersToUIUsers = (userResponses: UserListApiModel[]) => {
        var userUIModels: UserListUIModel[] = [];
        if (userResponses && userResponses.length > 0) {
            userResponses.forEach(function (userData) {
                userUIModels.push(UserMapper.ApiToUI(userData, ScenarioService.MapUserScenarioName(userData.scenarioIds, scenarioData)));
            });
        }
        return userUIModels;
    };

    private UpdateUserApiCall = async (UpdateUserEndPoint: string, token: string, updateUserRequest: UpdateUser, eTag: string) => {
        return await fetch(UpdateUserEndPoint, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token,
                'If-Match': eTag
            },
            body: JSON.stringify(updateUserRequest)
        });
    };

    private DisableUserApiCall = async (DisableUserEndPoint: string, token: string, eTag: string) => {
        return await fetch(DisableUserEndPoint, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token,
                'If-Match': eTag
            }
        });
    };


    private GetUserApiCall = async (GetUserEndPoint: string, token: string) => {
        return await fetch(GetUserEndPoint, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            }
        });
    };

    private getUpdateUserRequest = (displayName: string, givenName: string,
        surName: string, scenarioIds: string[], roles: string[], locations: string[]) => {

        return new UpdateUser(displayName, givenName,
            surName, scenarioIds, roles, locations);
    };

    private static UpdateUserResponseToUpdateUserPortalUI = (updateUserResponse: UserUpdateServiceResponseModel) => {
        return new UserUpdateServiceResponseModel(
            updateUserResponse.id,
            updateUserResponse.error);
    };

    private static DisableUserResponseToUpdateUserPortalUI = (disableUserResponse: DisableUserServiceResponseModel) => {
        return new DisableUserServiceResponseModel(
            disableUserResponse.id,
            disableUserResponse.error,
            disableUserResponse.user);
    };
}