"use client";
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from "react-error-boundary";
import { DefaultRuntimeErrorFallback } from 'Components/Framework/Page/ErrorHandling';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { Customizer, mergeStyles } from 'office-ui-fabric-react/';

// Inject some global styles
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#root)': {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});

ReactDOM.render(
    <Customizer {...FluentCustomizations}>
        <ErrorBoundary FallbackComponent={DefaultRuntimeErrorFallback}>
            <App />
        </ErrorBoundary>
    </Customizer>,
    document.getElementById('root')
);

