import * as React from "react";
import {
    Button,
    Label
} from "@fluentui/react-components";
import { ChevronLeft16Regular, ChevronRight16Regular } from "@fluentui/react-icons";
import { ResourceStrings } from 'Resources/ResourceKeys';
import ResourceManager from 'Resources/ResourceManager';
import { current } from "immer";

export type PagingParams = {
    pageSize: number;
    batchSize: number;
    token: string;
    totalItemCount: number;
};

// Props passed to the list
export interface IListPagingProps {
    pageSize: number,
    totalCount: number,
    onFetchNextBatch: () => Promise<any>;
    getCache: () => any;
};

// Props passed to the UI component that renders the paging navigation
export interface ListPagingNavigatorProps {
    disabled: boolean;
    pageCount: number;
    onClickNext: (page: number) => void;
    onClickPrevious: (page: number) => void;
    currentPage?: number;
};

// The UI component of paging
export const ControlledListPagingNavigator: React.FunctionComponent<ListPagingNavigatorProps> = (props) => {
    return (
        <div>
            <span key="prev">
                <Button
                    className="list-paging-nav-button"
                    appearance="transparent" icon={<ChevronLeft16Regular />}
                    iconPosition="before"
                    onClick={(event) => props.onClickPrevious(props.currentPage ? props.currentPage - 1 : 0)}
                    disabled={props.disabled || props.currentPage === 1}>
                        {ResourceManager.GetString(ResourceStrings.FrameworkList.Previous)}
                </Button>
            </span>
            <span key="info">
                <Label>{"Page " + props.currentPage
                                + " of " + (props.pageCount)}</Label>
            </span>
            <span key="next">
                <Button
                    className="list-paging-nav-button"
                    appearance="transparent" icon={<ChevronRight16Regular />}
                    iconPosition="after"
                    onClick={(event) => props.onClickNext(props.currentPage ? props.currentPage + 1 : 0)}
                    disabled={props.disabled || props.currentPage === props.pageCount}>
                        {ResourceManager.GetString(ResourceStrings.FrameworkList.Next)}
                </Button>
            </span>
        </div>
    );
};