import * as React from 'react';
import * as Portal from "Components/Framework/Page/CommonPage";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, Selection } from 'office-ui-fabric-react/lib/DetailsList';

import { Constants } from 'Utilities/Constants';
import { DeviceHealthDetails } from './DeviceHealthDetails';
import { DeviceHealthService } from 'Services/DeviceHealthService';
import { DeviceState } from 'Models/DeviceState';
import { DeviceHealthUIModel, EmptyDeviceHealthUIModel } from 'Models/DeviceHealthUIModel';
import { Helper } from 'Utilities/Helper';
import { LocationFilters } from 'Components/Framework/List/LocationFilters';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import ResourceManager from 'Resources/ResourceManager';
import Loading from 'Images/Loading.gif';
import './DeviceHealth.css';
import { CSVLink } from 'react-csv';
import { Authorization, AuthorizedRole } from "Auth/Authorization";

// Move the following values to the localization project
const Component_Name = 'Device Health';
const ColumnName_DeviceAsset = ResourceManager.GetString(ResourceStrings.Device.AssetId);
const ColumnName_Type = ResourceManager.GetString(ResourceStrings.Device.Scenario);
const ColumnName_DeviceState = ResourceManager.GetString(ResourceStrings.DeviceHealth.DeviceState);
const ColumnName_Recommendations = ResourceManager.GetString(ResourceStrings.DeviceHealth.Recommendations);
const ColumnName_BatteryStatus = ResourceManager.GetString(ResourceStrings.DeviceHealth.BatteryStatus);
const ColumnName_Campus = ResourceManager.GetString(ResourceStrings.Locations.Campus);
const ColumnName_Facility = ResourceManager.GetString(ResourceStrings.Locations.Facility);
const ColumnName_Room = ResourceManager.GetString(ResourceStrings.Locations.Room);
const ColumnName_Locker = ResourceManager.GetString(ResourceStrings.Locations.Locker);
const ColumnName_Slot = ResourceManager.GetString(ResourceStrings.Locations.Slot);
const UnAuthorized = ResourceManager.GetString(ResourceStrings.DeviceHealth.UnAuthorized);
let AlertMessage = ResourceManager.GetString(ResourceStrings.DeviceHealth.NoDevicesFound);
const headers = [
    { label: ColumnName_DeviceAsset, key: ResourceManager.GetString(ResourceStrings.DeviceKey.DeviceAsset) },
    { label: ColumnName_Type, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Scenario) },
    { label: ColumnName_DeviceState, key: ResourceManager.GetString(ResourceStrings.DeviceKey.DeviceState) },
    { label: ColumnName_Recommendations, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Recommendations) },
    { label: ColumnName_BatteryStatus, key: ResourceManager.GetString(ResourceStrings.DeviceKey.BatteryStatus) },
    { label: ColumnName_Campus, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Campus) },
    { label: ColumnName_Facility, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Facility) },
    { label: ColumnName_Room, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Room) },
    { label: ColumnName_Locker, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Locker) },
    { label: ColumnName_Slot, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Slot) }
];

export interface IDeviceHealthState {
    items: DeviceHealthUIModel[];
    filters: { key: string, value: string }[];
    selectedDevice: DeviceHealthUIModel;
    displayDetails: boolean;
    isLoading: boolean;
}

let tracingService = TracingService.getInstance();
let deviceHealthService = DeviceHealthService.getInstance();

export class DeviceHealth extends React.Component<{}, IDeviceHealthState> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Device Health",
        authorizedRoles: [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ]
    };
    private _allItems: DeviceHealthUIModel[];
    private _columns: IColumn[];
    private _selection: Selection;
    private fileName = "Device Health.csv";

    constructor(props: {}) {
        super(props);

        this._allItems = [];
        
        this._columns = [
            {
                key: this.getColumnKey(ColumnName_DeviceAsset),
                name: ColumnName_DeviceAsset,
                fieldName: 'deviceAsset',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Type),
                name: ColumnName_Type,
                fieldName: 'deviceType',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_DeviceState),
                name: ColumnName_DeviceState,
                fieldName: 'deviceState',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Recommendations),
                name: ColumnName_Recommendations,
                fieldName: 'recommendations',
                headerClassName: 'dark-hover',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_BatteryStatus),
                name: ColumnName_BatteryStatus,
                fieldName: 'batteryStatus',
                headerClassName: 'dark-hover',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true
            },
            {
                key: this.getColumnKey(ColumnName_Campus),
                name: ColumnName_Campus,
                fieldName: 'campus',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Facility),
                name: ColumnName_Facility,
                fieldName: 'facility',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Room),
                name: ColumnName_Room,
                fieldName: 'room',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Locker),
                name: ColumnName_Locker,
                fieldName: 'locker',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Slot),
                name: ColumnName_Slot,
                fieldName: 'slot',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            }
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (this._selection.count === 1) {
                    this.setState({ selectedDevice: this._selection.getSelection()[0] as DeviceHealthUIModel });
                }
            }
        });

        this.state = {
            filters: [],
            items: this._allItems,
            selectedDevice: EmptyDeviceHealthUIModel,
            displayDetails: false,
            isLoading: true
        };
    }
    getColumnKey = (ColumnName: string) => {
        return ColumnName.split(" ").join("");
    };

    private Constant = Constants.getInstance();

    static contextType = UserContext;

    onRenderItemColumn = (item: DeviceHealthUIModel, index: number | undefined, column: IColumn | undefined): JSX.Element | string | string[] | number | Date => {
        // Clickable Asset Id
        if (column?.key === this.getColumnKey(ColumnName_DeviceAsset)) {
            return <button className="device-health-item-btn" onClick={() => this.DisplayDeviceHealthDetails(item.deviceAsset)} >{item.deviceAsset}</button>;
        }

        // Device State Icons
        if (column?.key === this.getColumnKey(ColumnName_DeviceState)) {
            if (item.deviceState === DeviceState.Healthy)
                return <div> <Icon iconName="CompletedSolid" style={{ color: 'green' }} /> {Helper.CamelCaseToText(DeviceState.Healthy)} </div>;
            if (item.deviceState === DeviceState.NeedsAttention)
                return <div> <Icon iconName="IncidentTriangle" style={{ color: '#F39C12' }} /> {Helper.CamelCaseToText(DeviceState.NeedsAttention)}  </div>;
            if (item.deviceState === DeviceState.Unhealthy)
                return <div> <Icon iconName="BlockedSiteSolid12" style={{ color: 'red' }} /> {Helper.CamelCaseToText(DeviceState.Unhealthy)}  </div>;
        }

        // Recommended Action List
        if (column?.key === this.getColumnKey(ColumnName_Recommendations)) {
            return (<div>
                {
                    item.recommendations?.map((recommendation, i) => {
                        return (<ul><li key={'action' + i}>{recommendation}</li></ul>);
                    })
                }
            </div>);
        }

        return item[column?.fieldName as keyof DeviceHealthUIModel];
    };

    private DisplayDeviceHealthDetails(id: string) {
        const device = this.state.items.find(device => device.deviceAsset === id);
        if (device) {
            this.setState({
                selectedDevice: device,
                displayDetails: true
            });
        }
    }

    private CloseDeviceDetails = () => {
        this.setState({ displayDetails: false });
    };

    private locationFilterCallback = (selectedCampusItem: string, selectedFacilityItem: string, selectedRoomItem: string, selectedMetroItem: string, selectedScenarioItem: string) => {
        if (selectedScenarioItem !== '' && selectedMetroItem === '') {
            this.setState({
                filters: [{ key: this.Constant.DeviceLocationScenario, value: selectedScenarioItem }]
            }, this.getdevicehealthdata);
        } else {
            this.setState({
                filters: [{ key: this.Constant.DeviceLocationMetro, value: selectedMetroItem },
                { key: this.Constant.DeviceLocationCampus, value: selectedCampusItem },
                { key: this.Constant.DeviceLocationFacility, value: selectedFacilityItem },
                { key: this.Constant.DeviceLocationRoom, value: selectedRoomItem },
                { key: this.Constant.DeviceLocationScenario, value: selectedScenarioItem }
                ]
            }, this.getdevicehealthdata);
        }
        console.log(this.state.filters);

    };

    private getdevicehealthdata = async () => {
        const user = this.context;
        let hasDeviceRole = Authorization.AuthorizeUser(user.roles, [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ]);
        
        if (user.IdToken && hasDeviceRole) {
            tracingService.trace(Component_Name, 'Authorized siteAdministrator, now fetching device health data');
            this.setState({ isLoading: true });
            await deviceHealthService.GetDeviceHealthData(user.IdToken, this.state.filters)
                .then(results => {
                    this.setState({ items: results.sort((a, b) => (a.deviceState < b.deviceState) ? 1 : -1) });
                    this.setState({ isLoading: false });
                })
                .catch(error => {
                    tracingService.trace(Component_Name, error);
                    AlertMessage = 'Error retrieving device health data.';
                    this.setState({ isLoading: false });
                });
        }
        else {
            tracingService.trace(Component_Name, 'Not authorized  as siteAdministrator or capacityManager');
            AlertMessage = UnAuthorized;
            this.setState({ isLoading: false });
        }
    };

    async componentDidMount() {
        this.getdevicehealthdata();
    }

    public render(): JSX.Element {
        const { items } = this.state;
        return (
            <Portal.CommonPage {...this.pageProps}>
            <Fabric>
                <div className="device-health-component ghostwhite">
                    <div className='device-health-container'>
                    <div><CSVLink style={{ float: "right", width: "relative" }} data={this.state.items} filename={this.fileName} headers={headers} target="_blank" >
                            <button className="buttonSD-secondary" style={{ border: '0px', font: 'Segoe UI', fontSize: '16px', fontWeight: 500 }}>Export </button></CSVLink></div>
                        <LocationFilters
                            toCallBack={(selectedCampusItem: string, selectedFacilityItem: string, selectedRoomItem: string, selectedMetroItem: string, selectedScenarioItem: string) => this.locationFilterCallback(selectedCampusItem, selectedFacilityItem, selectedRoomItem, selectedMetroItem, selectedScenarioItem)} ></LocationFilters>
                        <div className='device-health-table-header dark' />
                        <div style={{ position: 'relative', width: '100%' }}>
                            {this.state.displayDetails ? <DeviceHealthDetails device={this.state.selectedDevice} onClose={this.CloseDeviceDetails} /> : <DetailsList
                                items={items}
                                columns={this._columns}
                                setKey="set"
                                selection={this._selection}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                onItemInvoked={this.DisplayDeviceHealthDetails}
                                onRenderItemColumn={this.onRenderItemColumn}
                            />}
                            <img src={Loading} hidden={!this.state.isLoading} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Loading"></img>
                            <div style={{ textAlign: 'center' }} hidden={this.state.isLoading || items.length > 0}><h5>{AlertMessage}</h5></div>
                        </div>
                        <div className='device-health-table-footer dark' />
                    </div>
                </div>
            </Fabric>
            </Portal.CommonPage>
        );
    }

}