import * as React from 'react';
import { IIconProps } from 'office-ui-fabric-react/lib/Icon';
import { ActionButton } from 'office-ui-fabric-react';

import { DeviceHealthUIModel } from 'Models/DeviceHealthUIModel';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import ResourceManager from 'Resources/ResourceManager';
import "scss/site.css";

// Move the following values to the localization project
const Component_Name = 'Device Health Details';
const BackToList = 'Back to list';
const DeviceDetail_SerialNumber = ResourceManager.GetString(ResourceStrings.Device.SerialNumber);
const DeviceDetail_Scenario = ResourceManager.GetString(ResourceStrings.Device.Scenario);
const DeviceDetail_OS = ResourceManager.GetString(ResourceStrings.DeviceHealth.OS);
const DeviceDetail_LastHeartbeat = ResourceManager.GetString(ResourceStrings.DeviceHealth.LastHeartbeat);
const DeviceDetail_LastWindowsUpdate = ResourceManager.GetString(ResourceStrings.DeviceHealth.LastWindowsUpdate);
const DeviceDetail_HardwareModel = ResourceManager.GetString(ResourceStrings.DeviceHealth.HardwareModel);
const DeviceDetail_StorageCapacity = ResourceManager.GetString(ResourceStrings.DeviceHealth.StorageCapacity);
const DeviceDetail_StorageRemaining = ResourceManager.GetString(ResourceStrings.DeviceHealth.StorageRemaining);
const DeviceDetail_Windows = 'Windows';
const NotAvailable = "---";
const Gigabytes = "GB";

let tracingService = TracingService.getInstance();

interface IDeviceHealthDetailsProps {
    device: DeviceHealthUIModel;
    onClose: () => any;
}
const ChromeBackIcon: IIconProps = { iconName: 'ChromeBack' };

export class DeviceHealthDetails extends React.Component<IDeviceHealthDetailsProps> {
    private device: DeviceHealthUIModel;

    constructor(props: IDeviceHealthDetailsProps) {
        super(props);
        this.device = props.device;
    }

    static contextType = UserContext;

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying device details');
    }


    public render(): JSX.Element {
        return (
            <div style={{ outline: "1px solid black", backgroundColor: '#fefefe', width: '100%', minHeight:'300px', position: 'absolute', top: 0, left: 0, bottom: 0, zIndex: 1 }}>
                <div style={{ marginLeft: '2%'}}>  
                <ActionButton onClick={this.props.onClose} iconProps={ChromeBackIcon}>
                    {BackToList}
                </ActionButton> 
                </div>
                <div style={{ width: '90%', margin: '1rem auto', position: 'relative' }}>                      
                    <h2 className="title" style={{ margin: '20px 0' }}>
                        {this.device.deviceName}
                    </h2>
                
                    <div id="device-details">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%'
                        }}>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_SerialNumber}</h5>
                                <p>{this.device.deviceSerialNumber !== null ? this.device.deviceSerialNumber : NotAvailable}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_Scenario}</h5>
                                <p>{this.device.deviceType !== null ? this.device.deviceType : NotAvailable}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_LastWindowsUpdate}</h5>
                                <p>{this.device.windowsUpdateDate !== null ? new Date(this.device.windowsUpdateDate).toUTCString() : NotAvailable}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_LastHeartbeat}</h5>
                                <p>{this.device.lastActivityDate !== null ? new Date(this.device.lastActivityDate).toUTCString() : NotAvailable}</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: '1%'
                        }}>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_HardwareModel}</h5>
                                <p>{(this.device.deviceMake !== null ? this.device.deviceMake : NotAvailable) + " " + (this.device.deviceModel !== null ? this.device.deviceModel : NotAvailable)}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_OS}</h5>
                                <p>{this.device.deviceOS !== null ? DeviceDetail_Windows + " " + this.device.deviceOS : NotAvailable}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_StorageCapacity}</h5>
                                <p>{this.device.storageCapacity !== 0 ? Math.round(this.device.storageCapacity)+ " " + Gigabytes : NotAvailable}</p>
                            </div>
                            <div style={{ flex: "1 0 20%" }}>
                                <h5 className="header">{DeviceDetail_StorageRemaining}</h5>
                                <p>{this.device.remainingStorageCapacity !== 0 ? Math.round(this.device.remainingStorageCapacity) + " " + Gigabytes : NotAvailable}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}