import * as React from "react";
import { memo } from "react";
import Dot from "./Dot";

//===========================================
// DOTS FUNCTIONAL COMPONENT
//===========================================

interface DotsProps {
  slideId: number;
  slides: string[];
  // any other props that come into the component
}

const Dots = ({ slideId, slides }: DotsProps) => {
  return (
    <div className="fullW height70 abs bot0 fCenter">
      <div className="row" style={{ marginLeft: "2%" }}>
        {slides.map((slide, id) => (
          <Dot slideId={slideId} dotId={id} key={id} />
        ))}
      </div>
    </div>
  );
};

export default memo(Dots);
