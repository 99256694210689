import { DeviceApiModel } from "./DeviceModel";

export class DeviceReponseModelPagination {
    countiToken: string;
    devices: DeviceApiModel[];
    totalRecords: number;


    constructor(countiToken: string,
        devices: DeviceApiModel[],
        totalRecords: number
        ) {
        this.countiToken = countiToken;
        this.devices = devices;
        this.totalRecords = totalRecords;
        
    }
}

export const EmptyDevicePageReponseModel = null;

export default DeviceReponseModelPagination;