import * as React from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import {
    Checkbox, DefaultButton, Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, PrimaryButton, TextField, Stack, IStackTokens } from "@fluentui/react";
import { EmptyUserListApiModel, UserListUIModel } from "../../../Models/UserListModel";
import "../../../scss/common.css";
import { UserManagementService } from 'Services/UserManagementService';
import { ScenarioService } from "Services/ScenarioService";
import { UserContext } from 'Auth/UserContext';
import * as Common from "Components/Common/ValyrianCommonComponents";
import { ScenarioResponseModel } from "../../../Models/ScenarioResponseModel";
import Loading from 'Images/Loading.gif';
import { CommonUtils } from "../../../Utilities/CommonUtils";
import { Authorization, AuthorizedRole } from "../../../Auth/Authorization";
import { CreateSingleUserService } from "Services/CreateSingleUserService";
import { ErrorBoundary } from "react-error-boundary";
import { KnownErrors, StandardErrorFallbackPage, StandardErrorProps } from "Components/Framework/Page/ErrorHandling";

var scenarioOptions: IDropdownOption[] = [];
var roleOptions: IDropdownOption[] = [];
var metroOptions: IDropdownOption[] = [];

let createSingleUserService = CreateSingleUserService.getInstance();
let userManagementService = UserManagementService.getInstance();
let AlertMessage = "";

const firstName = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.firstName
);
const metro = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.metro
);
const displayName = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.displayName
);
const lastName = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.lastName
);
const scenarioPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.scenarioPlaceholder
);
const rolesPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.rolesPlaceholder
);
const metroPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.metroPlaceholder
);
const firstNameInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.firstNameInvalidMessage
);
const lastNameInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.lastNameInvalidMessage
);
const displayNameInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.UpdateUserKeys.displayNameInvalidMessage
);
const userWithNoDCATApprovalMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UserWithNoDCATApprovalMessage
);
const userWithNoCOIMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UserWithNoCOIMessage
);
const notificationStatusSuccess = Common.ResourceManager.GetString(
    Common.ResourceStrings.Notification.NotificationStatusSuccess
);
const notificationStatusFailure = Common.ResourceManager.GetString(
    Common.ResourceStrings.Notification.NotificationStatusFailure
);
const checkBoxStyles = {
    text: {
        fontWeight: "500"
    }
};
var scenarioData: ScenarioResponseModel[];
const stackTokens: IStackTokens = { childrenGap: 15 };

interface IUserDetailsProps {
    user: UserListUIModel;
    onClose: any;
    loggedInUserDetails: any;
    metroMetadata: any;
}

export class EditUserPage extends React.Component<IUserDetailsProps, any, any> implements Portal.ICommonPage {
    private user: UserListUIModel;
    static contextType = UserContext;
    private Constant = Common.Constants.getInstance();
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Edit User",
        authorizedRoles: [AuthorizedRole.SystemAdmin, AuthorizedRole.OrganizationAdministrator, AuthorizedRole.ScenarioOwner, AuthorizedRole.ScenarioChamp, AuthorizedRole.ScenarioUser]
    };

    constructor(props: any) {
        super(props);
        this.user = props.user;
        this.user.accountEnabled = props.user.accountEnabled ?? true;
        scenarioData = ScenarioService.getInstance().GetScenarioData();
        this.state = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            displayName: this.user.displayName,
            userId: this.user.id,
            email: this.user.email,
            principalName: this.user.userPrincipalName,
            scenarios: this.user.scenarioIds,
            metro: CommonUtils.convertMetroData(this.user.metro),
            roles: this.user.roles,
            etag: this.user.etag,
            isLoading: false,
            formErrors: {
                firstName: "",
                lastName: "",
                displayName: "",
                metro: ""
            },
            isUserUpdated: false,
            showMessageBar: true,
            errorMessage: "",
            errorCode: "",
            selectedUser: props.user,
            loggedInUserDetails: props.loggedInUserDetails,
            metroMetadata: props.metroMetadata,
            isActive: this.user.accountEnabled,
            userApiCall: false,
            isUserCreated: false,
            isNotificationSent: false,
            userEmail: "",
            isValidUser: false,
            isUserOrgAdmin: false,
            showNonDCATCreateButton: false,
            skipDcat: false,
            showNonCOICreateButton: false,
            skipCoi: false,
            updateUserApiCall: false,
            disableUserApiCall: false,
            isUserDisabled: false,
            isSingleDropdown: false
        };
        this.validateField = this.validateField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.onDropdownMultiChange = this.onDropdownMultiChange.bind(this);
        this.handleSubmitUserUpdate = this.handleSubmitUserUpdate.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.userUpdateSuccessMessage =
            this.userUpdateSuccessMessage.bind(this);
        this.UserFriendlyErrorMessageMapping =
            this.UserFriendlyErrorMessageMapping.bind(this);
        this.closeMessageBar = this.closeMessageBar.bind(this);
        this.fillScenarioDropdownOptions = this.fillScenarioDropdownOptions.bind(this);
        this.userCreationSuccessMessage =
            this.userCreationSuccessMessage.bind(this);
        this.userCreationSuccessButErrorInSendingAccountDetailsMessage =
            this.userCreationSuccessButErrorInSendingAccountDetailsMessage.bind(this);
        this.Authorize = this.Authorize.bind(this);
    };

    private loggedInUserWithOrgAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator]);
    };

    private loggedInUserWithSysAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.SystemAdmin]);
    };

    private loggedInUserWithScenarioOwnerRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.ScenarioOwner]);
    };

    private Authorize(user: any): boolean {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, this.pageProps.authorizedRoles);
    };

    //TODO : Mapping known error messages with user frendly messages with the combination of
    //error codes and errormsg strings as API doesnot return appropriate errorcodes for all error msgs
    //In future once API returns approriate error codes this logic can be modified to check only with errorcodes.
    //update the contact mechanisum when valsupport is retired
    UserFriendlyCreateUserErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedInternalServerErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "Conflict":
                return Common.ResourceManager.GetString(
                    Common.ResourceStrings.CreateSingleUser
                        .UserCreationFailedUPNExistsMessage
                );
            case "BadRequest":
                if (
                    this.state.errorMessage.includes("userPrincipalName already exists")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedUPNExistsMessage
                    );
                } else if (
                    this.state.errorMessage.includes("scenarioIds are invalid")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedScenariosInvalidMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                } else if (
                    this.state.errorMessage.includes("email domain is invalid")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedEmailDomainInvalidMessage
                    );
                } else if (
                    this.state.errorMessage.includes(
                        userWithNoDCATApprovalMessage
                    )
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedNoDCATMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                }
                else if (
                    this.state.errorMessage.includes(
                        userWithNoCOIMessage
                    )
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedNoCOIMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                }
                //If none of the above conditions are met then we display actual error message from API
                //to ensure UI never breaks even if error strings are modified on API end.
                else {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedBadRequestGenericMessage,
                            this.state.errorMessage
                        )
                    );
                }
            //If the error is not handled above then we display actual error message from API
            //to ensure UI never breaks even if error strings are modified on API end.
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    UserFriendlyErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateUserKeys
                            .UpdateUserFailedInternalServerErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "PreconditionFailed":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateUserKeys
                            .UpdateUserFailedPreconditionErrorMessage,
                        this.state.email
                    )
                );
            case "NotFound":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateUserKeys
                            .UpdateUserFailedNotFoundErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "BadRequest":
                if (
                    this.state.errorMessage.includes("The scenarioIds are invalid:")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(Common.ResourceStrings.UpdateUserKeys
                            .UpdateUserFailedBadRequestGenericMessage,
                            this.state.errorMessage
                        )
                    );
                }
                else {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.UpdateUserKeys
                                .UpdateUserFailedBadRequestGenericMessage,
                            this.state.errorMessage
                        )
                    );
                }
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.UpdateUserKeys
                            .UpdateUserFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    UserFriendlyDisableUserErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                if (this.state.errorMessage.includes("User cannot be Decativated from AAD :")) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(Common.ResourceStrings.DisableUserKeys
                            .DisableUserFailedAdminRoleErrorMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                }
                else {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.DisableUserKeys
                                .DisableUserFailedInternalServerErrorMessage,
                            this.state.errorMessage
                        )
                    );
                }
            case "NotFound":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DisableUserKeys
                            .DisableUserFailedNotFoundErrorMessage,
                        this.state.errorMessage
                    )
                );
            case "BadRequest":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DisableUserKeys
                            .DisableUserFailedBadRequestGenericMessage,
                        this.state.errorMessage
                    )
                );
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DisableUserKeys
                            .DisableUserFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    validateField(fieldName: any, value: any) {
        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case firstName:
                let firstNameValid = value?.length > 0;
                fieldValidationErrors.firstName = firstNameValid
                    ? ""
                    : firstNameInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case lastName:
                let lastNameValid = value?.length > 0;
                fieldValidationErrors.lastName = lastNameValid
                    ? ""
                    : lastNameInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case displayName:
                let displayNameValid = value?.length > 0;
                fieldValidationErrors.displayName = displayNameValid
                    ? ""
                    : displayNameInvalidMessage;
                this.setState({
                    isLoading: false
                });
                break;
            case metro:
                let metroValid = value?.length > 0;
                fieldValidationErrors.metro = metroValid
                    ? ""
                    : metroPlaceholder;
                this.setState({
                    isLoading: false
                });
                break;
            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors});
    }

    handleSubmitUserUpdate = async () => {
        this.setState({
            isLoading: true,
            userApiCall: false,
            userDisableApiCall: false,
            userUpdateApiCall: false,
            isUserDisabled: false,
            isUserCreated: false,
            isUserUpdated: false,
            isSingleDropdown: false

        });

        if (this.state.isActive && this.user.accountEnabled) {
            this.validateField(firstName, this.state.firstName);
            this.validateField(lastName, this.state.lastName);
            this.validateField(displayName, this.state.displayName);
            this.validateField(metro, this.state.metro);
        }
        if (
            this.state.formErrors.displayName === "" &&
            this.state.formErrors.firstName === "" &&
            this.state.formErrors.lastName === "" &&
            this.state.formErrors.metro === "") {
            console.log("submitting these values : ");
            await this.handleUserUpdate().then((results) => {
                this.setState({ isLoading: false });
            });
        }
    };

    userUpdateSuccessMessage = () => {
        console.log(
            Common.Helper.stringFormat(
                Common.ResourceStrings.UpdateUserKeys
                    .UpdateUserSuccessMessage,
                this.state.email
            )
        );
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.UpdateUserKeys
                    .UpdateUserSuccessMessage,
                this.state.email
            )
        );
    };

    userDisableSuccessMessage = () => {
        console.log(
            Common.Helper.stringFormat(
                Common.ResourceStrings.DisableUserKeys
                    .DisableUserSuccessMessage,
                this.state.email
            )
        );
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.DisableUserKeys
                    .DisableUserSuccessMessage,
                this.state.email
            )
        );
    };


    userCreationSuccessMessage = () => {
        console.log(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessMessage,
                this.state.userEmail
            )
        );
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessMessage,
                this.state.userEmail
            )
        );
    };

    //update the contact mechanisum when valsupport is retired
    userCreationSuccessButErrorInSendingAccountDetailsMessage = () => {
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessButErrorInSendingAccountDetailsMessage,
                this.state.userEmail,
                this.Constant.getSupportEmail
            )
        );
    };

    fillScenarioDropdownOptions = () => {
        const user = this.context;
        if (this.state.roles[0] === AuthorizedRole.ScenarioUser || this.state.roles[0] === AuthorizedRole.ScenarioOwner) {
            this.setState({ isSingleDropdown: true });
        }
        if (this.loggedInUserWithSysAdminRole(user)) {
            scenarioOptions = scenarioData.map(option => ({ key: option.id, text: option.name }));
        } else if (scenarioOptions.length === 0) {
            if (this.loggedInUserWithOrgAdminRole(user)) {
                scenarioOptions = scenarioData
                    .filter(option => option.id !== 'ValyrianSupport')
                    .filter(option => option.id !== 'ValyrianOps')
                    .map(option => ({ key: option.id, text: option.name }));

            } else {
                this.props.loggedInUserDetails.scenarioIds.forEach((scenario: string) => {
                    scenarioOptions.push({ key: scenario, text: CommonUtils.convertScenarioIdToNameMapping(scenarioData).get(scenario) });
                });
            }
        }
    };
    fillRoleDropdownOptions = () => {
        const user = this.context;
        if (roleOptions.length === 0) {
            if (this.loggedInUserWithSysAdminRole(user)) {
                CommonUtils.GetRoleData().forEach((option) => {
                    roleOptions.push({ key: option.id, text: option.name });
                });
            } else if (this.loggedInUserWithOrgAdminRole(user)) {
                CommonUtils.GetOrgAdminRoleData().forEach((option) => {
                    roleOptions.push({ key: option.id, text: option.name });
                });
            } else if (this.loggedInUserWithScenarioOwnerRole(user) && this.user.scenarioIds.includes('ValyrianSupport')) {
                CommonUtils.GetRoleDataScenarioOwnerValSupportView().forEach((option) => {
                    roleOptions.push({ key: option.id, text: option.name });
                });
                return;
            } else if (this.loggedInUserWithScenarioOwnerRole(user)) {
                CommonUtils.GetRoleDataScenarioOwnerView().forEach((option) => {
                    roleOptions.push({ key: option.id, text: option.name });
                });
            } else {
                this.props.loggedInUserDetails.roles.forEach((role: string) => {
                    roleOptions.push({ key: role, text: role });
                });
            }
        }
    };

    fillMetroDropdownOptions = () => {
        if (metroOptions.length === 0) {
            this.props.metroMetadata.forEach((location: { id: any; name: any; }) => {
                metroOptions.push({ key: location.id, text: location.name });
            });
        }
    };

    handleInputChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >,
        newValue: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const { asset } = this.state;
        const newAsset = { [target.id]: newValue };
        this.setState(newAsset);
    };


    onDropdownMultiChange = (event: any, value: { selected: any; key: any; } | any) => {
        if (value) {
            const newKeys = [...(this.state.scenarios ?? [])];
            if (value.selected) {
                newKeys.push(value.key);
            } else {
                const index = newKeys.indexOf(value.key);
                if (index !== -1) {
                    newKeys.splice(index, 1);
                }
            }
            this.setState({ scenarios: newKeys });
        }
    };

    handleDropdownChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
        >,
        value: any | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const newResource = { [target.id]: [value] };
        this.setState(newResource);
        this.setState({ role: [value] });

        const user = this.context;
        if (value === (AuthorizedRole.ScenarioUser) || value === (AuthorizedRole.ScenarioOwner)) {
            this.setState({ isSingleDropdown: true });
        } else if (value === (AuthorizedRole.ScenarioChamp) || value === (AuthorizedRole.OrganizationAdministrator)) {
            this.setState({ isSingleDropdown: false });
        }

    };


    handleCheckboxChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        this.setState({ isActive: !!checked });
    };

    private handleUserUpdate = async () => {
        const user = this.context;
        this.setState({
            isLoading: true
        });
        if (this.state.isActive) {
            if (this.user.accountEnabled === false) {
                //create
                await createSingleUserService
                    .ReactivateUser(
                        user.IdToken,
                        this.state.displayName,
                        user.userPrincipalName,
                        this.state.firstName,
                        this.state.lastName,
                        this.state.email,
                        this.state.scenarios,
                        this.state.roles,
                        this.state.company,
                        true,
                        true
                    )
                    .then((results) => {
                        console.log(results);
                        if (results.error) {
                            this.setState({
                                userApiCall: true,
                                showMessageBar: true,
                                isUserCreated: false,
                                isNotificationSent: false,
                                userEmail: this.state.email,
                                errorMessage: results.error.message,
                                errorCode: results.error.code,
                                isActive: false
                            });
                            if (this.state.errorMessage.includes(userWithNoDCATApprovalMessage) && Common.Constants.getNonDCATApprovedScenarios().includes(this.state.role) && this.state.isUserOrgAdmin === true) {
                                this.setState({ showNonDCATCreateButton: true, skipDcat: true });
                            }
                            if (this.state.errorMessage.includes(userWithNoCOIMessage) && Common.Constants.getNonCOIScenarios().includes(this.state.scenario) && this.state.isUserOrgAdmin === true) {
                                this.setState({ showNonCOICreateButton: true, skipCoi: true });
                            }
                        } else if (results.notificationStatus === notificationStatusSuccess) {
                            this.setState({ userEmail: this.state.email });
                            this.setState({
                                userApiCall: true,
                                isUserCreated: true,
                                isNotificationSent: true,
                                showMessageBar: true,
                                errorMessage: "",
                                errorCode: "",
                                isActive: results.user.accountEnabled
                            });
                        } else if (results.notificationStatus === notificationStatusFailure) {
                            this.setState({ userEmail: this.state.email });
                            this.setState({
                                userApiCall: true,
                                isUserCreated: true,
                                isNotificationSent: false,
                                showMessageBar: true,
                                errorMessage: "",
                                errorCode: "",
                                isActive: results.user.accountEnabled
                            });
                        }
                        this.user.accountEnabled = this.state.isActive;
                    });
            } else {
                //update
                await userManagementService
                    .UpdateUserAsync(
                        user.IdToken,
                        this.state.displayName,
                        this.state.firstName,
                        this.state.lastName,
                        this.state.metro,
                        this.state.roles[0] === AuthorizedRole.ScenarioUser || this.state.roles[0] === AuthorizedRole.ScenarioOwner ? [this.state.scenarios[0]] : this.state.scenarios,
                        this.state.roles,
                        this.state.etag,
                        this.state.userId
                    )
                    .then((results) => {
                        if (results.error) {
                            this.setState({
                                userUpdateApiCall: true,
                                showMessageBar: true,
                                isUserUpdated: false,
                                errorMessage: results.error.message,
                                errorCode: results.error.code
                            });
                        } else if (results.id === this.user.id) {
                            this.setState({ userEmail: this.Constant.getSupportEmail });
                            this.setState({
                                isLoading: false,
                                email: this.user.email,
                                userUpdateApiCall: true,
                                isUserUpdated: true,
                                showMessageBar: true,
                                errorMessage: "",
                                id: results.id,
                                errorCode: ""
                            });
                        }
                    });
            }
        } else {
            if (this.user.accountEnabled === true) {
                await userManagementService.DisableUserAsync(user.IdToken, this.state.principalName, this.state.isActive).then((results) => {
                    if (!results.user) {
                        this.setState({
                            showMessageBar: true,
                            isUserUpdated: false,
                            errorMessage: results.error.message,
                            errorCode: results.error.code,
                            userDisableApiCall: true,
                            isUserDisabled: false
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            showMessageBar: true,
                            errorMessage: "",
                            id: results.user.userPrincipalName,
                            errorCode: "",
                            isActive: results.user.accountEnabled,
                            userDisableApiCall: true,
                            isUserDisabled: true
                        });
                    }
                    this.user.accountEnabled = this.state.isActive;
                });
            }
        }
    };

    closeMessageBar = () => {
        this.props.onClose();
        this.setState({
            userUpdateApiCall: false,
            isUserUpdated: false,
            showMessageBar: false,
            errorMessage: "",
            errorCode: "",
            userDisableApiCall: false,
            userApiCall: false
        });

    };

    private handleCancel = () => {
        this.setState({
            selectedUser: EmptyUserListApiModel,
            selectedItemShouldOpen: false
        });
    };

    async componentDidMount() {
        this.fillScenarioDropdownOptions();
        this.fillMetroDropdownOptions();
        this.fillRoleDropdownOptions();
    }

    render() {
        return (
            <Portal.CommonPage {...this.pageProps}>
                <div className="wrapper">
                    <ErrorBoundary FallbackComponent={StandardErrorFallbackPage}>
                        <div className="securedevicesform">
                            {
                                this.state.isLoading ? (
                                    <div className="loading-overlay">
                                        <img src={Loading} className="loading-icon" alt="Loading"></img>
                                    </div>
                                ) : null
                            }
                            <div style={{ display: "flex" }}>
                                <button style={{ marginLeft: "auto" }} onClick={this.props.onClose}>
                                    &#10005;
                                </button>
                            </div>
                            <Label className="securedevicesform-title">{this.user.userPrincipalName}</Label>
                            <div className="securedevicesform-body">
                                <div className="form__text">
                                    <Label
                                        className="securedevices-label"
                                        htmlFor="displayName"
                                    >
                                        Display Name
                                    </Label>
                                    <TextField
                                        className="securedevices-input"
                                        id="displayName"
                                        defaultValue={this.state.displayName}
                                        onChange={(event, value) =>
                                            this.handleInputChange(event, value)
                                        }
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.displayName}
                                    </Label>
                                </div>
                                <div className="form__text">
                                    <Label
                                        className="securedevices-label"
                                        htmlFor="firstName"
                                    >
                                        First Name
                                    </Label>
                                    <TextField
                                        className="securedevices-input"
                                        id="firstName"
                                        defaultValue={this.user.firstName}

                                        onChange={(event, value) =>
                                            this.handleInputChange(event, value)
                                        }
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.firstName}
                                    </Label>
                                </div>
                                <div className="form__text">
                                    <Label
                                        className="securedevices-label"
                                        htmlFor="lastName"
                                    >
                                        Last Name
                                    </Label>
                                    <TextField
                                        className="securedevices-input"
                                        id="lastName"
                                        defaultValue={this.user.lastName}
                                        onChange={(event, value) =>
                                            this.handleInputChange(event, value)
                                        }
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />

                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.lastName}
                                    </Label>
                                </div>
                                <div className="form__text">
                                    <Label
                                        className="securedevices-label"
                                        htmlFor="upn"
                                    >
                                        User Principal Name
                                    </Label>
                                    <TextField
                                        className="securedevices-input"
                                        id="upn"
                                        value={this.user.userPrincipalName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form__text">
                                    <Label
                                        className="securedevices-label"
                                        htmlFor="email"
                                    >
                                        User Email
                                    </Label>
                                    <TextField
                                        className="securedevices-input"
                                        id="email"
                                        value={this.user.email}
                                        disabled={true}
                                    />
                                </div>

                                <div className="form__dropdown">

                                    <Label className="securedevices-label">
                                        Role
                                    </Label>
                                    <Dropdown
                                        className="securedevices-dropdown"
                                        id="roles"
                                        defaultSelectedKey={this.state.roles}
                                        onChange={(event, value) =>
                                            this.handleDropdownChange(event, value?.key)
                                        }
                                        placeholder={rolesPlaceholder}
                                        options={roleOptions}
                                        calloutProps={{ doNotLayer: true }}
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.role}
                                    </Label>
                                </div>

                                <div className="form__dropdown"
                                    hidden={this.state.isSingleDropdown}>
                                    <Label className="securedevices-label">
                                        Scenarios
                                    </Label>
                                    <Dropdown
                                        className="securedevices-dropdown crop-dropdown"
                                        id="scenarios"
                                        defaultSelectedKeys={this.state.scenarios}
                                        multiSelect
                                        multiSelectDelimiter={", " }
                                        onChange={(event, value) =>
                                            this.onDropdownMultiChange(event, value)
                                        }
                                        placeholder={scenarioPlaceholder}
                                        options={scenarioOptions}
                                        calloutProps={{ doNotLayer: true }}
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.scenarios}
                                    </Label>
                                </div>

                                <div className="form__dropdown"
                                    hidden={!this.state.isSingleDropdown}>
                                    <Label className="securedevices-label">
                                        Scenarios
                                    </Label>
                                    <Dropdown
                                        className="securedevices-dropdown crop-dropdown"
                                        id="scenarios"
                                        defaultSelectedKey={this.state.scenarios[0]}
                                        onChange={(event, value) =>
                                            this.handleDropdownChange(event, value?.key)
                                        }
                                        placeholder={scenarioPlaceholder}
                                        options={scenarioOptions}
                                        calloutProps={{ doNotLayer: true }}
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.scenarios}
                                    </Label>
                                </div>


                                <div className="form__dropdown">
                                    <Label className="securedevices-label">
                                        Metro
                                    </Label>
                                    <Dropdown
                                        className="securedevices-dropdown crop-dropdown"
                                        id="metro"
                                        defaultSelectedKey={this.state.metro}
                                        onChange={(event, value) =>
                                            this.handleDropdownChange(event, value?.key)
                                        }
                                        placeholder={metroPlaceholder}
                                        options={metroOptions}
                                        calloutProps={{ doNotLayer: true }}
                                        disabled={!this.user.accountEnabled || !this.state.isActive}
                                    />
                                    <Label className="securedevices-error" style={{ color: "red" }}>
                                        {this.state.formErrors.metro}
                                    </Label>
                                </div>

                                <div className="form__dropdown"
                                    hidden={!this.loggedInUserWithOrgAdminRole(this.context) && !this.loggedInUserWithSysAdminRole(this.context)}>
                                    <Label className="securedevices-label">
                                        Account Enabled
                                    </Label>
                                    <Checkbox
                                        id="checkbox"
                                        className="securedevices-dropdown"
                                        styles={checkBoxStyles}
                                        defaultChecked ={this.user.accountEnabled ?? true}
                                        onChange={this.handleCheckboxChange}
                                        boxSide="end"
                                    />
                                </div>
                                <div className="securedevicesform-footer">
                                    <Stack horizontal tokens={stackTokens}>
                                        <PrimaryButton
                                            onClick={this.handleSubmitUserUpdate}
                                            text="Save"
                                        />
                                        <DefaultButton onClick={this.props.onClose} text="Cancel" />
                                    </Stack>
                                </div>
                            </div>
                            {this.state.showMessageBar && this.state.userUpdateApiCall ? (
                                <div className="securedevicesform-header">
                                    {this.state.isUserUpdated ? (
                                        <div className="securedevicesform-header">
                                            {this.state.id ? (
                                                <MessageBar
                                                    messageBarType={MessageBarType.success}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.userUpdateSuccessMessage()}
                                                </MessageBar>
                                            ) : (
                                                <MessageBar
                                                    messageBarType={MessageBarType.warning}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.UserFriendlyErrorMessageMapping()}
                                                </MessageBar>
                                            )}
                                        </div>
                                    ) : (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            isMultiline={true}
                                            onDismiss={() => this.closeMessageBar()}
                                        >
                                            {this.UserFriendlyErrorMessageMapping()}
                                        </MessageBar>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}
                            {this.state.showMessageBar && this.state.userDisableApiCall ? (
                                <div className="securedevicesform-header">
                                    {this.state.isUserDisabled ? (
                                        <div className="securedevicesform-header">
                                            {this.state.id ? (
                                                <MessageBar
                                                    messageBarType={MessageBarType.success}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.userDisableSuccessMessage()}
                                                </MessageBar>
                                            ) : (
                                                <MessageBar
                                                    messageBarType={MessageBarType.warning}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.UserFriendlyErrorMessageMapping()}
                                                </MessageBar>
                                            )}
                                        </div>
                                    ) : (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            isMultiline={true}
                                            onDismiss={() => this.closeMessageBar()}
                                        >
                                            {this.UserFriendlyDisableUserErrorMessageMapping()}
                                        </MessageBar>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}
                            {this.state.showMessageBar && this.state.userApiCall ? (
                                <div className="securedevicesform-header">
                                    {this.state.isUserCreated ? (
                                        <div className="securedevicesform-header">
                                            {this.state.isNotificationSent ? (
                                                <MessageBar
                                                    messageBarType={MessageBarType.success}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.userCreationSuccessMessage()}
                                                </MessageBar>
                                            ) : (
                                                <MessageBar
                                                    messageBarType={MessageBarType.warning}
                                                    isMultiline={true}
                                                    onDismiss={() => this.closeMessageBar()}
                                                >
                                                    {this.userCreationSuccessButErrorInSendingAccountDetailsMessage()}
                                                </MessageBar>
                                            )}
                                        </div>
                                    ) : (
                                        <MessageBar
                                            messageBarType={MessageBarType.error}
                                            isMultiline={true}
                                            onDismiss={() => this.closeMessageBar()}
                                        >
                                            {this.UserFriendlyCreateUserErrorMessageMapping()}
                                        </MessageBar>
                                    )}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>

                        <div className="standard-page-content">
                            {!this.state.currentError ? this.props.children : StandardErrorFallbackPage({ error: this.state.currentError })}
                        </div>
                    </ErrorBoundary>
                </div>
            </Portal.CommonPage>
        );
    }
}
