import * as React from "react";

import { Constants } from "Utilities/Constants";
import { ResourceStrings } from 'Resources/ResourceKeys';
import ResourceManager from 'Resources/ResourceManager';
import 'scss/site.css';

//===========================================
// FOOTER FUNCTIONAL COMPONENT
//===========================================
const Microsoft = ResourceManager.GetString(ResourceStrings.Common.Microsoft);
const Feedback = ResourceManager.GetString(ResourceStrings.Footer.Feedback);
const AboutUs = ResourceManager.GetString(ResourceStrings.Footer.About);
const ContactUs = ResourceManager.GetString(ResourceStrings.Footer.ContactUs);
const MicrosoftPrivacyStatement = ResourceManager.GetString(ResourceStrings.Footer.MicrosoftPrivacyStatement);
const MicrosoftGlobalDataPrivacyNotice = ResourceManager.GetString(ResourceStrings.Footer.MicrosoftGlobalDataPrivacyNotice);
const MicrosoftPrivacyStatementLink = "https://go.microsoft.com/fwlink/?LinkId=521839";
const MicrosoftGlobalDataPrivacyNoticeLink ="https://go.microsoft.com/fwlink/?LinkId=518021";

export interface TransparentFooterProp {
    isAuthenticated: boolean;    
}

export const TransparentFooter: React.FunctionComponent<TransparentFooterProp> = (props) => {

    const SupportEmail_text = 'mailto:' + Constants.getInstance().getSupportEmail + '?subject=Request for more information';
    const FeedbacktEmail_text = 'mailto:' + Constants.getInstance().getSupportEmail + '?subject=Feedback for secure devices';

    return (
        <footer
            className="nng-white border-top box-shadow-top"
            style={{ marginTop: "2%", paddingBottom: "10px" }}
        >
            <div
                className=""
                style={{
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginTop: "1%",
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "12px",
                    color: "dimgray"
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", flex: "85%" }}>
                    
                </div>
                <div
                    className="copyright"
                    id="copyright"
                    style={{
                        color: "dimgray",
                        alignContent: "right",
                        textAlign: "right",
                        fontSize: "12px",
                        float: "right",
                        display: "block"
                    }}
                >
                    <h5> {Microsoft}</h5>© {new Date().getFullYear()} {Microsoft}

                </div>
            </div>
            <div
                className=""
                style={{
                    marginLeft: "5%",
                    marginRight: "5%",
                    display: "flex",
                    flexDirection: "row"
                }}          >
                <a hidden={props.isAuthenticated}
                    style={{
                        color: "#343a40",
                        alignContent: "right",
                        textAlign: "right",
                        fontSize: "12px",
                        flex: "100%",
                        float: "right"
                    }} href={MicrosoftPrivacyStatementLink} target="_blank" rel="noreferrer">
                    {MicrosoftPrivacyStatement}
                </a>
                <a hidden={!props.isAuthenticated}
                    style={{
                        color: "dimgray",
                        alignContent: "right",
                        textAlign: "right",
                        fontSize: "12px",
                        flex: "100%",
                        float: "right"
                    }} href={MicrosoftGlobalDataPrivacyNoticeLink} target="_blank" rel="noreferrer">
                    {MicrosoftGlobalDataPrivacyNotice}
                </a>
            </div>
        </footer>
    );
};

export default TransparentFooter;
