import * as React from "react";
import { createBrowserHistory } from "history";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

import { Constants } from "Utilities/Constants";
import { UserContext } from "Auth/UserContext";

const browserHistory = createBrowserHistory({ basename: "" });
var constants = Constants.getInstance();

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
        instrumentationKey: constants.InstrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

export interface IApplicationInsightsState {
  userID: string;
  tenantId: string;
}
interface Logger {
  logInfo: (message: any) => void;
  logEvent: (eventName: string, message: any) => void;
  logException: (message: any) => void;
}

export class ConsoleLogger implements Logger {
  private static instance: ConsoleLogger;

  private constructor() {}

  public static getInstance = () => {
    if (!ConsoleLogger.instance) {
      ConsoleLogger.instance = new ConsoleLogger();
    }
    return ConsoleLogger.instance;
  };

  public logInfo = (message: any) => {
    console.log(message);
  };

  public logException = (message: any) => {
    console.log(message);
  };

  public logEvent = (eventName: string, message: any) => {
    console.log("Event " + eventName + " has occurred");
    console.log(message);
  };
}

export class ApplicationInsightsLogger
  extends React.Component<{}, IApplicationInsightsState>
  implements Logger {
  private static instance: ApplicationInsightsLogger;
  static contextType = UserContext;

  private telemetryInitialized: boolean = false;
  private constructor(props: {}) {
    super(props);
      this.state = { userID: "", tenantId: "" };
      console.log("logger created w/ key: " + constants.InstrumentationKey);
  }
  componentDidMount() {
    this.setUserContext = this.setUserContext.bind(this);
  }
  public setUserContext = (UserID: string, TenantId: string) => {
      // Ignore lint rule 'react/no-direct-mutation-state'
      // Reason: can't use setState() because this component is designed to be mounted. 
      // eslint-disable-next-line
      this.state = { userID: UserID, tenantId: TenantId };
    this.addTelemetryInitializers();
  };

  private addTelemetryInitializers = () => {
    // check if appInsights is loaded, if loaded, initialize telemetry data, else do nothing
    if (appInsights !== null && appInsights.context !== null) {
      appInsights.addTelemetryInitializer((envelope: any) => {
        try {
          var envelopeData = envelope.baseData;
          envelopeData.properties = envelopeData.properties || {};

          // Add correlation Id to AppInsights logs as custom property.
            envelopeData.properties["CorrelationId"] = constants.CorrelationID;

          // Add tenant id to AppInsights logs
          envelopeData.properties["TenantId"] = this.state.tenantId;

          // Add upn to AppInsights logs
            envelopeData.properties["UserID"] = this.state.userID;

          // Add the http referrer to AppInsights logs as custom property.
          envelopeData.properties["Referer"] = document.referrer;

          this.telemetryInitialized = true;
        } catch (e) {
          console.log(e);
        }
      });
    }
  };

  public static getInstance = () => {
    if (!ApplicationInsightsLogger.instance) {
      ApplicationInsightsLogger.instance = new ApplicationInsightsLogger({});
    }
    return ApplicationInsightsLogger.instance;
  };

  public logInfo = (tracemessage: any) => {
    if (!this.telemetryInitialized) this.addTelemetryInitializers();

    appInsights.trackTrace({ message: JSON.stringify(tracemessage) });
  };

  public logException = (message: any, properties?: any) => {
    if (!this.telemetryInitialized) this.addTelemetryInitializers();
    appInsights.trackException({
      error: new Error(message),
      severityLevel: SeverityLevel.Error
    });
    };

    public logEvent = (eventName: string, message: any) => {
        if (!this.telemetryInitialized) this.addTelemetryInitializers();
        const eventObject = {
            CorrelationId: constants.CorrelationID,
            Locale: constants.deafutLocale,
            CurrentPageTitle: window.location.pathname,
            eventMessageString: JSON.stringify(message)
        };

        if (message !== null) {
            for (var key in message) {
                if (message.hasOwnProperty(key)) {
                    var value = message[key];
                    (eventObject as any)[key] = value;
                }
            }
            appInsights.trackEvent({ name: eventName, properties: eventObject });
        }
    };
}