import * as React from 'react';
import { Component } from 'react';
import { ResourceStrings } from 'Resources/ResourceKeys';
import ResourceManager from 'Resources/ResourceManager';
import logo from 'Images/Banner.png';

// Move the following values to the localization project
const SignIn_Text = ResourceManager.GetString(ResourceStrings.Landing.PleaseSignIn);

export class Landing extends Component {
    render() {
        return (
            <div>
                <img src={logo} alt="Banner" style={{ flex: 1, width: '100%' }} />
                <div>
                    <div style={{ backgroundColor: '#F8F8F8' }}>
                        <div className="container" style={{ display: 'flex', flexDirection: 'row', flex: '1 0 auto' }}>
                            <h4
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 20,
                                    margin: '2%',
                                    color: 'purple',
                                    verticalAlign: 'center',
                                    justifyContent: 'center',
                                    whiteSpace: 'pre-wrap'
                                }}
                            >
                                {SignIn_Text}                              
                            </h4>                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
