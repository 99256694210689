import { Constants } from '../Utilities/Constants';

const constants = Constants.getInstance();

export const HttpGet: string = "GET";
export const HttpPost: string = "POST";
export const HttpPatch: string = "PATCH";

// Move to new ServiceUtils
export const CreateDefaultRequestHeaders = (token: string, correlationId: string, continuationToken?: string): any => {
    let defaultHeaders = {
        'content-type': 'application/json',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'correlationId': correlationId,
        Authorization: constants.Bearer + token
    };

    
    let continuationHeaders = {
        'content-type': 'application/json',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'correlationId': constants.CorrelationID,
        Authorization: constants.Bearer + token,
        'x-ms-continuation': continuationToken
    };

    return continuationHeaders === null ? defaultHeaders : continuationHeaders;
};

