export class DeviceApiModel {
    id: string;
    assetId: string;
    serialNumber: string;
    scenarioIds: string[];
    tagIds: string[];
    hasVariant: boolean;
    variantLabelName: string;
    variantOptionName: string;
    deviceStatus: string;
    autoPilotRegistrationDate: Date;
    location: {
        campus: string;
        metro: string;
        facility: string;
        room: string;
        locker: string;
        slot: string;
    };
    etag: string;

    constructor(Id: string,
        AssetId: string,
        SerialNumber: string,
        ScenarioIds: string[],
        TagIds: string[],
        HasVariant: boolean,
        VariantLabelName: string,
        VariantOptionName: string,
        DeviceStatus: string,
        AutoPilotRegistrationDate: Date,
        Location: {
            campus: string,
            metro: string,
            facility: string,
            room: string,
            locker: string,
            slot: string
        },
        Etag: string)
    {
        this.id = Id;
        this.assetId = AssetId;
        this.serialNumber = SerialNumber;
        this.scenarioIds = ScenarioIds;
        this.autoPilotRegistrationDate = AutoPilotRegistrationDate;
        this.tagIds = TagIds;
        this.deviceStatus = DeviceStatus;
        this.location = Location;
        this.etag = Etag;
        this.variantLabelName = VariantLabelName;
        this.variantOptionName = VariantOptionName;
        this.hasVariant = HasVariant;
    }
}

export const EmptyDeviceApiModel = new DeviceApiModel("", "", "", [], [], false, "", "", "", new Date(),
    { campus: "", metro: "", facility: "", room: "", locker: "", slot: "" }, "");

export class DeviceUIModel {
    id: string;
    deviceStatus: string;
    campus: string;
    metro: string;
    facility: string;
    room: string;
    locker: string;
    slot: string;
    hasVariant: boolean;
    variantLabelName: string;
    variantOptionName: string;
    deviceAssetId: string;
    deviceType: string;
    deviceSerialNumber: string;
    autoPilotRegistrationDate: number;
    apRegistrationDateUTCString: string;
    etag: string;

    constructor(id: string,
        deviceStatus: string,
        campus: string,
        metro: string,
        facility: string,
        room: string,
        locker: string,
        slot: string,
        hasVariant: boolean,
        variantLabelName: string,
        variantOptionName: string,
        deviceType: string,
        deviceAssetId: string,
        deviceSerialNumber: string,
        autoPilotRegistrationDate: number,
        apRegistrationDateUTCString: string,
        etag: string)
    {
        this.id = id;
        this.deviceStatus = deviceStatus;
        this.campus = campus;
        this.metro = metro;
        this.facility = facility;
        this.room = room;
        this.locker = locker;
        this.slot = slot;
        this.deviceAssetId = deviceAssetId;
        this.deviceType = deviceType;
        this.deviceSerialNumber = deviceSerialNumber;
        this.autoPilotRegistrationDate = autoPilotRegistrationDate;
        this.apRegistrationDateUTCString = apRegistrationDateUTCString;
        this.etag = etag;
        this.variantLabelName = variantLabelName;
        this.variantOptionName = variantOptionName;
        this.hasVariant = hasVariant;
    }
}

export const EmptyDeviceUIModel = new DeviceUIModel("", "NA", "NA",
    "", "", "", "", "", false, "", "", "", "", "", 0, "", "");

export class DeviceMapper {
    public static ApiToUI(apiDevice: DeviceApiModel, scenarioDisplayName: string): DeviceUIModel {
        return new DeviceUIModel(
            apiDevice.id,
            apiDevice.deviceStatus,
            apiDevice.location?.campus,
            apiDevice.location?.metro,
            apiDevice.location?.facility,
            apiDevice.location?.room,
            apiDevice.location?.locker,
            apiDevice.location?.slot,
            apiDevice.hasVariant,
            apiDevice.variantLabelName,
            apiDevice.variantOptionName,
            scenarioDisplayName,
            apiDevice.assetId,
            apiDevice.serialNumber,
            apiDevice.autoPilotRegistrationDate !== null ? new Date(apiDevice.autoPilotRegistrationDate).getTime() : new Date("2018-01-01T00:00:00").getTime(),
            apiDevice.autoPilotRegistrationDate !== null ? new Date(apiDevice.autoPilotRegistrationDate).toUTCString().substring(5) : '---',
            apiDevice.etag
        );
    }
}