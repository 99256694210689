import { ApplicationInsightsLogger } from "./Logger";
import { Constants } from "Utilities/Constants";

let Constant = Constants.getInstance();

export interface entry {
  method: string;
  url: string;
  headers: any;
  data: any;
  status?: string;
}
export interface trace {
  id: number;
  time: number;
  type: string;
  tags: string;
  currentUrl: string;
  correlationId: string;
  entry?: entry;
}
export class TracingService {
  private static instance: TracingService;
  private currentUrl: string = "";
  private traces: trace[] = [];
  private nextId: number = 0;
  public static getInstance = () => {
    if (!TracingService.instance) {
      TracingService.instance = new TracingService();
    }
    return TracingService.instance;
  };
  public getTraces(): trace[] {
    return this.traces;
  }
  public trace(type: string, tags: string, entry: entry = {} as entry) {
    this.traces.push({
      id: ++this.nextId,
      time: new Date().getTime(),
      type: type,
      entry: entry,
      currentUrl: this.currentUrl,
      tags: tags,
      correlationId: entry.headers
        ? entry.headers[Constant.entryHeaders.msCorrelationId]
        : Constant.notAvailable
    });
    let lastTrace = this.traces[this.traces.length - 1];
    ApplicationInsightsLogger.getInstance().logInfo(lastTrace);
  }
  public traceHttpRequest(request: any) {
    /// <summary>
    /// Adds tracing for an api request.
    /// </summary>
    if (request) {
      let entry = {
        method: request.method,
        url: request.url,
        headers: request.headers,
        data: request.data
      } as entry;
      this.trace(
        Constant.TraceTypes.Information,
        Constant.TraceTags.HttpRequest,
        entry
      );
    }
  }
  public traceHttpResponse(response: any) {
    /// <summary>
    /// Adds tracing for the response of an api request.
    /// </summary>
    if (response) {
      let entry = {
        url: response.url,
        headers: response.headers,
        data: response,
        status: response.status
      } as entry;
      // Log the type based on the status code.
      let traceType = Constant.TraceTypes.Information;
      if (response.status <= 0 || response.status >= 400) {
        traceType = Constant.TraceTypes.Error;
      }
      this.trace(traceType, Constant.TraceTags.HttpResponse, entry);
    }
  }
  public traceJavascriptError(error: any) {
    if (error) {
      let entry = {
        method: "",
        url: "",
        headers: "",
        data: error.toString()
      } as entry;
      this.trace(
        Constant.TraceTypes.Error,
        Constant.TraceTags.JavascriptError,
        entry
      );
    }
  }
  public traceAction(action: any) {
    /// <summary>
    /// Adds tracing for the action
    /// </summary>
    if (action) {
      this.trace(action.actionType, action.tags);
    }
  }
  //call this function to set current location
  public interceptOnRouteChangeStart(currentUrl: string) {
    this.currentUrl = currentUrl;
  }
}
