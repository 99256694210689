import * as React from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { ResourceStrings } from 'Resources/ResourceKeys';
import ResourceManager from 'Resources/ResourceManager';
import { frameworkLightTheme } from "Components/Framework/Theme/Theme";
import "scss/site.css";

const header_Text = ResourceManager.GetString(ResourceStrings.NavBar.SecureDevicesPortal);

initializeIcons();

let userIsAuthenticated = false;
let userHasDevicePermissions = false;

interface SiteHeaderProp {
    isAuthenticated: boolean;
    authButtonMethod: any;
    user: any;
}

function AuthNavItem(props: SiteHeaderProp) {
    // If authenticated, return a dropdown with the user's info and a
    // sign out button
    if (props.isAuthenticated) {
        return (
            <div>
                <UncontrolledDropdown >
                    <DropdownToggle nav caret style={{ color: frameworkLightTheme.colorBrandBackgroundInverted }}>
                        Hello, {props.user.displayName}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <p className="dropdown-item-text text-muted mb-0">
                            {props.user.userPrincipalName}
                        </p>
                        <DropdownItem divider />
                        <DropdownItem onClick={props.authButtonMethod}>
                            Sign Out
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }

    // Not authenticated, return a sign in link
    return (
        <PrimaryButton
            style={{ backgroundColor: "transparent" }}
            onClick={props.authButtonMethod}
            className="nav-link border-0"
            color="white"
        >
            {ResourceManager.GetString(ResourceStrings.NavBar.SignIn)}
        </PrimaryButton>
    );
}

export const SiteHeaderBar: React.FunctionComponent<SiteHeaderProp> = (props) => {
    if (props.isAuthenticated && props.user.roles) {
        userHasDevicePermissions = Authorization.AuthorizeUser(props.user.roles, [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioManager
        ]);
        
        userIsAuthenticated = props.isAuthenticated;
    }

    return (
        <table
            className="site-header-bar"
            style={{ backgroundColor: frameworkLightTheme.colorBrandBackground, color: frameworkLightTheme.colorBrandBackgroundInverted }}
            >
            <tbody>
                <tr>
                    <td style={{ width: "70%", paddingTop: "8px" }}>
                        <h5 className="site-title">
                            <a href="./" style={{ color: frameworkLightTheme.colorBrandBackgroundInverted }}>{header_Text}</a>
                        </h5>
                    </td>
                    <td style={{ width: "30%" }}>
                        <div className="site-header-auth-nav">
                            <AuthNavItem
                                isAuthenticated={props.isAuthenticated}
                                authButtonMethod={props.authButtonMethod}
                                user={props.user}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};