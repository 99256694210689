export class UserElevationServiceResponseModel {

    id: string;
    status: string;
    error: {
        code: string,
        message: string,
    };

    constructor(id: string, status: string, Error: {
        code: string,
        message: string,
    }) {
        this.id = id;
        this.status = status;
        this.error = Error;
    }
}

export const EmptyUserElevationServiceResponseModel = new UserElevationServiceResponseModel("", "", {
    code: "",
    message: ""
});

export default UserElevationServiceResponseModel;
