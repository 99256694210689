import React, { Component } from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import {
    Label,
    Icon,
    PrimaryButton,
    DefaultButton,
    TextField,
    Dropdown,
    IDropdownOption,
    Checkbox,
    Stack,
    IStackTokens,
    mergeStyles,
    mergeStyleSets
} from "@fluentui/react";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react/lib/Tooltip";
import {
    DirectionalHint,
    MessageBar,
    MessageBarType
} from "office-ui-fabric-react";
import * as Common from "Components/Common/ValyrianCommonComponents";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { CompanyService } from "Services/CompanyService";
import { CreateSingleUserService } from "Services/CreateSingleUserService";
import { ScenarioService } from "Services/ScenarioService";
import { ScenarioResponseModel } from "Models/ScenarioResponseModel";
import CompanyResponseModel from "Models/CompanyResponseModel";
import "../../../scss/common.css";
import "./UserManagement.css";
import { CommonUtils } from "../../../Utilities/CommonUtils";
import { UserManagementService } from "../../../Services/UserManagementService";
import Loading from 'Images/Loading.gif';

// Move the following values to the localization project
const Component_Name = "Create Single User";
const UnAuthorized = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UnAuthorized
);
let AlertMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UnAuthorized
);
const firstName = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.firstName
);
const lastName = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.lastName
);
const email = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.email
);
const role = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.role
);
const scenario = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.scenario
);
const company = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.company
);
const firstNamePlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.firstNamePlaceholder
);
const lastNamePlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.lastNamePlaceholder
);
const emailPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.emailPlaceholder
);
const rolePlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.rolePlaceholder
);
const scenarioPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.scenarioPlaceholder
);
const companyPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.companyPlaceholder
);
const firstNameInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.firstNameInvalidMessage
);
const lastNameInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.lastNameInvalidMessage
);
const emailInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.emailInvalidMessage
);
const roleInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.roleInvalidMessage
);
const scenarioInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.scenarioInvalidMessage
);
const companyInvalidMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.companyInvalidMessage
);
const toolTipContent = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.toolTipContent
);
const notificationStatusSuccess = Common.ResourceManager.GetString(
    Common.ResourceStrings.Notification.NotificationStatusSuccess
);
const notificationStatusFailure = Common.ResourceManager.GetString(
    Common.ResourceStrings.Notification.NotificationStatusFailure
);
const userWithNoDCATApprovalMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UserWithNoDCATApprovalMessage
);
const userWithNoCOIMessage = Common.ResourceManager.GetString(
    Common.ResourceStrings.CreateSingleUser.UserWithNoCOIMessage
);


const inlineBlockStyle: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" }
};

const checkBoxStyles = {
    text: {
        fontWeight: "500"
    }
};

const iconClass = mergeStyles({
    height: 20,
    width: 20,
    margin: "0 5px"
});

const classNames = mergeStyleSets({
    yellow: [{ color: "#FFBA08" }, iconClass]
});

let scenarioOptions: IDropdownOption[] = [];
let companyOptions: IDropdownOption[] = [];
let allCompanyOptions: IDropdownOption[] = [];

let userManagementService = UserManagementService.getInstance();
let createSingleUserService = CreateSingleUserService.getInstance();
let TracingService = Common.TracingService.getInstance();
const stackTokens: IStackTokens = { childrenGap: 15 };
let loggedInUserDetail: any;

export class CreateSingleUser extends Component<any, any> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Create User",
        authorizedRoles: [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.AccountManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner
        ]
    };

    constructor(props: any) {
        super(props);
        //Refactor to use user object here and user User.firstName etc etc and use that user object to submit the request
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            scenario: "",
            company: "",
            isUserWithDcat: false,
            formErrors: {
                firstName: "",
                lastName: "",
                email: "",
                scenario: "",
                company: ""
            },
            userApiCall: false,
            isUserCreated: false,
            isNotificationSent: false,
            showMessageBar: true,
            userEmail: "",
            isValidUser: false,
            errorMessage: "",
            errorCode: "",
            isUserOrgAdmin: false,
            isUserSysAdmin:false,
            showNonDCATCreateButton: false,
            showNonCOICreateButton: false,
            skipDcat: false,
            skipCoi: false,
            loggedInUserDetails: [],
            isLoading: false,
            showSupportOrValOpsUserCreateButton: false
        };

        this.validateField = this.validateField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmitUserCreation = this.handleSubmitUserCreation.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.closeMessageBar = this.closeMessageBar.bind(this);
        this.fillScenarioDropdownOptions =
            this.fillScenarioDropdownOptions.bind(this);
        this.userCreationSuccessMessage =
            this.userCreationSuccessMessage.bind(this);
        this.userCreationSuccessButErrorInSendingAccountDetailsMessage =
            this.userCreationSuccessButErrorInSendingAccountDetailsMessage.bind(this);
        this.UserFriendlyErrorMessageMapping =
            this.UserFriendlyErrorMessageMapping.bind(this);
    }
    private Constant = Common.Constants.getInstance();

    validateField(fieldName: any, value: any) {
        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case firstName:
                let firstNameValid = value.length > 0;
                fieldValidationErrors.firstName = firstNameValid
                    ? ""
                    : firstNameInvalidMessage;
                break;
            case lastName:
                let lastNameValid = value.length > 0;
                fieldValidationErrors.lastName = lastNameValid
                    ? ""
                    : lastNameInvalidMessage;
                break;
            case email:
                let emailValid = value.match(this.Constant.getRegex.EmailRegex);
                fieldValidationErrors.email = emailValid ? "" : emailInvalidMessage;
                break;
            case scenario:
                let scenarioValid = value.length > 0;
                fieldValidationErrors.scenario = scenarioValid ? "" : scenarioInvalidMessage;
                break;
            case company:
                let companyValid = value.length > 0;
                fieldValidationErrors.company = companyValid
                    ? ""
                    : companyInvalidMessage;
                break;
            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors });
    }

    closeMessageBar = () => {
        companyOptions = allCompanyOptions;
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            scenario: "",
            company: "",
            isUserWithDcat: false,
            userApiCall: false,
            isUserCreated: false,
            isNotificationSent: false,
            showMessageBar: false,
            userEmail: "",
            errorMessage: "",
            errorCode: "",
            isValidUser: true,
            showNonDCATCreateButton: false,
            showNonCOICreateButton: false,
            skipDcat: false,
            skipCoi: false,
            isLoading: false
        });
    };

    private loggedInUserWithOrgAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator]);
    };

    private loggedInUserWithSysAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.SystemAdmin]);
    };

    GetUserByAadId = async () => {
        const user = this.context;
        const scenarioData = await ScenarioService.getInstance().GetCreateUserScenariosAsync(user.IdToken);
        await userManagementService
            .GetUserByAadObjectId(
                user.IdToken,
                user.userObjectID
        ).then((results) => {
            loggedInUserDetail = results.message;
            loggedInUserDetail.scenarioIds.forEach((scenario: string) => {
                scenarioOptions.push({ key: scenario, text: CommonUtils.convertScenarioIdToNameMapping(scenarioData).get(scenario) });
                if (user.roles.includes("ScenarioOwner") && (loggedInUserDetail.scenarioIds.includes("ValyrianSupport"))) {
                    this.setState({
                        showSupportOrValOpsUserCreateButton: true,
                        skipDcat: true
                    });
                }
                });
            });
    };


    fillScenarioDropdownOptions = async () => {
        const user = this.context;
        if (scenarioOptions.length === 0) {
            if (this.loggedInUserWithSysAdminRole(user)) {
                const scenarioData = await ScenarioService.getInstance().GetCreateUserScenariosAsync(user.IdToken);
                scenarioOptions = scenarioData.map(option => ({ key: option.id, text: option.name }));
            } else if (this.loggedInUserWithOrgAdminRole(user)) {
                const scenarioData = await ScenarioService.getInstance().GetCreateUserScenariosAsync(user.IdToken);
                scenarioOptions = scenarioData
                    .filter(option => option.id !== 'ValyrianSupport')
                    .filter(option => option.id !== 'ValyrianOps')
                    .map(option => ({ key: option.id, text: option.name }));
            } else {
                this.GetUserByAadId();
            }
        }
    };

    fillCompanyDropdownOptions = async () => {
        const user = this.context;
        if(companyOptions.length === 0){
            const companyData = await ScenarioService.getInstance().GetCreateUserScenariosAsync(user.IdToken);
            this.setState({ companyData });
            companyData.forEach((scenario) => {
                if (scenario.supportedCompanyMetaData) {
                    scenario.supportedCompanyMetaData.forEach((options) => {
                        if (companyOptions && allCompanyOptions) {
                            const exists = companyOptions.some(company => company.key === options.companyId);
                            if (!exists) {
                                companyOptions.push({
                                    key: options.companyId,
                                    text: options.companyName
                                });
                                allCompanyOptions = companyOptions;
                            }
                        }
                    });
                }
            });
        }
    };

    filterCompanyOptions = (scenarioId?: string) => {
        const { companyData } = this.state;
        let filteredCompanyOptions = [];

        if (!scenarioId) {
            companyData.forEach((scenario: ScenarioResponseModel) => {
                if(scenario.supportedCompanyMetaData){
                    scenario.supportedCompanyMetaData.forEach((options) => {
                        filteredCompanyOptions.push({
                            key: options.companyId,
                            text: options.companyName
                        });
                    });
                }
            });
        } else {
            const selectedScenario = companyData.find((scenario: ScenarioResponseModel) => scenario.id === scenarioId);
            if (selectedScenario) {
                filteredCompanyOptions = selectedScenario.supportedCompanyMetaData.map((company: { companyId: string, companyName: string }) => ({
                    key: company.companyId,
                    text: company.companyName
                }));
            }
        }
        this.setState({company: ''});
        companyOptions = filteredCompanyOptions;
    };

    handleInputChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >,
        newValue: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const { asset } = this.state;
        console.log(target, target.id, target.name, target.value);
        const newAsset = { [target.id]: target.value };
        this.setState(newAsset);
    };

    handleDropdownChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
        >,
        value: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const newResource = { [target.id]: value };

        if (target.id === 'scenario') {
            this.setState(newResource);
            this.filterCompanyOptions(value);
        } else if (target.id === 'company') {
            this.setState(newResource);
        }
    };

    handleCheckboxChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        this.setState({ isUserWithDcat: !!checked });
    };

    handleSubmitUserCreation = async () => {
        this.validateField(firstName, this.state.firstName);
        this.validateField(lastName, this.state.lastName);
        this.validateField(email, this.state.email);
        this.validateField(scenario, this.state.scenario);
        this.validateField(company, this.state.company);
        if (
            this.state.formErrors.email === "" &&
            this.state.formErrors.firstName === "" &&
            this.state.formErrors.lastName === "" &&
            this.state.formErrors.scenario === "" &&
            this.state.formErrors.company === ""
        ) {
            this.setState({ isLoading: true });
            console.log("submitting these values : ");
            await this.createSingleUser().then((results) => {
                this.setState({
                    isLoading: false,
                    userApiCall: true
                });
                console.log(this.state);
            });
        }
    };

    handleCancel = () => {
        companyOptions = allCompanyOptions;
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            scenario: "",
            company: "",
            isUserWithDcat: false,
            userApiCall: false,
            isUserCreated: false,
            isNotificationSent: false,
            userEmail: "",
            isValidUser: true,
            errorMessage: "",
            errorCode: "",
            showMessageBar: true,
            showNonDCATCreateButton: false,
            showNonCOICreateButton: false,
            skipDcat: false,
            skipCoi: false,
            isLoading: false
        });
        console.log(this.state);
    };

    userCreationSuccessMessage = () => {
        console.log(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessMessage,
                this.state.userEmail
            )
        );
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessMessage,
                this.state.userEmail
            )
        );
    };

    //update the contact mechanisum when valsupport is retired
    userCreationSuccessButErrorInSendingAccountDetailsMessage = () => {
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.CreateSingleUser
                    .UserCreationSuccessButErrorInSendingAccountDetailsMessage,
                this.state.userEmail,
                this.Constant.getSupportEmail
            )
        );
    };

    //TODO : Mapping known error messages with user frendly messages with the combination of
    //error codes and errormsg strings as API doesnot return appropriate errorcodes for all error msgs
    //In future once API returns approriate error codes this logic can be modified to check only with errorcodes.
    //update the contact mechanisum when valsupport is retired
    UserFriendlyErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedInternalServerErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "Conflict":
                return Common.ResourceManager.GetString(
                    Common.ResourceStrings.CreateSingleUser
                        .UserCreationFailedUPNExistsMessage
                );
            case "BadRequest":
                if (
                    this.state.errorMessage.includes("userPrincipalName already exists")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedUPNExistsMessage
                    );
                } else if (
                    this.state.errorMessage.includes("scenarioIds are invalid")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedScenariosInvalidMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                } else if (
                    this.state.errorMessage.includes("The user is not authorized to make a request.")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedInvalidRolesMessage
                    );
                } else if (
                    this.state.errorMessage.includes("email domain is invalid")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedEmailDomainInvalidMessage
                    );
                } else if (
                    this.state.errorMessage.includes(
                        userWithNoDCATApprovalMessage
                    )
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedNoDCATMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                } else if (
                    this.state.errorMessage.includes(
                        userWithNoCOIMessage
                    )
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedNoCOIMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                }
                //If none of the above conditions are met then we display actual error message from API
                //to ensure UI never breaks even if error strings are modified on API end.
                else {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.CreateSingleUser
                                .UserCreationFailedBadRequestGenericMessage,
                            this.state.errorMessage
                        )
                    );
                }
            //If the error is not handled above then we display actual error message from API
            //to ensure UI never breaks even if error strings are modified on API end.
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.CreateSingleUser
                            .UserCreationFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    static contextType = Common.UserContext;

    private userWithAccountManagerRole = (user: {
        displayName: string;
        userPrincipalName: string;
        userObjectID: string;
        roles: string[];
        IdToken: string;
        TenantId: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.SystemAdmin, AuthorizedRole.OrganizationAdministrator, AuthorizedRole.AccountManager, AuthorizedRole.ScenarioOwner]);
    };

    private userWithOrgAdminRole = (user: {
        displayName: string;
        userPrincipalName: string;
        userObjectID: string;
        roles: string[];
        IdToken: string;
        TenantId: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator]);
    };

    private userWithSysAdminRole = (user: {
        displayName: string;
        userPrincipalName: string;
        userObjectID: string;
        roles: string[];
        IdToken: string;
        TenantId: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.SystemAdmin]);
    };

    private isUserValid = () => {
        const user = this.context;
        if (this.userWithAccountManagerRole(user)) {
            this.setState({
                isValidUser: true
            });
        } else {
            TracingService.trace(Component_Name, "Not authorized  as AccountManager");
            AlertMessage = UnAuthorized;
            this.setState({
                isValidUser: false
            });
        }
    };

    private isUserOrgAdminValid = () => {
        const user = this.context;
        if (this.userWithOrgAdminRole(user)) {
            this.setState({
                isUserOrgAdmin: true
            });
        } else {
            TracingService.trace(Component_Name, "Not authorized as Organization Administrator");
            AlertMessage = UnAuthorized;
            this.setState({
                isUserOrgAdmin: false
            });
        }
    };


    private isUserSysAdminValid = () => {
        const user = this.context;
        if (this.userWithSysAdminRole(user)) {
            this.setState({
                isUserSysAdmin: true
            });
        } else {
            TracingService.trace(Component_Name, "Not authorized as System Admin");
            AlertMessage = UnAuthorized;
            this.setState({
                isUserSysAdmin: false
            });
        }
    };

    private createUserWithoutDCAT = async () => {
        this.setState({
            isLoading: false,
            isUserWithDcat: true
        });
        await this.handleSubmitUserCreation();
    };

    private createUserWithoutCOI = async () => {
        this.setState({
            isLoading: false
        });
        await this.handleSubmitUserCreation();
    };

    private createSingleUser = async () => {
        const user = this.context;
        this.setState({
            isLoading: true
        });
        if (this.userWithAccountManagerRole(user)) {
            TracingService.trace(
                Component_Name,
                "Authorized AccountManger, now creating new user"
            );
            await createSingleUserService
                .CreateNewUser(
                    user.IdToken,
                    user.userPrincipalName,
                    this.state.firstName,
                    this.state.lastName,
                    this.state.email,
                    [this.state.scenario],
                    ["Scenario User"],
                    this.state.company,
                    this.state.skipDcat,
                    this.state.skipCoi
                )
                .then((results) => {
                    console.log(results);
                    if (results.error) {
                        this.setState({
                            userApiCall: true,
                            showMessageBar: true,
                            isUserCreated: false,
                            isNotificationSent: false,
                            userEmail: this.state.email,
                            errorMessage: results.error.message,
                            errorCode: results.error.code
                        });
                        if (this.state.errorMessage.includes(userWithNoDCATApprovalMessage) && Common.Constants.getNonDCATApprovedScenarios().includes(this.state.scenario) && this.state.isUserOrgAdmin === true && this.state.isUserSysAdmin === true) {
                            this.setState({ showNonDCATCreateButton: true, skipDcat: true });
                        }
                        if (this.state.errorMessage.includes(userWithNoCOIMessage) && Common.Constants.getNonCOIScenarios().includes(this.state.scenario) && this.state.isUserOrgAdmin === true && this.state.isUserSysAdmin === true) {
                            this.setState({ showNonCOICreateButton: true, skipCoi: true });
                        }
                    } else if (results.notificationStatus === notificationStatusSuccess) {
                        companyOptions = allCompanyOptions;
                        this.setState({ userEmail: this.state.email });
                        this.setState({
                            firstName: "",
                            lastName: "",
                            email: "",
                            scenario: "",
                            company: "",
                            isUserWithDcat: false,
                            userApiCall: true,
                            isUserCreated: true,
                            isNotificationSent: true,
                            showMessageBar: true,
                            errorMessage: "",
                            errorCode: "",
                            skipDcat: false,
                            skipCoi: false,
                            showNonDCATCreateButton: false,
                            showNonCOICreateButton: false
                        });
                    } else if (results.notificationStatus === notificationStatusFailure) {
                        companyOptions = allCompanyOptions;
                        this.setState({ userEmail: this.state.email });
                        this.setState({
                            firstName: "",
                            lastName: "",
                            email: "",
                            scenario: "",
                            company: "",
                            isUserWithDcat: false,
                            userApiCall: true,
                            isUserCreated: true,
                            isNotificationSent: false,
                            showMessageBar: true,
                            errorMessage: "",
                            errorCode: "",
                            skipDcat: false,
                            skipCoi: false,
                            showNonDCATCreateButton: false,
                            showNonCOICreateButton: false
                        });
                    }
                });
        } else {
            TracingService.trace(Component_Name, "Not authorized  as AccountManager");
            AlertMessage = UnAuthorized;
            this.setState({
                isUserCreated: false,
                errorMessage: "Not authorized to create new user"
            });
        }
    };

    async componentDidMount() {
        this.isUserValid();
        this.fillScenarioDropdownOptions();
        this.fillCompanyDropdownOptions();
        this.isUserOrgAdminValid();
    }

    render() {
        return (
            <Portal.CommonPage {...this.pageProps}>
            <div className="wrapper">
                    <div className="securedevicesform" hidden={!this.state.isValidUser}>
                    {
                        this.state.isLoading ? (
                            <div className="loading-overlay">
                                <img src={Loading} className="loading-icon" alt="Loading"></img>
                            </div>
                        ) : null
                    }
                    <Label className="securedevicesform-title">Create a new user</Label>
                    <div className="securedevicesform-body">
                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="firstName"
                            >
                                First Name
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="firstName"
                                value={this.state.firstName}
                                placeholder={firstNamePlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                                    disabled={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.firstName}
                            </Label>
                        </div>
                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="lastName"
                            >
                                Last Name
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="lastName"
                                value={this.state.lastName}
                                placeholder={lastNamePlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                                    disabled={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.lastName}
                            </Label>
                        </div>
                        <div className="form__text">
                            <Label className="securedevices-label" required htmlFor="email">
                                Email
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="email"
                                value={this.state.email}
                                placeholder={emailPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                                    disabled={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.email}
                            </Label>
                        </div>

                        <div className="form__dropdown">
                            <Label className="securedevices-label" required htmlFor="scenario">
                                Scenario
                            </Label>
                            <Dropdown
                                className="securedevices-dropdown crop-dropdown"
                                id="scenario"
                                selectedKey={this.state.scenario}
                                onChange={(event, value) =>
                                    this.handleDropdownChange(event, value?.key as string)
                                }
                                placeholder={scenarioPlaceholder}
                                options={scenarioOptions}
                                calloutProps={{ doNotLayer: true }}
                                    disabled={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.scenario}
                            </Label>
                        </div>
                        <div className="form__dropdown">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="comapany"
                            >
                                Company
                            </Label>
                            <Dropdown
                                className="securedevices-dropdown"
                                id="company"
                                selectedKey={this.state.company}
                                onChange={(event, value) =>
                                    this.handleDropdownChange(event, value?.key as string)
                                }
                                placeholder={companyPlaceholder}
                                options={companyOptions}
                                calloutProps={{ doNotLayer: true }}
                                    disabled={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.company}
                            </Label>
                        </div>

                        <div className="securedevices-checkbox" hidden={this.state.isUserOrgAdmin || this.state.showSupportOrValOpsUserCreateButton}>
                            <Checkbox
                                id="checkbox"
                                styles={checkBoxStyles}
                                label="I acknowledge that this user has a DCAT *"
                                checked={this.state.isUserWithDcat}
                                onChange={this.handleCheckboxChange}
                            />
                        </div>

                        <div className="securedevices-warning" hidden={this.state.isUserOrgAdmin || this.state.showSupportOrValOpsUserCreateButton}>
                            <TooltipHost
                                content={toolTipContent}
                                // This is the important part!
                                styles={inlineBlockStyle}
                                id="tooltipId"
                                directionalHint={DirectionalHint.rightTopEdge}
                            >
                                <Label>
                                    <Icon iconName="Warning" className={classNames.yellow} />
                                    All accounts created with this tool need to have an active
                                    DCAT
                                    <Icon
                                        iconName="info"
                                        className={iconClass}
                                        aria-describedby="tooltipId"
                                    />
                                </Label>
                            </TooltipHost>
                        </div>
                            <div className="securedevicesform-footer" hidden={this.state.showNonDCATCreateButton || this.state.showSupportOrValOpsUserCreateButton || this.state.showNonCOICreateButton === true}>
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                onClick={this.handleSubmitUserCreation}
                                text="Create"
                            />
                            <DefaultButton onClick={this.handleCancel} text="Cancel" />
                        </Stack>
                    </div>
                    <div className="securedevicesform-footer" hidden={!this.state.showNonDCATCreateButton}>
                        <Stack horizontal tokens={stackTokens}>
                            <MessageBar
                                isMultiline={false}
                                onDismiss={() => this.closeMessageBar()}
                            >
                                User doesn't have DCAT approval. Want to create anyway
                            </MessageBar>
                        </Stack>
                            </div>
                            <div className="securedevicesform-footer" hidden={!this.state.showNonCOICreateButton}>
                                <Stack horizontal tokens={stackTokens}>
                                    <MessageBar
                                        isMultiline={false}
                                        onDismiss={() => this.closeMessageBar()}
                                    >
                                        User is not part of COI. Want to create anyway
                                    </MessageBar>
                                </Stack>
                            </div>
                    <div className="securedevicesform-footer" hidden={!this.state.showNonDCATCreateButton} style={{ float: "right", width: "relative" }}>
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                onClick={this.createUserWithoutDCAT}
                                text="Proceed without DCAT"
                            />
                            <DefaultButton onClick={this.handleCancel} text="Cancel" />
                        </Stack>
                            </div>
                            <div className="securedevicesform-footer" hidden={!this.state.showNonCOICreateButton} style={{ float: "right", width: "relative" }}>
                                <Stack horizontal tokens={stackTokens}>
                                    <PrimaryButton
                                        onClick={this.createUserWithoutCOI}
                                        text="Proceed without COI"
                                    />
                                    <DefaultButton onClick={this.handleCancel} text="Cancel" />
                                </Stack>
                            </div>
                    </div>
                        <div className="securedevicesform-footer" hidden={!this.state.showSupportOrValOpsUserCreateButton}>
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                onClick={this.createUserWithoutDCAT}
                                text="Create User"
                            />
                            <DefaultButton onClick={this.handleCancel} text="Cancel" />
                        </Stack>
                    </div>
                    {this.state.showMessageBar && this.state.userApiCall ? (
                            <div className="securedevicesform-header" hidden={this.state.showNonDCATCreateButton === true || this.state.showNonCOICreateButton === true}>
                            {this.state.isUserCreated ? (
                                <div className="securedevicesform-header">
                                    {this.state.isNotificationSent ? (
                                        <MessageBar
                                            messageBarType={MessageBarType.success}
                                            isMultiline={true}
                                            onDismiss={() => this.closeMessageBar()}
                                        >
                                            {this.userCreationSuccessMessage()}
                                        </MessageBar>
                                    ) : (
                                        <MessageBar
                                            messageBarType={MessageBarType.warning}
                                            isMultiline={true}
                                            onDismiss={() => this.closeMessageBar()}
                                        >
                                            {this.userCreationSuccessButErrorInSendingAccountDetailsMessage()}
                                        </MessageBar>
                                    )}
                                </div>
                            ) : (
                                <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={true}
                                        onDismiss={() => this.closeMessageBar()}
                                >
                                    {this.UserFriendlyErrorMessageMapping()}
                                </MessageBar>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
            </Portal.CommonPage>
        );
    }
}