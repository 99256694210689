export class Helper {
    public static lastNotificationBannerHeight = 0;

    public static isEmptyOrSpaces = (str: string) => {
        if (str === undefined) {
            return true;
        }
        if (str === null) {
            return true;
        }
        if (str === "") {
            return true;
        }
        if (str.match(/^ *$/) !== null) {
            return true;
        }
        return false;
    };

    public static isEmailAddress = (str: string) => {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(str).toLowerCase());
    };

    public static isAlphanumeric = (str: string) => {
        return str.match(/^[a-z0-9]+$/i) !== null;
    };

    public static sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    public static formatFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size < 1024 * 1024) {
            return (size / 1024.0).toFixed(0) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / 1024.0 / 1024.0).toFixed(1) + ' MB';
        } else {
            return (size / 1024.0 / 1024.0 / 1024.0).toFixed(1) + ' GB';
        }
    };

    public static IsJsonString(str: any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public static GetJwtTokenExpiryTimeInMinutes(token: string) {
        if (this.isEmptyOrSpaces(token)) {
            return -60;
        }
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var payload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let jsonPayload =  JSON.parse(payload);
        let currentTime = new Date();
        let expireInMinutes = (currentTime.getTime() - (jsonPayload["exp"] * 1000)) / 60000;
        return expireInMinutes;
    }

    public static cleanPunctuation = (input: string): string => {
        // eslint-disable-next-line
        let punctuation = /[.,\/#!?$%\^&\*;:{}=\-_`~()]/g;
        let cleanPhrase = input.replace(punctuation, "");

        return cleanPhrase;
    };

    public static CamelCaseToText(input: string) {
        const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
        return input.replace(regex, '$1$4 $2$3$5');
    }

    public static NewGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    public static stringFormat(template: string, ...args: any[]) {
        return template.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] !== 'undefined'
                ? args[number]
                : match
                ;
        });
    }
}