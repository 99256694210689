export class FileUploadApiResponseModel {
    id: string;
    createdByUPN: string;
    fileName: string;
    scenario: string;
    size: number;
    extension: string;
    createdTimeUTC: string;
    error: {
        code: string,
        message: string,
        correlationId: string
    };

    constructor(Id: string,
        CreatedByUPN: string,
        FileName: string,
        Scenario: string,
        Size: number,
        Extension: string,
        CreatedTimeUTC: string,
        Error: {
            code: string,
            message: string,
            correlationId: string,
        }) {
        this.id = Id;
        this.createdByUPN = CreatedByUPN;
        this.fileName = FileName;
        this.scenario = Scenario;
        this.size = Size;
        this.extension = Extension;
        this.createdTimeUTC = CreatedTimeUTC;
        this.error = Error;
        
    }

}

export const EmptyFileUploadResponseModel = new FileUploadApiResponseModel("", "", "", "", 0, "", "", { code: "", message: "", correlationId: "" });

export default FileUploadApiResponseModel;