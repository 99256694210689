export class UpdateDevice {
    id: string;
    Location: {
        facilityShortCode: string;
        slot: string;
        locker: string;
        room: string;
    };

    constructor(id: string,
                Location: {
                    facilityShortCode: string,
                    slot: string,
                    locker: string,
                    room: string
                })
    {
        this.id = id;
        this.Location = Location;
    }

    static getUpdateDevicePatchModel(updateDevice: UpdateDevice) {

        return {
            id: updateDevice.id,
            Location: {
                facilityShortCode: updateDevice.Location.facilityShortCode,
                slot: updateDevice.Location.slot,
                locker: updateDevice.Location.locker,
                room: updateDevice.Location.room
            }
        };
    }
}

export default UpdateDevice;