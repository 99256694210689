export class User {
    displayName: string;
    givenName: string;
    surName: string;
    mailNickname: string;
    userPrincipalName: string;
    contactInfo: {
        email: string,
        city: null,
        country: null,
        department: string,
        jobTitle: null,
        officeLocation: null,
        postalCode: null,
        state: null,
        streetAddress: null,
        mobilePhone: null
    };
    scenarioIds: string[];
    roles: string[];
    skipDcat: boolean;
    skipCoi: boolean;
    accountEnabled: boolean;

    constructor(DisplayName: string, GivenName: string,
        SurName: string, MailNickname: string, UserPrincipalName: string, ContactInfo: {
            email: string,
            city: null,
            country: null,
            department: string,
            jobTitle: null,
            officeLocation: null,
            postalCode: null,
            state: null,
            streetAddress: null,
            mobilePhone: null
        }, ScenarioIds: string[], Roles: string[], skipDcat: boolean, skipCoi: boolean, accountEnabled: boolean) {
        this.displayName = DisplayName;
        this.givenName = GivenName;
        this.surName = SurName;
        this.mailNickname = MailNickname;
        this.userPrincipalName = UserPrincipalName;
        this.contactInfo = ContactInfo;
        this.scenarioIds = ScenarioIds;
        this.roles = Roles;
        this.skipDcat = skipDcat;
        this.skipCoi = skipCoi;
        this.accountEnabled = accountEnabled;
    }

    static getUserPostModel(user: User) {
        return {
            displayName: user.displayName,
            givenName: user.givenName,
            surName: user.surName,
            mailNickname: user.mailNickname,
            userPrincipalName: user.userPrincipalName,
            contactInfo: {
                email: user.contactInfo.email,
                city: user.contactInfo.city,
                country: user.contactInfo.country,
                department: user.contactInfo.department,
                jobTitle: user.contactInfo.jobTitle,
                officeLocation: user.contactInfo.officeLocation,
                postalCode: user.contactInfo.postalCode,
                state: user.contactInfo.state,
                streetAddress: user.contactInfo.streetAddress,
                mobilePhone: user.contactInfo.mobilePhone
            },
            scenarioIds: user.scenarioIds,
            roles: user.roles,
            skipDcat: user.skipDcat,
            skipCoi: user.skipCoi,
            accountEnabled: user.accountEnabled
        };
    }
}

export default User;