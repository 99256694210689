import { UserListApiModel } from "./UserListModel";
export class UserReponseModelPagination {
    countiToken: string;
    users: UserListApiModel[];
    totalRecords: number;
    constructor(countiToken: string,
        users: UserListApiModel[],
        totalRecords: number
    ) {
        this.countiToken = countiToken;
        this.users = users;
        this.totalRecords = totalRecords;
    }
}
export const EmptyUserPageReponseModel = null;
export default UserReponseModelPagination;