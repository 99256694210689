import User from "./User";

export class UserServiceResponseModel {

    notificationStatus: string;
    user: User;
    error: {
        code: string,
        message: string,
    };

    constructor(NotificationStatus: string, User: User, Error: {
        code: string,
        message: string,
    }) {
        this.notificationStatus = NotificationStatus;
        this.user = User;
        this.error = Error;
    }
}

export const EmptyUserServiceResponseModel = new UserServiceResponseModel("", new User("", "", "", "", "", {
    email: "",
    city: null,
    country: null,
    department: "",
    jobTitle: null,
    officeLocation: null,
    postalCode: null,
    state: null,
    streetAddress: null,
    mobilePhone: null
}, [],[], false, false, false), {
    code: "",
    message: ""
});

export default UserServiceResponseModel;
