export class UpdateUser {
    displayName: string;
    givenName: string;
    surName: string;
    scenarioIds: string[];
    roles: string[];
    metros: string[];

    constructor(DisplayName: string, GivenName: string,
        SurName: string, ScenarioIds: string[], Roles: string[], Metros: string[]) {
        this.displayName = DisplayName;
        this.givenName = GivenName;
        this.surName = SurName;
        this.scenarioIds = ScenarioIds;
        this.roles = Roles;
        this.metros = Metros;
    }

    static getUpdateUserPatchModel(updateUser: UpdateUser) {
        return {
            displayName: updateUser.displayName,
            givenName: updateUser.givenName,
            surName: updateUser.surName,
            scenarioIds: updateUser.scenarioIds,
            roles: updateUser.roles,
            metros: updateUser.metros,
            locations: [{ "metro": updateUser.metros?.length > 0 ? updateUser.metros[0].toString() : updateUser.metros }]
        };
    }
}

export default UpdateUser;