export class Device {
    serialNumber: string;
    assetId: string;
    location: {
        facilityShortCode: string;
        room: string;
        locker: string;
        slot: string;
    };
    scenarioIds: string[];
    tagIds: string[];

    constructor(
        SerialNumber: string,
        AssetId: string,
        Location: {
            facilityShortCode: string,
            room: string,
            locker: string,
            slot: string
        },
        ScenarioIds: string[],
        TagIds: string[]
    ){
        this.serialNumber = SerialNumber ;
        this.assetId = AssetId ;
        this.location = Location;
        this.scenarioIds = ScenarioIds;
        this.tagIds = TagIds;
    }
    static getDeviceOnboardingPostModel(device: Device) {
        return {
            serialNumber: device.serialNumber,
            assetId: device.assetId,
            location: {
                facilityShortCode: device.location.facilityShortCode,
                room: device.location.room,
                locker: device.location.locker,
                slot: device.location.slot
            },
            scenarioIds: device.scenarioIds,
            tagIds: device.tagIds
        };
    }

}

export default Device;