export enum DeviceState {
    /* Healthy:
     * - Windows udpates are up to date AND
     * - Battrey >= 40% AND
     * - Storage >= 20% remaining AND
     * - Device is active.
     */
    Healthy = "Healthy",

    /* Needs attention:
    * - Windows update pending < one week away OR
    * - Battery < 40%  OR
    * - Storage < 20% remaining OR
    * - Device inactive> 15 days.
    */
    NeedsAttention = "NeedsAttention",

    /* Unhealthy:
    * - Windows update passed the date OR
    * - Battery < 10% OR
    * - Storage < 5% OR
    * - Device inactive > 30 days.
    */
    Unhealthy = "Unhealthy"
}