export class UserElevationRequest {

    action: string;
    principalId: string;
    //Identifier of the unifiedRoleDefinition object that is being assigned to the principal
    roleDefinitionId: string;
    //Identifier of the directory object representing the scope of the assignment.
    directoryScopeId: string;
    justification: string;
    scheduleInfo: {
        startDateTime: Date;
        expiration: {
            type: string;
            duration: string;
        };
    };


    constructor(action: string,
        principalId: string,
        roleDefinitionId: string,
        directoryScopeId: string,
        justification: string,
        scheduleInfo: {
            startDateTime: Date,
            expiration: {
                type: string,
                duration: string
            }
        }
    ) {
        this.action = action;
        this.principalId = principalId;
        this.roleDefinitionId = roleDefinitionId;
        this.directoryScopeId = directoryScopeId;
        this.justification = justification;
        this.scheduleInfo = scheduleInfo;
    }

    static getUserPostModel(userElevationRequest: UserElevationRequest) {
        return userElevationRequest;
    }
}

export default UserElevationRequest;