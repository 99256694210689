import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { DeviceApiModel, DeviceMapper, DeviceUIModel } from 'Models/DeviceModel';
import { ScenarioResponseModel } from 'Models/ScenarioResponseModel';
import { ScenarioService } from './ScenarioService';
import { TracingService } from './TracingService';
import * as Common from "Components/Common/ValyrianCommonComponents";
import UpdateDevice from '../Models/UpdateDevice';
import DeviceReponseModelPagination from '../Models/DeviceReponseModelPagination';
import DeviceUpdateServiceResponseModel, { EmptyDeviceUpdateServiceResponseModel } from '../Models/DeviceUpdateServiceResponseModel';
import { error } from 'console';

const constants = Constants.getInstance();
const Component_Name = 'Device Inventory Service';
let tracingService = TracingService.getInstance();
var scenarioData: ScenarioResponseModel[];

export class DeviceInventoryService {
    private static instance: DeviceInventoryService;

    private constructor() {
        scenarioData = ScenarioService.getInstance().GetScenarioData();
    }

    public static getInstance = () => {
        if (!DeviceInventoryService.instance) {
            DeviceInventoryService.instance = new DeviceInventoryService();
        }
        return DeviceInventoryService.instance;
    };


    public GetDeviceInventoryData = async (token: string, filters: { key: string, value: string }[], countiToken: string, pagesize: string): Promise<DeviceReponseModelPagination> => {
        tracingService.trace(Component_Name, 'GetInventoryAPICall');
        var DeviceInventoryEndPoint = constants.DevicesPaginationApiEndpoint;
        if (pagesize !== "") {
            DeviceInventoryEndPoint = DeviceInventoryEndPoint + "&pagesize=" + pagesize;
        }
        var filter = "";

        // Add filters if any
        if (filters.length > 0) {
            for (var i = 0; i < filters.length; i++) {
                if (filters[i].value !== "") {
                    if (i !== 0) {
                        filter = filter + " and ";
                    }
                    filter = filter + filters[i].key + " eq '" + filters[i].value + "'";
                }
            }
            if (filter !== "") {
                filter = "&$filter=(" + filter + ')';
            }
        }

        if (filter !== "") {
            DeviceInventoryEndPoint = DeviceInventoryEndPoint + filter;
        };

        try {
            var response =
                await fetch(DeviceInventoryEndPoint, {
                    method: 'get',
                    headers: {
                        'content-type': 'application/json',
                        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                        'correlationId': constants.CorrelationID,
                        Authorization: constants.Bearer + token,
                        'x-ms-continuation': countiToken
                    }

                });
            const data = await response.json();

            if (response.ok) {
                return new DeviceReponseModelPagination(data["continuationToken"], data["devices"], data["totalRecords"]);
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;
                return Promise.reject(errorMsg);
            }
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
            return Promise.reject(error);
        };
    };

    public ReassignDevice = async (token: string, deviceGuid: string, scenario: string, tag?: string): Promise<{ error: boolean, message: string }> => {
        tracingService.trace(Component_Name, 'ReassignDeviceAPICall');

        var devicesApi = constants.Api.Url + constants.Routes.Devices;
        var reassignScenarioAction = constants.DeviceActionReassignScenario;
        var endpoint = `${devicesApi}/${deviceGuid}/${reassignScenarioAction}${constants.Api.Version}&scenario=${scenario}`;
        if (tag) {
            endpoint += `&tags=${tag}`;
        }

        let result = {
            error: false,
            message: ""
        };

        await fetch(endpoint, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            }
        })
        .then(async response => {
            const data = await response.json();

            if (!response.ok) {
                const errorMsg = (data && data.error.message) || response.statusText;

                result = {
                    error: true,
                    message: errorMsg
                };
            }
            else {
                result = {
                    error: false,
                    message: data
                };
            }
        })
        .catch((error) => {
            const logMessage = "Unexpected error occurred. HTTP request failed. CorrelationID: " + constants.CorrelationID + ". ";
            console.log(logMessage);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);

            result = {
                error: true,
                message: logMessage + error
            };
        });

        return result.error ? Promise.reject(result) : result;
    };


    public DecommissionDevice = async (token: string, deviceGuid: string): Promise<{ error: boolean, message: string }> => {
        tracingService.trace(Component_Name, 'DecommissionDeviceAPICall');

        var devicesApi = constants.Api.Url + constants.Routes.Devices;
        var decommissionDeviceAction = constants.DecommissionDeviceAction;
        var endpoint = `${devicesApi}/${deviceGuid}/${decommissionDeviceAction}${constants.Api.Version}`;

        try {
            var response =
                await fetch(endpoint, {
                    method: 'delete',
                    headers: {
                        'content-type': 'application/json',
                        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                        'correlationId': constants.CorrelationID,
                        Authorization: constants.Bearer + token
                    }
                });

            const data = await response.json();

            if (response.ok) {
                return {
                    error: false, message: data
                };
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;

                return {
                    error: true,
                    message: errorMsg
                };
            }
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);

            return Promise.reject(error);
        };
    };

    public UpdateDeviceAsync = async (token: string, facility: string, slot: string, locker: string, room: string, etag: string, id: string): Promise<DeviceUpdateServiceResponseModel> => {
        tracingService.trace(Component_Name, 'UpdateDeviceAPICall');
        let updateDeviceOutput: DeviceUpdateServiceResponseModel = EmptyDeviceUpdateServiceResponseModel;
        const UpdateDeviceEndPoint = Common.Helper.stringFormat(constants.EditDeviceLocationApiEndpoint, id);
        const updateDeviceRequest = UpdateDevice.getUpdateDevicePatchModel(
            this.getUpdateDeviceRequest(id, facility, slot ,locker ,room));
        console.log(updateDeviceRequest);

        try {
            const response = await this.UpdateDeviceApiCall(UpdateDeviceEndPoint, token, updateDeviceRequest, etag);
            const data = await response.json();
            let updateDeviceResponse: DeviceUpdateServiceResponseModel;
            updateDeviceResponse = data;
            updateDeviceOutput = DeviceInventoryService.UpdateDeviceResponseToUpdateDevicePortalUI(updateDeviceResponse);

        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return updateDeviceOutput;
    };

    private UpdateDeviceApiCall = async (UpdateDeviceEndPoint: string, token: string, updateDeviceRequest: UpdateDevice, eTag: string) => {
        return await fetch(UpdateDeviceEndPoint, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token,
                'If-Match': eTag
            },
            body: JSON.stringify(updateDeviceRequest)
        });
    };

    private static UpdateDeviceResponseToUpdateDevicePortalUI = (updateDeviceResponse: DeviceUpdateServiceResponseModel) => {
        return new DeviceUpdateServiceResponseModel(
            updateDeviceResponse.id,
            updateDeviceResponse.error);
    };

    private getUpdateDeviceRequest = (id: string, facilityShortCode: string, slot: string,
        locker: string, room: string) => {

        return new UpdateDevice(id, {
            facilityShortCode, slot,
            locker, room
        });
    };

    public static ApiDevicesToUIDevices = (devicesResponses: DeviceApiModel[]) => {
        var deviceUIModels: DeviceUIModel[] = [];
        if (devicesResponses) {
            devicesResponses.forEach(function (deviceData) {
                deviceUIModels.push(DeviceMapper.ApiToUI(deviceData, ScenarioService.MapScenarioName(deviceData.scenarioIds, scenarioData)));
            });
        }

        return deviceUIModels;
    };
}