import * as React from 'react';
import jwt_decode from 'jwt-decode';
import { TracingService, ApplicationInsightsLogger, Constants } from "Components/Common/ValyrianCommonComponents";
import { PublicClientApplication } from '@azure/msal-browser';


export interface AuthComponentProps {
    error: string;
    isAuthenticated: boolean;
    user: any;
    login: Function;
    logout: Function;
    getTokens: Function;
    setError: Function;
    IdToken: string;
    accessToken: string;
}

interface AuthProviderState {
    error: any;
    isAuthenticated: boolean;
    user: any;
    IdToken: string;
    accessToken: string;
}

const Component_Name = 'Authentication Component';
const tracingService = TracingService.getInstance();
const logger = ApplicationInsightsLogger.getInstance();
const scopes = ['openid', 'profile'];

export default function withAuthProvider<T extends React.Component<AuthComponentProps>>(
    WrappedComponent: new (props: AuthComponentProps, context?: any) => T
): React.ComponentClass {
    return class extends React.Component<any, AuthProviderState> {
        private publicClientApplication: PublicClientApplication;
        private Constant = Constants.getInstance();

        constructor(props: any) {
            super(props);
            this.state = {
                error: null,
                isAuthenticated: false,
                user: {},
                IdToken: '',
                accessToken: ''
            };

            // Initialize the MSAL application object
            this.publicClientApplication = new PublicClientApplication({
                auth: {
                    authority: this.Constant.AuthAuthority,
                    clientId: this.Constant.AuthAppId,
                    redirectUri: this.Constant.AuthRedirectUri
                }
            });
        }

        componentDidMount() {
            this.publicClientApplication
                .handleRedirectPromise()
                .then(this.handleResponse)
                .catch((err) => {
                    logger.logException(err);
                    this.setState({ error: err.errorMessage });
                    console.error(err);
                });

            // If MSAL already has an account, the user is already logged in
            tracingService.trace(Component_Name, 'Authenticate user');
            const accounts = this.publicClientApplication.getAllAccounts();

            if (accounts && accounts.length > 0 && this.state.isAuthenticated === false) {
                // Enhance user object with data from Graph
                this.getUserProfile();
            }
        }

        handleResponse = (response: any) => {
            //handle redirect response
            if (response !== null) {
                tracingService.trace(Component_Name, 'Populate user profile');
                this.getUserProfile();
            }
        };

        render() {
            return (
                <WrappedComponent
                    IdToken={this.state.IdToken}
                    accessToken={this.state.accessToken}
                    error={this.state.error}
                    isAuthenticated={this.state.isAuthenticated}
                    user={this.state.user}
                    login={() => this.login()}
                    logout={() => this.logout()}
                    getTokens={(scopes: string[]) => this.getTokens(scopes)}
                    setError={(message: string, debug: string) => this.setErrorMessage(message, debug)}
                    {...this.props}
                />
            );
        }

        async login() {
            try {
                // Login via redirect
                tracingService.trace(Component_Name, 'Authenticate user');
                this.publicClientApplication.loginRedirect({
                    scopes: scopes
                });

                // After login, get the user's profile
                tracingService.trace(Component_Name, 'Populate user profile');
                await this.getUserProfile();
            } catch (err) {
                this.setState({
                    isAuthenticated: false,
                    user: {},
                    error: this.normalizeError((typeof err === "string") ? err as string : err as Error)
                });
            }
        }

        logout() {
            this.publicClientApplication.logout();
        }

        async getTokens(scopes: string[]): Promise<{ idTokenKey: string, accessTokenKey: string }> {
            try {
                const accounts = this.publicClientApplication.getAllAccounts();
                if (accounts.length <= 0) throw new Error('login_required');
                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                var silentResult = await this.publicClientApplication.acquireTokenSilent({
                    scopes: scopes,
                    account: accounts[0]
                });
                var tokens =
                {
                    idTokenKey: silentResult.idToken,
                    accessTokenKey: silentResult.accessToken
                };
                

                return tokens;

            } catch (err) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (this.isInteractionRequired(err as Error)) {
                    await this.publicClientApplication.acquireTokenRedirect({
                        scopes: scopes
                    });
                    return Promise.resolve({ idTokenKey: 'redirect', accessTokenKey: 'redirect' });
                }
                else {
                    logger.logException(err);
                    throw err;
                }
            }
        }

        // <getUserProfileSnippet>
        async getUserProfile() {
            try {
                var tokens = await this.getTokens(scopes);
                interface MyToken {
                    name: string;
                    upn: string;
                    roles: string;
                    tid: string;
                    oid: string;
                }

                var idToken = tokens.idTokenKey;
                var accessToken = tokens.accessTokenKey;


                if (idToken) {
                    let user = jwt_decode<MyToken>(idToken);
                    this.setState({
                        isAuthenticated: true,
                        user: {
                            displayName: user.name,
                            userPrincipalName: user.upn,
                            userObjectID: user.oid,
                            roles: user.roles,
                            IdToken: idToken,
                            accessToken: accessToken,
                            TenantId: user.tid
                        },
                        error: null
                    });
                    logger.setUserContext(user.oid, user.tid);
                }
            } catch (err) {
                logger.logException(err);
                this.setState({
                    isAuthenticated: false,
                    user: {},
                    error: this.normalizeError((typeof err === "string") ? err as string : err as Error)
                });
            }
        }
        // </getUserProfileSnippet>

        setErrorMessage(message: string, debug: string) {
            this.setState({
                error: { message: message, debug: debug }
            });
        }

        normalizeError(error: string | Error): any {
            var normalizedError = {};
            if (typeof error === 'string') {
                var errParts = error.split('|');
                normalizedError =
                    errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
            } else {
                normalizedError = {
                    message: error.message,
                    debug: JSON.stringify(error)
                };
            }
            return normalizedError;
        }

        isInteractionRequired(error: Error): boolean {
            if (!error.message || error.message.length <= 0) {
                return false;
            }

            return (
                error.message.indexOf('consent_required') > -1 ||
                error.message.indexOf('interaction_required') > -1 ||
                error.message.indexOf('login_required') > -1 ||
                error.message.indexOf('no_account_in_silent_request') > -1
            );
        }
    };
}
