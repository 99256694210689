import * as React from "react";
import {
    useId,
    Input,
    Button
} from "@fluentui/react-components";
import { SearchRegular, DismissRegular } from "@fluentui/react-icons";
// Types
import type { ButtonProps, InputProps } from "@fluentui/react-components";
// CSS
import "Components/Framework/Forms/Form.css";
import "./Filters.css";

export type CommonListSearchProps = {
    columnId: string;
    placeholder: string;
    useCache: boolean;
    onSearch?: (value: string) => void; // todo: search client-side if no callback provided
}

const SearchIcon: React.FunctionComponent<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            appearance="transparent"
            icon={<SearchRegular />}
            size="small"
        />
    );
};
const ClearSearchButton: React.FunctionComponent<ButtonProps> = (props) => {
    return (
        <Button
            {...props}
            appearance="transparent"
            icon={<DismissRegular />}
            size="small"
        />
    );
};

export const SearchField: React.FunctionComponent<CommonListSearchProps> = (props) => {
    const inputId = useId("listSearch");
    const [value, setValue] = React.useState("");

    const onChange: InputProps["onChange"] = (ev, data) => {
        // string validation is a useful here.
        // maybe regex to validation by type ("email", "number", "alphanumeric", etc...)
        setValue(data.value);
    };

    const onSubmit = () => {
        console.log("Searching on value: " + value);
        if (props.onSearch) {
            props.onSearch(value);
        }
    };

    const clearSearch = () => {
        if (props.onSearch) {
            props.onSearch("");
        }
        setValue("");
    };

    return (
        <div>
            <Input
                id={inputId}
                className="filter-search"
                value={value}
                placeholder={props.placeholder}
                contentAfter={<ClearSearchButton onClick={() => clearSearch()} aria-label="Search by first column" />}
                onChange={onChange}
                onKeyDown={(e) => { return e.key === "Enter" ? onSubmit() : null; }}
            />
        </div>
    );
};