import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { TracingService } from './TracingService';
import FileUploadApiResponseModel from '../Models/FileUploadApiResponse';
import { EmptyFileUploadResponseModel } from '../Models/FileUploadApiResponse';


const constants = Constants.getInstance();
const Component_Name = 'Upload File Service';
let tracingService = TracingService.getInstance();

export class FileUploadService {
    private static instace: FileUploadService;

    public static getInstance = () => {
        if (!FileUploadService.instace) {
            FileUploadService.instace = new FileUploadService();
        }
        return FileUploadService.instace;

    };

    public UploadFile = async (token: string, file: File): Promise<FileUploadApiResponseModel> => {
        tracingService.trace(Component_Name, 'FileUploadAPICall');
        
        let fileUploadOutput: FileUploadApiResponseModel = EmptyFileUploadResponseModel;

        const FileUploadEndPoint = constants.FileUploadApiEndpoint;

        try {
            const response = await this.fileUploadApiCall(FileUploadEndPoint, token, file);
            const data = await response.json();
            fileUploadOutput = data;
            console.log(fileUploadOutput);
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return fileUploadOutput;
    };

    private fileUploadApiCall = async (FileUploadEndPoint: string, token: string, file: File) => {

        const formData = new FormData();
        
        formData.append('file', file);

        return await fetch(FileUploadEndPoint, {
            method: 'POST',
            headers: {
                'correlationId': constants.CorrelationID,
                 Authorization: constants.Bearer + token
            },
            
            body: formData  
        });
    };
}