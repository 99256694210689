import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { DeviceHealthUIModel } from 'Models/DeviceHealthUIModel';
import { DeviceSnapshotModel } from 'Models/DeviceSnapshotModel';
import { ScenarioResponseModel } from 'Models/ScenarioResponseModel';
import { ScenarioService } from './ScenarioService';
import { TracingService } from './TracingService';

const constants = Constants.getInstance();
const Component_Name = 'Device Health Service';
const NoActionNeeded = ['No action needed'];
const Plugged = 'Plugged';
const Unplugged = 'Unplugged';
// Values are based on the definition provided here https://docs.microsoft.com/en-us/windows/win32/cimwin32prov/win32-battery
const BatteryStatusPluggedin = ['The system has access to AC so no battery is being discharged.', 'Charging', 'Charging and High', 'Charging and Low', 'Charging and Critical'];
let tracingService = TracingService.getInstance();
var scenarioData: ScenarioResponseModel[];

export class DeviceHealthService {
    private static instance: DeviceHealthService;
    private constructor() {
        scenarioData = ScenarioService.getInstance().GetScenarioData();
    }

    public static getInstance = () => {
        if (!DeviceHealthService.instance) {
            DeviceHealthService.instance = new DeviceHealthService();
        }
        return DeviceHealthService.instance;
    };

    public GetDeviceHealthData = async (token: string, filters: { key: string, value: string }[]): Promise<DeviceHealthUIModel[]> => {
        tracingService.trace(Component_Name, 'GetDevicehealthAPICall');
        var deviceHealthPortalOutputs: DeviceHealthUIModel[] = [];
        var filter = "";            
        var DeviceHealthApiEndpoint = constants.DeviceHealthApiEndpoint;
        // Add filters if any
        if (filters.length > 0) {
            for (var i = 0; i < filters.length; i++)
            {
                if (filters[i].value !== "") {
                    if (i !== 0) {
                        filter = filter + " and ";
                    }
                    filter = filter + filters[i].key + " eq '" + filters[i].value + "'";
                }
            }
            if (filter !== "") {
                filter = "&$filter=(" + filter + ')';
            }
        }

        if (filter !== "") {
            DeviceHealthApiEndpoint = DeviceHealthApiEndpoint + filter;
        };

        //TODO: The headers config and the fetch call will be moved to HttpRequestService
        await fetch(DeviceHealthApiEndpoint, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            }
        })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.error.message) || response.statusText;
                    throw new Error(error);
                }
                else {
                    var deviceHealthResponse: DeviceSnapshotModel[];
                    deviceHealthResponse = data;
                    deviceHealthPortalOutputs = DeviceHealthService.DeviceHealthSourceToPortalModel(deviceHealthResponse);
                }
            })
            .catch((error) => {
                console.log("Error occurred with correlationID: " + constants.CorrelationID);
                tracingService.traceHttpResponse(error.response);
                ApplicationInsightsLogger.getInstance().logException(error);
                return Promise.reject(error);
            });

        return deviceHealthPortalOutputs;
    };

    private static DeviceHealthSourceToPortalModel = (deviceHealthSourceData: DeviceSnapshotModel[]) => {
        var deviceHealthPortalOutputs: DeviceHealthUIModel[] = [];
        // TODO: Make the location component dynamic.This is bigger change as the layout of the table and filter are all be affected.
        deviceHealthSourceData.forEach(function (deviceHealthData) {
            deviceHealthPortalOutputs.push(
                new DeviceHealthUIModel(
                    deviceHealthData.assetId,
                    "",
                    deviceHealthData.deviceHealthState,
                    deviceHealthData.recommendedActions !== null ? deviceHealthData.recommendedActions : NoActionNeeded,
                    deviceHealthData.batteryPercentage !== null ? deviceHealthData.batteryPercentage + "%- " + DeviceHealthService.PluggedInStatus(deviceHealthData.batteryStatusDescription) : constants.notAvailable,
                    deviceHealthData.location?.campus,
                    deviceHealthData.location?.metro,
                    deviceHealthData.location?.facility,
                    deviceHealthData.location?.room,
                    deviceHealthData.location?.locker,
                    deviceHealthData.location?.slot,
                    deviceHealthData.assetId,
                    deviceHealthData.serialNumber,
                    ScenarioService.MapScenarioName(deviceHealthData.scenarioIds, scenarioData),
                    deviceHealthData.lastWindowsUpdate,
                    deviceHealthData.lastHeartbeat,
                    deviceHealthData.make,
                    deviceHealthData.model,
                    deviceHealthData.osVersion,
                    deviceHealthData.totalStorageCapacityInGb,
                    deviceHealthData.remainingStrorageCapacityGb
                ));
        });

        return deviceHealthPortalOutputs;
    };

    private static PluggedInStatus(batteryStatusDescription: string): string {
        if (BatteryStatusPluggedin.includes(batteryStatusDescription)) {
            return Plugged;
        }
        else {
            return Unplugged;
        }
    }
}