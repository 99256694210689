import * as CommonComponents from "Components/Common/ValyrianCommonComponents";
import UserElevation from 'Models/UserElevationRequest';
import ADUserRole from 'Models/ADUserRoles';
import UserElevationServiceResponseModel, { EmptyUserElevationServiceResponseModel } from 'Models/UserElevationServiceResponseModel';

const Component_Name = 'Elevate User Service';
let tracingService = CommonComponents.TracingService.getInstance();
const constants = CommonComponents.Constants.getInstance();

export class GraphUserElevationService {
    private static instance: GraphUserElevationService;

    public static getInstance = () => {
        if (!GraphUserElevationService.instance) {
            GraphUserElevationService.instance = new GraphUserElevationService();
        }
        return GraphUserElevationService.instance;
    };

    public ElevateUser = async (UserElevationRequest: UserElevation, token: string): Promise<UserElevationServiceResponseModel> => {
        tracingService.trace(Component_Name, 'UserElevationAzureAPICall');
        let UserElevationOutput: UserElevationServiceResponseModel = EmptyUserElevationServiceResponseModel;
        const ElevateUserEndPoint = constants.ElevateUserGraphApiEndpoint;

        try {
            const response = await this.UserElevateApiCall(ElevateUserEndPoint, token, UserElevationRequest);
            const data = await response.json();
            let UserElevationResponse: UserElevationServiceResponseModel;
            UserElevationResponse = data;
            UserElevationOutput = new UserElevationServiceResponseModel(UserElevationResponse.id, UserElevationResponse.status, UserElevationResponse.error);
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            CommonComponents.ApplicationInsightsLogger.getInstance().logException(error);
        }
        return UserElevationOutput;
    };

    public GetUserRoles = async (UserObjectId: string, token: string): Promise<ADUserRole[]> => {
        tracingService.trace(Component_Name, 'UserElevationAzureAPICall');
        let ADUserRoleOutput: ADUserRole[] = [];
        try {
            const response = await fetch(constants.UserRolesGraphAPIEndPoint, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization: constants.Bearer + token
                }
            });

            const data = await response.json();
            ADUserRoleOutput = data.value;
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            CommonComponents.ApplicationInsightsLogger.getInstance().logException(error);
        }
        return ADUserRoleOutput;
    };

    private UserElevateApiCall = async (UserELevationEndPoint: string, token: string, UserElevationRequest: UserElevation) => {
        return await fetch(UserELevationEndPoint, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            },
            body: JSON.stringify(UserElevationRequest)
        });
    };

}
