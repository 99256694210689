export enum AuthorizedRole {
    OrganizationAdministrator = "OrganizationAdministrator",
    SystemAdmin = "SystemAdmin",
    SiteAdministrator = "SiteAdministrator",
    CapacityManager = "CapacityManager",
    DeviceManager = "DeviceManager",
    ScenarioManager = "ScenarioManager",
    AccountManager = "AccountManager",
    User = "User",
    EligiblePrivilegedUser = "EligiblePrivilegedUser",
    FileShareUser = "FileShareUser",
    ScenarioOwner = "ScenarioOwner",
    ScenarioChamp = "ScenarioChamp",
    ScenarioUser = "ScenarioUser",
    SupportUser = "SupportUser"
}

export class Authorization {
    public static AuthorizeUser(userRoles: any, authorizedRoles: AuthorizedRole[]): boolean {
        let isAuthorized = false;
        if (authorizedRoles.length === 0) {
            // All users are authorized
            return true;
        }

        if (userRoles !== undefined) {
            for (let role of authorizedRoles) {
                if (userRoles.find((element: string) => element === role)) {
                    isAuthorized = true;
                    break;
                }
            }
        }

        return isAuthorized;
    }
}