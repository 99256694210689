import * as React from "react";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { NavSection } from "./NavSection";
import './SideNav.css';

export class SidebarNav extends React.Component<any, any> {

    public render(): JSX.Element {
        return (
            <Fabric>
                <div className="side-nav-pane"><NavSection isAuthenticated={this.props.isAuthenticated}
                    authButtonMethod={this.props.authButtonMethod}
                    user={this.props.user} /></div>
            </Fabric>
        );
    }
}