import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { TracingService } from './TracingService';
import Device from 'Models/Device';
import DeviceOnboardResponseModel, { EmptyDeviceOnboardResponseModel } from 'Models/DeviceOnboardResponseModel';

const constants = Constants.getInstance();
const Component_name = 'Device Onboard';
let tracingService = TracingService.getInstance();

export class DeviceOnboardingService {
    private static instance: DeviceOnboardingService;

    public static getInstance = () => {
        if (!DeviceOnboardingService.instance) {
            DeviceOnboardingService.instance = new DeviceOnboardingService();
        }
        return DeviceOnboardingService.instance;
    };

    public DeviceOnboarding = async (token: string, userPrincipalName: string,
        facilityShortCode: string,
        room: string,
        locker: string,
        slot: string,
        serialNumber: string,
        assetId: string,
        scenarioIds: string,
        tagIds: string[]
    ): Promise<DeviceOnboardResponseModel> => {
        tracingService.trace(Component_name, 'DeviceOnboardingAPICall');
        let DeviceOnboardOutput: DeviceOnboardResponseModel = EmptyDeviceOnboardResponseModel;
        const DeviceOnboardingEndPoint = constants.DevicesApiEndpoint;
        const DeviceOnboardingRequest = Device.getDeviceOnboardingPostModel(
            this.getDeviceOnboardRequest(serialNumber, assetId, facilityShortCode, room, locker, slot, [scenarioIds], tagIds)
        );
        console.log(DeviceOnboardingRequest);

        try {
            const response = await this.deviceOnboardingApiCall(DeviceOnboardingEndPoint, token, DeviceOnboardingRequest);
            const data = await response.json();
            let deviceOnboardResponse: DeviceOnboardResponseModel;
            deviceOnboardResponse = data;
            DeviceOnboardOutput = DeviceOnboardingService.OnboardDeviceResponseToDevicePortalUI(deviceOnboardResponse);

        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return DeviceOnboardOutput;
    };

    private deviceOnboardingApiCall = async (DeviceOnboardingEndPoint: string, token: string, DeviceOnboardRequest: Device) => {
        return await fetch(DeviceOnboardingEndPoint, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            },
            body: JSON.stringify(DeviceOnboardRequest)
        });
    };



    private getDeviceOnboardRequest = (serialNumber: string, assetId: string, facilityShortCode: string, room: string, locker: string, slot: string, scenarioIds: string[], tagIds: string[]) => {
        return new Device(serialNumber, assetId,
        {
            facilityShortCode: facilityShortCode,
            room: room,
            locker: locker,
            slot: slot
        }, scenarioIds, tagIds);
    };

    private static OnboardDeviceResponseToDevicePortalUI = (deviceOnboardResponse: DeviceOnboardResponseModel) => {
        return new DeviceOnboardResponseModel(
            deviceOnboardResponse.responseMessage,
            deviceOnboardResponse.device,
            deviceOnboardResponse.error
        );
    };
}