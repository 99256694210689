import * as React from "react";
import {
    Badge,
    Button,
    MenuCheckedValueChangeData,
    MenuCheckedValueChangeEvent,
    MenuItemRadio,
    MenuList,
    OnOpenChangeData,
    OpenPopoverEvents,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    TableColumnDefinition,
    Tag,
    TagGroup,
    TagGroupProps
} from "@fluentui/react-components";
import {
    getInitialFilterState,
    renderFilterControlsByType
} from "./DynamicFilterEditors";
import { SearchField } from "./Search";
// Types
import type { DynamicFilterDefinition, FilterValue } from "./DynamicFilterTypes";
import type { CommonListSearchProps } from "./Search";
// CSS
import "Components/Framework/Forms/Form.css";
import "./Filters.css";

/* -----------------------------------------------------------------
   Types & Interfaces
   -----------------------------------------------------------------*/

type AddFilterButtonProps = {
    definitions: DynamicFilterDefinition[];
    onAddFilter: (key: string, value: FilterValue) => void;
};

export interface ICommonListFiltersProps {
    definitions: DynamicFilterDefinition[];
    onFiltersUpdate: (filters: { key: string, value: string | any[] }[]) => void;
};

export interface IListFilterBarProps {
    searchProps?: CommonListSearchProps;
    filterProps?: ICommonListFiltersProps;
    //cleared: boolean;
    //disabled: boolean;
}

export interface ICommonListColumn {
    column: TableColumnDefinition<any>;
    filter?: DynamicFilterDefinition;
};

const ActiveFilterTag = (key: string, filterLabel: string, value: FilterValue) => {
    return (
        <Tag
            key={key}
            value={key}
            shape="circular"
            dismissible
            dismissIcon={{ "aria-label": "remove" }}
            secondaryText={filterLabel}
            style={{ paddingLeft: "20px" }}
        >
            {value.text}
        </Tag>
    );
};

/* -----------------------------------------------------------------
   Filter Contol & Menu & Editor
   -----------------------------------------------------------------*/
const FilterEditor: React.FunctionComponent<{ definition: DynamicFilterDefinition, onFilterApply: (key: string, value: FilterValue) => void }> = (props) => {
    // State variable always declared first!
    const [currentValue, setCurrentValue] = React.useState<FilterValue | undefined>(undefined);

    const onApplyClick = () => {
        if (currentValue !== undefined) {
            props.onFilterApply(props.definition.key, currentValue);
        }
    };

    return props.definition.key !== "" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="standard-form-subheader">Filter by { props.definition.label }</div>
            {
                renderFilterControlsByType(
                    {
                        definition: props.definition,
                        state: getInitialFilterState(props.definition.dependencyKey),
                        onValueChange: (key, value) => setCurrentValue(value)
                    }
                )
            }
            <Button className="standard-form-button" onClick={onApplyClick}>Apply</Button>
        </div>
    ) : null;
};

const FilterMenu: React.FunctionComponent<{ definitions: DynamicFilterDefinition[], onFilterSelect: (filterKey: string) => void }> = (props) => {
    const onCheckedValueChange = (e: MenuCheckedValueChangeEvent, data: MenuCheckedValueChangeData) => {
        if (data.checkedItems.length > 0) {
            props.onFilterSelect(data.checkedItems[0]);
        }
    };
 
    return (
        <div>
            <div className="standard-form-subheader">Select a filter</div>
            <MenuList onCheckedValueChange={onCheckedValueChange}>
                {props.definitions.map(def => <MenuItemRadio key={def.key} name="filter" value={def.key}>{def.label}</MenuItemRadio>)}
            </MenuList>
        </div>
    );
};

const AddFiltersButton: React.FunctionComponent<AddFilterButtonProps> = (props) => {
    // State variable always declared first!
    const [isEditorOpen, setEditorOpen] = React.useState<boolean>(false);
    const [isPopoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    const [selectedFilterDef, setSelectedFilterDef] = React.useState<DynamicFilterDefinition>({
        key: "",
        label: "",
        options: undefined
    });
    const displayPopover = props.definitions.length === 0 ? true : false;

    const onFilterSelected = (filterKey: string) => {
        let def = props.definitions.find(def => def.key === filterKey);
        setSelectedFilterDef(def !== undefined ? def : {
            key: "",
            label: "",
            options: undefined
        });

        setEditorOpen(true);
    };

    const addFilter = (key: string, value: FilterValue) => {
        setEditorOpen(false);
        setPopoverOpen(false);
        props.onAddFilter(key, value);
    };

    const onPopoverChange = (e: OpenPopoverEvents, data: OnOpenChangeData) => {
        setEditorOpen(!data.open);
        setPopoverOpen(data.open);
    };

    return (
        <Popover withArrow closeOnScroll open={isPopoverOpen} positioning="below" onOpenChange={onPopoverChange}>
            <PopoverTrigger>
                <button hidden={displayPopover} className="popover-button-hidden">
                    <Badge appearance="filled" color="brand" size="extra-large" style={{ marginRight: "0.5rem", paddingLeft: "20px", paddingRight: "20px" }}>+ Add filters</Badge></button>
            </PopoverTrigger>
            <PopoverSurface>
                {
                    isEditorOpen === false ? <FilterMenu definitions={props.definitions} onFilterSelect={onFilterSelected}></FilterMenu> : 
                        <FilterEditor definition={selectedFilterDef} onFilterApply={addFilter}></FilterEditor>
                }
            </PopoverSurface>
        </Popover>
    );
};

const ClearFiltersButton: React.FunctionComponent<{ hidden: boolean, onButtonClick: () => void }> = (props) => {
    return (
        <Badge
            hidden={ props.hidden }
            appearance="tint" color="brand" size="extra-large"
            style={{ marginRight: "0.5rem", paddingLeft: "20px", paddingRight: "20px" }}
            onClick={props.onButtonClick}
        >
            X Clear filters
        </Badge>
    );
};

/* -----------------------------------------------------------------
   Dynamic Filters Container & Search Field
   -----------------------------------------------------------------*/
const DynamicFiltersContainer: React.FunctionComponent<ICommonListFiltersProps> = (props) => {
    // State variable always declared first!
    const [activeFilters, setActiveFilters] = React.useState<{ key: string, filterLabel: string, filterValue: FilterValue }[]>([]);

    const addFilter = (key: string, filterValue: FilterValue) => {
        console.log("added filter for: " + key);
        let definition = props.definitions.find(def => def.key === key);
        activeFilters.push({
            key: key,
            filterLabel: definition !== undefined ? definition.label : "",
            filterValue: filterValue
        });

        setActiveFilters([
            ...activeFilters
        ]);

        console.log(activeFilters);
        if (activeFilters.length > 0) {
            props.onFiltersUpdate(
                activeFilters.map(filter => {
                    return {
                        key: filter.key,
                        value: filter.filterValue.value
                    };
                })
            );
        }
    };

    const removeFilter: TagGroupProps["onDismiss"] = (_e, { value }) => {
        console.log("remove tag with this value: " + value);
        const updatedFilters = activeFilters.filter((filter) => filter.key !== value);
        setActiveFilters(updatedFilters);
        props.onFiltersUpdate(
            updatedFilters.map(filter => {
                return {
                    key: filter.key,
                    value: filter.filterValue.value
                };
            })
        );
        //console.log(activeFilters);
        
    };
    const clearFilters = () => {
        setActiveFilters([]);
        props.onFiltersUpdate([]);
    };

    return (
        <div style={{ display: "flex" }}>
            <TagGroup onDismiss={removeFilter} aria-label="Active filters" style={{ marginRight: "0.5rem"}}>
                {activeFilters.map((filter) => ActiveFilterTag(filter.key, filter.filterLabel, filter.filterValue))}
            </TagGroup>
            <AddFiltersButton
                definitions={props.definitions.filter(d => activeFilters.find(active => active.key === d.key) === undefined)}
                onAddFilter={addFilter}>
            </AddFiltersButton>
            
            <ClearFiltersButton hidden={activeFilters.length === 0} onButtonClick={clearFilters}></ClearFiltersButton>
        </div>
    );
};

/* -----------------------------------------------------------------
   Filter Bar
   -----------------------------------------------------------------*/
export const CommonListFilterBar: React.FunctionComponent<IListFilterBarProps> = (props) => {
    return (
        <div style={{ display: "flex" }}>
            { props.searchProps !== undefined ? <SearchField {...props.searchProps} ></SearchField> : null }
            { props.filterProps !== undefined ? <DynamicFiltersContainer { ...props.filterProps } ></DynamicFiltersContainer> : null }
        </div>
    );
};