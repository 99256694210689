import * as React from "react";

// STYLES OBJECT
const s = {
  container: "abs fullW fullH",
  slideImage: "fullH fullW imgCover"
};

//===========================================
// SLIDE FUNCTIONAL COMPONENT
//===========================================

interface SlideProps {
  position: string;
  transition: string;
  image: string;
}

const Slide = ({ position, transition, image }: SlideProps) => {
  return (
    <div className={s.container + " " + position + " " + transition}>
      {image}
    </div>
  );
};
export default React.memo(Slide);
