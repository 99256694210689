import * as React from 'react';
import { Component } from 'react';
import { Constants } from 'Utilities/Constants';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { TracingService } from 'Services/TracingService';
import ResourceManager from 'Resources/ResourceManager';
import Slideshow from 'Components/Common/Slideshow/Slideshow';
import Banner from 'Images/Banner.png';
import EasyDataTransfersIcon from 'Images/EasyDataTransfersIcon.png';
import MarketingPageCustomizableIcon from 'Images/MarketingPageCustomizableIcon.png';
import MarketingPageHardwareIcon from 'Images/MarketingPageHardwareIcon.png';
import MarketingPageHealthAndSecurityIcon from 'Images/MarketingPageHealthAndSecurityIcon.png';
import MarketingPagePersonaIcon from 'Images/MarketingPagePersonaIcon.png';
import MarketingPageTrialIcon from 'Images/MarketingPageTrialIcon.png';
import SharableacrossusersIcon from 'Images/SharableacrossusersIcon.png';
import 'scss/site.css';

const Component_Name = 'Marketing page';
const SecureDevicesFeatures_Text = 'Secure Device Features';
const WhatAreSecureDevices_title = ResourceManager.GetString(ResourceStrings.Marketing.WhatAreSecureDevices_title);
const WhatAreSecureDevices_text = ResourceManager.GetString(ResourceStrings.Marketing.WhatAreSecureDevices_text);
const FeaturePersona_title = ResourceManager.GetString(ResourceStrings.Marketing.FeaturePersona_title);
const FeaturePersona_text = ResourceManager.GetString(ResourceStrings.Marketing.FeaturePersona_text);
const FeatureHardware_title = ResourceManager.GetString(ResourceStrings.Marketing.FeatureHardware_title);
const FeatureHardware_text = ResourceManager.GetString(ResourceStrings.Marketing.FeatureMonitoring_text);
const FeatureApps_title = ResourceManager.GetString(ResourceStrings.Marketing.FeatureApps_title);
const FeatureApps_text = ResourceManager.GetString(ResourceStrings.Marketing.FeatureApps_text);
const FeatureMonitoring_title = ResourceManager.GetString(ResourceStrings.Marketing.FeatureMonitoring_title);
const FeatureMonitoring_text = ResourceManager.GetString(ResourceStrings.Marketing.FeatureMonitoring_text);
const SharableAcrossUsers_title = ResourceManager.GetString(ResourceStrings.Marketing.SharableAcrossUsers_title);
const SharableAcrossUsers_text = ResourceManager.GetString(ResourceStrings.Marketing.SharableAcrossUsers_text);
const EasyDataTransfers_title = ResourceManager.GetString(ResourceStrings.Marketing.EasyDataTransfers_title);
const EasyDataTransfers_text = ResourceManager.GetString(ResourceStrings.Marketing.EasyDataTransfers_text);
const Announcements_title = ResourceManager.GetString(ResourceStrings.Marketing.Announcements_title);
const Announcements_update1 = ResourceManager.GetString(ResourceStrings.Marketing.Announcements_update1);
const Announcements_update2 = ResourceManager.GetString(ResourceStrings.Marketing.Announcements_update2);
const Announcements_update3 = ResourceManager.GetString(ResourceStrings.Marketing.Announcements_update3);
const Trial_title = ResourceManager.GetString(ResourceStrings.Marketing.Trial_title);
const Trial_text = ResourceManager.GetString(ResourceStrings.Marketing.Trial_text);
const RolloutProgress = ResourceManager.GetString(ResourceStrings.Marketing.RolloutProgress);
const SupportEmail_text = 'mailto:' + Constants.getInstance().getSupportEmail + '?subject = Request for secure device project demo';

//===========================================
// SLIDES DATA
//===========================================
const slides = [Announcements_update1, Announcements_update2, Announcements_update3];
let tracingService = TracingService.getInstance();

export class Marketing extends Component<any> {
    render() {
        tracingService.trace(Component_Name, 'Loading marketing page');
        return (
            <div>
                <img src={Banner} alt="Secure Devices cloud based, zero trust, sharable secured devices built with DC persona in mind" style={{ flex: 1, width: '100%' }} />
                <div>
                    <div style={{ backgroundColor: '#F8F8F8' }}>
                        <div className="container" style={{ display: 'flex', flexDirection: 'row' }}>
                            <h4
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 20,
                                    margin: '2%',
                                    verticalAlign: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                { WhatAreSecureDevices_title }
                            </h4>
                            <p
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 80,
                                    marginBottom: '3%',
                                    marginTop: '3%',
                                    textAlign: 'justify',
                                    textJustify: 'auto',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                { WhatAreSecureDevices_text }
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <div style={{ display: 'flex', flexDirection: 'row', height: '10%'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 30,
                                    margin: '5%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <h4 style={{ color: 'purple' }}>{SecureDevicesFeatures_Text}</h4>                          
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', margin: '1%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={MarketingPagePersonaIcon} alt="MarketingPagePersonaIcon" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{FeaturePersona_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{FeaturePersona_text}</p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={MarketingPageCustomizableIcon} alt="SD" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{FeatureHardware_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{FeatureHardware_text}</p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={MarketingPageHardwareIcon} alt="SD" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{FeatureApps_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{FeatureApps_text}</p>
                                </div>
                            </div>                           
                        </div>
                    </div>
                    <div className="container">
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '3%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={SharableacrossusersIcon} alt="MarketingPagePersonaIcon" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{SharableAcrossUsers_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{SharableAcrossUsers_text}</p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={MarketingPageHealthAndSecurityIcon} alt="SD" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{FeatureMonitoring_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{FeatureMonitoring_text}</p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '33%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '10%',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img className="icon" src={EasyDataTransfersIcon} alt="SD" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '10%' }}>
                                    <h5 style={{ color: 'purple' }}>{EasyDataTransfers_title} </h5>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: '90%',
                                        textAlign: 'center',
                                        margin: '5%'
                                    }}
                                >
                                    <p>{EasyDataTransfers_text}</p>
                                </div>
                            </div>
                        </div>
                        <p>{RolloutProgress}</p>
                    </div>
                    <div
                        className="container screenW"
                        style={{ display: 'flex', flexDirection: 'row', marginTop: '2%' }}
                    >
                        <div className="card" style={{ backgroundColor: 'indigo' }}>
                            <div>
                                <h5
                                    style={{
                                        backgroundColor: 'rgba(220, 220, 220, 0.3)',
                                        borderRadius: '10px',
                                        marginBottom: '4%'
                                    }}
                                >
                                    {Announcements_title}
                                </h5>
                            </div>
                            <Slideshow slides={slides} />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#F8F8F8', marginTop: '10%', marginBottom: '5%', display: 'flex' }}>
                        <div className="container" style={{ display: 'flex', flexDirection: 'row', height: '10%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 10, margin: '5%' }}>
                                <img src={MarketingPageTrialIcon} alt="SM" style={{ flex: 1, width: '100%' }} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 30,
                                    margin: '1%',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <h4 style={{ color: 'purple' }}>{Trial_text}</h4>
                                <p style={{ textAlign: 'justify' }}>
                                    {Trial_title}{' '}
                                    <a href={SupportEmail_text}>
                                        {' '}
                                        Contact us
                                    </a>{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
