import * as React from 'react';
import * as Portal from "Components/Framework/Page/CommonPage";
import * as Common from "Components/Common/ValyrianCommonComponents";
import { MessageBarType, PrimaryButton, DefaultButton } from '@fluentui/react';
import { Label, MessageBar, Stack, IStackTokens, TextField } from 'office-ui-fabric-react';
import { FileUploadService } from "Services/FileUploadService";     
import { TracingService } from "Services/TracingService";
import { UserContext } from "Auth/UserContext";
import { ResourceStrings } from '../../../Resources/ResourceKeys';
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import ResourceManager from "Resources/ResourceManager";
import Loading from 'Images/Loading.gif';
import "./FileUpload.css";

const Component_Name = 'File Upload';
let fileUploadService = FileUploadService.getInstance();
let tracingService = TracingService.getInstance();
const stackTokens: IStackTokens = { childrenGap: 15 };


let hiddenFileInput: React.RefObject<HTMLInputElement>;


const uploadFileTitle = ResourceManager.GetString(
    ResourceStrings.FileUpload.FileUploadTitle
);

const uploadFileTitleSubtext = ResourceManager.GetString(
    ResourceStrings.FileUpload.FileUPloadTitleSubText
);

export class FileUpload extends React.Component<any, any> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "File Upload",
        authorizedRoles: [AuthorizedRole.FileShareUser]
    };
    constructor(props: {}) {
        super(props);

        hiddenFileInput = React.createRef<HTMLInputElement>();

        this.state = {
            selectedFile: null,
            selectedFileName: "",
            isFileUploaded: false,
            showMessageBar: false,
            errorMessage: "",
            isLoading: false,
            fileId: "",
            errorIdMessage: ""
        };
        this.Authorize = this.Authorize.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.handleUnkownError = this.handleUnkownError.bind(this);
        this.getErrorIdMessage = this.getErrorIdMessage.bind(this);
    }

    static contextType = UserContext;

    handleUploadClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    getErrorIdMessage = (error: any) => {
        if (error.code === "InternalServerError") {
            return Common.Helper.stringFormat(
                ResourceStrings.FileUpload.FileUploadFailure,
                error.correlationId // This is the same correlation Id we sent from the Portal. 
            );
        }
        else {
            return "";
        }
    };

    handleUnkownError = () => {
        this.setState({
            isFileUploaded: false,
            showMessageBar: true,
            errorMessage: ResourceStrings.FileUpload.UnknownError,
            isLoading: false,
            fileId: "",
            errorIdMessage: ""
        });
        
    };
    private Authorize(user: any): boolean {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, this.pageProps.authorizedRoles);
    };

    handleFileUpload = async () => {
        const user = this.context;
        const maxSizeInBytes = 500 * 1024 * 1024;

        if (this.state.selectedFile !== null) {

            console.log(this.state.selectedFile);

            this.setState({
                isLoading: true
            });

            try
            {
                if (this.state.selectedFile.size === 0) {
                    this.setState({
                        isFileUploaded: false,
                        showMessageBar: true,
                        errorMessage: ResourceStrings.FileUpload.FileSizeIsZero,
                        isLoading: false,
                        fileId: "",
                        errorIdMessage: ""
                    });
                }
                else if (this.state.selectedFile.size > maxSizeInBytes)
                {
                    this.setState({
                        isFileUploaded: false,
                        showMessageBar: true,
                        errorMessage: ResourceStrings.FileUpload.FileSizeIsNotSupported,
                        isLoading: false,
                        fileId: "",
                        errorIdMessage: ""
                    });
                }
                else
                {
                    await fileUploadService.UploadFile(user.IdToken, this.state.selectedFile)
                        .then((results) => {
                            if (results.error) {

                                this.setState({
                                    isFileUploaded: false,
                                    showMessageBar: true,
                                    errorMessage: results.error.message ? results.error.message : ResourceStrings.FileUpload.UnknownError,
                                    isLoading: false,
                                    errorIdMessage: this.getErrorIdMessage(results.error)
                                });

                                tracingService.trace(Component_Name, 'FileUpload API Call Returned error.');
                            }
                            else if (results.id) {

                                this.setState({
                                    isFileUploaded: true,
                                    showMessageBar: true,
                                    isLoading: false,
                                    fileId: results.id
                                });
                                tracingService.trace(Component_Name, 'FileUpload API Call is successful');
                            }
                            else {
                                this.handleUnkownError();
                            }
                        });
                }
               
            }
            catch (error)
            {
                console.log("Unknown error");
                this.handleUnkownError();
            }
           
        }
        else
        {
            this.setState({
                isFileUploaded: false,
                showMessageBar: true,
                errorMessage: ResourceStrings.FileUpload.UploadFileEmpty,
                isLoading: false,
                fileId: "",
                errorIdMessage: ""
            });
        }
        
    };

    handleFileSelect = (event: any) => {
        const file = event.target.files[0];
        console.log(file);  

        this.setState({
            selectedFile: file,
            selectedFileName : file.name,
            showMessageBar: false
        });
    };

    handleCancel = () => {
        this.setState({
            selectedFileName: "",
            selectedFile: null,
            showMessageBar: false,
            errorMessage: "",
            fileId: "",
            errorIdMessage: ""
        });
    };

    fileUploadSucess = () => {
        return Common.Helper.stringFormat(
            ResourceStrings.FileUpload.FileUploadSuccess,
            this.state.fileId
        );
    };

    //TODO : The css classes used here are from CreateSingleUser.css. We need to move all of the common css to a common file.
    public render(): JSX.Element {
        return (
            <Portal.CommonPage {...this.pageProps}>
            <div className="wrapper">
                {
                    this.state.isLoading ? (
                        <div className="loading-overlay">
                            <img src={Loading} className="loading-icon" alt="Loading"></img>
                        </div>
                    ): null
                }

                <div className="securedevicesform">
                    
                    <Label className="securedevicesform-title">
                        { uploadFileTitle }
                        <small style={{ display: 'block'}} >{ uploadFileTitleSubtext }</small>                    
                    </Label>
                    
                    <div className="securedevicesform-body">
                        <div>
                            <Stack horizontal tokens={stackTokens}>
                                <Stack.Item grow>
                                    <TextField
                                        key={'uploadFileName'}
                                        required={true}
                                        id={'uploadFileName'}
                                        placeholder={this.state.selectedFileName}
                                        disabled={true}
                                        ariaLabel='Upload File Name'
                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <PrimaryButton onClick={this.handleUploadClick} text='Select File' />
                                </Stack.Item>
                            </Stack>
                            <input id={"fileInput"} type={"file"} ref={hiddenFileInput}
                                onChange={this.handleFileSelect} style={{ display: 'none' }} />
                        </div>
                    </div>
                    <div className="securedevicesform-footer">  
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton onClick={this.handleFileUpload} text="Upload" />
                            <DefaultButton onClick={this.handleCancel} text="Cancel" />
                        </Stack>
                    </div>

                    {
                        this.state.showMessageBar ?
                            (
                                this.state.isFileUploaded ?
                                    (
                                        <div className="securedevicesform-header">
                                            <MessageBar messageBarType={MessageBarType.success} isMultiline={true} >
                                                {this.fileUploadSucess()}
                                            </MessageBar>
                                        </div>
                                     )
                                    : 
                                    (
                                        <div className="securedevicesform-header">
                                            <MessageBar messageBarType={MessageBarType.error} isMultiline={true} >
                                                {this.state.errorMessage}
                                            </MessageBar>

                                            {this.state.errorIdMessage && (
                                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true} >
                                                    {this.state.errorIdMessage}
                                                </MessageBar>
                                            )}
                                            
                                        </div>
                                    )
                            )
                            : null
                    }
                </div>
            </div>
            </Portal.CommonPage>
        );
    }
}