import * as React from "react";
import { frameworkLightTheme } from "Components/Framework/Theme/Theme";
import { Prohibited48Filled, ErrorCircle48Filled } from "@fluentui/react-icons";
import { ApplicationInsightsLogger } from "Services/Logger";
import { FluentProvider } from "@fluentui/react-components";
import "./CommonPage.css";

export type StandardErrorProps = {
    errorCode: any;
    message: string;
    details: any;
    type: string;
};

export const KnownErrors = {
    forbidden: {
        errorCode: "403",
        message: "Access Denied",
        details: "You do not have an authorized role to view this page. Contact your Organization Administrator to request access.",
        type: "rbac"
    },
    unauthorized: {
        errorCode: "401",
        message: "Unauthorized",
        details: "The server's attempt to authenticate your account failed. If you have an authorized role, refresh or clear your browser cache.",
        type: "server"
    },
    unexpected: {
        errorCode: "422",
        message: "Unexpected Error",
        details: "Encountered an unexpected application error.",
        type: "runtime"
    }
};

export const DefaultRuntimeErrorFallback = ({error}: {error: any}) => {
    ApplicationInsightsLogger.getInstance().logException(`Default App-level ErrorBoundary caught unhandled runtime error. Message: ${error.message ?? ""}`);
    
    return (
        <FluentProvider>
            <div className="standard-page-error">
                <ErrorCircle48Filled color="red"></ErrorCircle48Filled>
                <h3>{error.errorCode ? error.message : KnownErrors.unexpected.message}</h3>
                <p>{error.details ? error.details : `${KnownErrors.unexpected.details} Message: ${error.message ?? ""}`}</p>
            </div>
        </FluentProvider>
    );
};

export const StandardErrorFallbackPage = ({error}: {error: any}) => {
    if (!error.type) {
        ApplicationInsightsLogger.getInstance().logException(`Standard Page caught unhandled runtime error. Message: ${error.message ?? ""}`);
    }

    let icon = <ErrorCircle48Filled color="red"></ErrorCircle48Filled>;
    if (error.errorCode) {
        if (error.errorCode === "403") {
            icon = <Prohibited48Filled color="red"></Prohibited48Filled>;
        }
    }

    return (
        <FluentProvider>
            <div className="standard-page-error">
                { icon }
                <h3>{error.message}</h3>
                <p>{error.details}</p>
            </div>
        </FluentProvider>
    );
};